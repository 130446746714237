import { has } from 'lodash';

import { getLeverageValues, getMaterialityValues, getSpendValues } from 'src/classes/data/suppliers/SupplierData';
import { levelOptions, sourceOptions } from 'src/classes/suppliers/SuppliersUtil';

export const getStaticFiltersOptions = () => ({
  leverages: getLeverageValues(),
  spends: getSpendValues(),
  materiality: getMaterialityValues(),
  levels: levelOptions(),
  sources: sourceOptions()
});

export const formatOptions = (options) => {
  if (has(options, 'values')) {
    return options.values;
  }

  return options;
};
