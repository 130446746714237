// Template of regular expression for testing URL
export const urlRegexString = 'https://www.|https://|http://www.|http://';

/**
 * Adds a 'http://' string at the beginning of the URL if there is no 'http://' or 'https://'
 * @param {string} url
 * @return {string}
 */
export const formatExternalUrl = (url) => {
  let prefix = hasHttpHtttps(url) ? '' : 'http://';
  return `${prefix}${url}`;
};

/**
 * Check if there is a 'http://' or 'https://' at the beginning of a url
 * @param {string} url
 * @return {boolean}
 */
export const hasHttpHtttps = (url) => {
  const regex = new RegExp(urlRegexString);
  return regex.test(url);
};

/**
 * Opens the url in a new tab
 * @param {string} url
 */
export const openInNewTab = (url) => {
  const win = window.open(url, '_blank');
  win.focus();
};
