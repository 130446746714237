import { find, get, keys, mapKeys } from 'lodash';
import { matchPath, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import React from 'react';
import { store } from 'src/redux/store';

import { fetchRootRoute, PortalLoginRoute } from 'src/routes';
import { isCurrentPathWithoutParams } from 'src/utils/isCurrentPathWithoutParams';
import { isCurrentPathWithParams } from 'src/utils/isCurrentPathWithParams';
import { LoggedInLayoutLogicWrapper } from 'src/components/spa/LoggedInLayoutLogicWrapper';
import { LoginPage, SingleSignOnPage, Routes, SingleSignOnGetAuthPage } from 'src/routesConfig';
import { NotFound } from 'src/components/errors/NotFound';
import { TopBarSection } from 'src/components/layout/top-bar/TopBarSection';
import { Types, WithPermissions } from 'src/components/utils/WithPermissions';

const getContentClassName = (pathname) => {
  if (isCurrentPathWithParams(pathname, 'isFullScreen')) {
    return 'full-screen';
  }
  const isSidebarExpanded = sessionStorage.getItem('isSidebarExpanded') === 'true';
  const additionalClassName = isSidebarExpanded ? 'is-open' : 'is-closed';

  return `l-content ${additionalClassName}`;
};

const getExternalRoutes = (props) =>
  Object.keys(Routes)
    .filter((route) => Routes[route].isWithoutAuthentication)
    .map((route) => {
      const RenderComponent = Routes[route].component;
      const path = fixPortalPath(Routes[route].path);
      return (
        <Route
          key={path}
          render={(params) => <RenderComponent {...props} {...params} />}
          path={path}
          exact={get(Routes[route], 'params.exact')}
        />
      );
    })
    .concat([
      <Route
        key={LoginPage.path}
        render={(params) => <LoginPage.Component {...props} {...params} />}
        path={[
          LoginPage.path,
          ...Object.keys(Routes)
            .filter((route) => !Routes[route].isWithoutAuthentication)
            .map((route) => Routes[route].path)
        ]}
        exact={LoginPage.params.exact}
      />,
      <Route
        key={SingleSignOnPage.path}
        render={(params) => <SingleSignOnPage.Component {...props} {...params} />}
        path={[
          SingleSignOnPage.path,
          ...Object.keys(Routes)
            .filter((route) => !Routes[route].isWithoutAuthentication)
            .map((route) => Routes[route].path)
        ]}
        exact={SingleSignOnPage.params.exact}
      />,
      <Route
        key={SingleSignOnGetAuthPage.path}
        render={(params) => <SingleSignOnGetAuthPage.Component {...props} {...params} />}
        path={[
          SingleSignOnGetAuthPage.path,
          ...Object.keys(Routes)
            .filter((route) => !Routes[route].isWithoutAuthentication)
            .map((route) => Routes[route].path)
        ]}
        exact={SingleSignOnGetAuthPage.params.exact}
      />,
      <Route path="*" component={NotFound} key="not_found" />
    ]);

const getInternalRoutes = (pathname, userDependentDataFetched) => {
  const internalRoutesArray = Object.keys(Routes).filter((route) => !Routes[route].isWithoutAuthentication);

  const renderInternalRoute = (route) => {
    const RenderComponent = Routes[route].component;
    const routePermissions = Routes[route].permissions || [];
    const type = Routes[route].permissionsType || Types.All;

    return (
      <Route
        key={Routes[route].path}
        render={(params) =>
          userDependentDataFetched ? (
            <WithPermissions requiredPermissions={routePermissions} type={type}>
              <section className={getContentClassName(pathname)} id="l-content">
                <TopBarSection />
                <RenderComponent {...params} />
              </section>
            </WithPermissions>
          ) : null
        }
        path={Routes[route].path}
        {...Routes[route].params}
      />
    );
  };

  return [
    <Route
      key="internalRoutesSwitch"
      render={() => (
        <LoggedInLayoutLogicWrapper isSignedIn={true} userDependentDataFetched={userDependentDataFetched}>
          <Switch>{internalRoutesArray.map(renderInternalRoute)}</Switch>
        </LoggedInLayoutLogicWrapper>
      )}
      path={internalRoutesArray.map((route) => Routes[route].path)}
    />,
    <Route path="*" component={NotFound} key="not_found" />
  ];
};

const getRoutes = (props) => {
  const { isSignedIn, pathname, userDependentDataFetched } = props;
  const isPathWithAuthentication = isCurrentPathWithoutParams(pathname, 'isWithoutAuthentication');

  return isSignedIn && isPathWithAuthentication
    ? getInternalRoutes(pathname, isPathWithAuthentication ? userDependentDataFetched : false)
    : getExternalRoutes(props);
};

const redirectIfWrongPath = (pathname, isSignedIn) => {
  const routes = mapKeys(Routes, (v, _k) => v.path);
  const routesPaths = keys(routes);

  const currentPath = find(routesPaths, (path) => {
    path = fixPortalPath(path);
    return matchPath(pathname, path) && matchPath(pathname, path)['isExact'];
  });

  if (isSignedIn && get(routes, [currentPath, 'prohibitIfLogged'])) {
    return <Redirect key={'redirect'} to={fetchRootRoute()} />;
  }
};

const fixPortalPath = (path) => {
  const portals = `(${store.getState().portals.data.slugs.join('|')})`;

  if (path === PortalLoginRoute) {
    return `${path}${portals}`;
  }
  return path;
};

export const SwitchComponent = (props) => {
  const { pathname } = useLocation();

  return (
    <Switch>
      {redirectIfWrongPath(pathname, props.isSignedIn)}
      {getRoutes({ ...props, pathname })}
    </Switch>
  );
};
