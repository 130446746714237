import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import Alert from 'react-s-alert';
import React, { Fragment, Suspense } from 'react';

import { AxiosConfig } from 'src/components/utils/AxiosConfig.jsx';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'src/redux/store';

import App from 'src/components/spa/App';
import DynamicHeadContent from 'src/components/utils/DynamicHeadContent.jsx';
import GoogleTagManager from 'src/components/utils/GoogleTagManager.jsx';
import history from 'src/history';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import ScrollToTop from 'src/ScrollToTop';

const isProduction = process.env.NODE_ENV === 'production';

const handleLoadingContainer = () => <LoadingContainer loading={true} className="loading--esg" />;

const AppWrapper = (props) => {
  return (
    <Fragment>
      {isProduction && <GoogleTagManager />}
      <Provider store={store}>
        <Suspense fallback={handleLoadingContainer()}>
          <PersistGate loading={null} persistor={persistor}>
            <AxiosConfig />
            <DynamicHeadContent />
            <ConnectedRouter history={history}>
              <ScrollToTop />
              <App {...props} />
            </ConnectedRouter>
          </PersistGate>
        </Suspense>
      </Provider>
      <Alert stack={{ limit: 1 }} effect="stackslide" position="top" />
    </Fragment>
  );
};

export default AppWrapper;
