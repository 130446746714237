import _ from 'lodash';
import { generatePath } from 'react-router-dom';

import moment from 'moment';
import qs from 'qs';

const removeEmptyParams = (params) =>
  _.reduce(
    params,
    (result, value, key) => {
      if (!_.isNil(value)) {
        result[key] = value;
      }
      return result;
    },
    {}
  );

// builds paths
export const buildPath = (url, params, removeEmpty = true) => {
  // Remove all null/empty vales like axios would
  params = removeEmpty ? removeEmptyParams(params) : params;

  return generatePath(url, params);
};

// builds internal filters path
export const buildFiltersPath = (url, params) => {
  const qstr = qs.stringify(params, {
    arrayFormat: 'comma'
  });

  return generatePath(url, params) + '?' + qstr;
};

// builds paths for the api calls, they need additional qstr
export const buildPathForApi = (url, params, removeEmpty = true) => {
  // Remove all null/empty vales like axios would
  params = removeEmpty ? removeEmptyParams(params) : params;

  const qstr = qs.stringify(params, {
    arrayFormat: 'brackets',
    serializeDate: (date) => moment(date).format()
  });

  return generatePath(url, params) + (qstr ? '?' + qstr : '');
};
