import { NavLink } from 'react-router-dom';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';

import { I18n } from 'src/utils/translations';

const AsideItem = ({
  aElemProps,
  callback,
  href,
  icon,
  id,
  isActive,
  isExternal,
  isSidebarExpanded,
  svgImage,
  title
}) => {
  const [isRenderTitle, setIsRenderTitle] = useState(isSidebarExpanded);
  const timeoutRef = useRef(null);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    if (isSidebarExpanded) {
      timeoutRef.current = setTimeout(() => {
        setIsRenderTitle(true);
      }, 500);
    } else {
      setIsRenderTitle(false);
    }
  }, [isSidebarExpanded]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  let className = aElemProps.className || '';
  if (isActive) {
    className += ' active';
  }

  const content = (
    <div className={`u-text-capital side-item-wrapper ${isActive ? 'black-svg-icon' : 'white-svg-icon'}`}>
      {svgImage && <img src={svgImage} alt="Excel icon" />}
      {icon && <FontAwesome name={icon} />}
      {isRenderTitle && title}
      {isExternal && isSidebarExpanded && <FontAwesome className="l-nav__external" name="external-link" />}
    </div>
  );

  if (isExternal) {
    return (
      <a id={id} href={href} {...aElemProps}>
        {content}
      </a>
    );
  } else if (!href) {
    return (
      <span role="button" id={id} {...aElemProps}>
        {content}
      </span>
    );
  }

  return (
    <NavLink
      id={id}
      exact
      to={href}
      {...aElemProps}
      title={`${I18n.t('click_to_visit')} ${title}`}
      className={className}
      onClick={callback}>
      {content}
    </NavLink>
  );
};

AsideItem.propTypes = {
  title: PropTypes.string.isRequired,
  aElemProps: PropTypes.object,
  callback: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isExternal: PropTypes.bool,
  isSidebarExpanded: PropTypes.bool
};

AsideItem.defaultProps = {
  aElemProps: {},
  callback: null,
  href: null,
  icon: '',
  id: '',
  isActive: false,
  isExternal: false,
  isSidebarExpanded: false
};

export default AsideItem;
