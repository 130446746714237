import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import { I18n } from 'src/utils/translations';

export interface ButtonProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  onClick: () => void;
}

const SaveButton = ({ className = '', disabled = false, label = I18n.t('save'), onClick }: ButtonProps) => (
  <EiqButtonWrapper
    aria-label={label}
    className={className}
    disabled={disabled}
    label={label}
    onClick={onClick}
    type="button"
    variant="btn--solid"
    variantColor="mint-100"
  />
);

export default SaveButton;
