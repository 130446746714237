import _ from 'lodash';

import { I18n } from 'src/utils/translations';

export const RISK_RANGE_TEXT = {
  extreme: '(0 - 2.49)',
  high: '(2.50 - 4.99)',
  medium: '(5 - 7.49)',
  low: '(7.50 - 10)'
};

export const MANAGEMENT_RISK_RANGE_TEXT = {
  extreme: '(0 - 49)',
  high: '(50 - 69)',
  medium: '(70 - 89)',
  low: '(90 - 100)'
};

export const WSS_RANGE_TEXT = {
  extreme: '(0.00 - 4.99)',
  high: '(5.00 - 6.99)',
  medium: '(7 - 8.99)',
  low: '(9.00 - 10)'
};

/**
 * look for reference https://www.toptal.com/designers/htmlarrows/punctuation/
 * @type {{string:string}}
 */
export const COMMON_HTML_CODE_CHARS = {
  '&#033;': '!',
  '&#034;': '"',
  '&#037;': '%',
  '&#038;': '&',
  '&#039;': "'", // APOSTROPHE
  '&#33;': '!',
  '&#34;': '"',
  '&#37;': '%',
  '&#38;': '&',
  '&#39;': "'", // APOSTROPHE
  '&#8216;': "'", // LEFT SINGLE QUOTATION MARK
  '&#8217;': "'", // RIGHT SINGLE QUOTATION MARK
  '&#8220;': '"', // LEFT DOUBLE QUOTATION MARK
  '&#8221;': '"' // RIGHT DOUBLE QUOTATION MARK
};

// Template of regular expression for testing valid email addresses
const emailRegexString =
  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
  '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

/**
 * Validates email address. Returns list of errors found.
 * @param email email to validate
 * @param extraValidator extra validation to test email against
 * @returns {array} array containing list of found errors (may be empty if no errors found)
 */
export const findEmailErrors = (email, extraValidator = null) => {
  let isValid = new RegExp(emailRegexString).test(email);

  if (isValid) {
    const errors = extraValidator ? extraValidator(email) : [];

    if (!(errors instanceof Array)) {
      throw new Error('Errors returned by validator should be array');
    }

    return errors;
  } else {
    return [I18n.t('form.input.email.invalid')];
  }
};

/**
 * @param value value to format
 * @param emptyValue {string} empty value placeholder
 * @returns {string} value from params, or emptyValue if value is null
 */
export const displayText = (value, emptyValue = '-') => (value ? value : emptyValue);

/**
 * Returns label representing user - displaying it's email, and full name (if available).
 * @returns {string} label consisting of email (and full name).
 */
export const getUserLabel = ({ email, fullname }) => {
  return email + (fullname ? ` - ${fullname}` : '');
};

export const getRoleName = (roleName) => {
  const deprecatedOptions = {
    account_manager: I18n.t('users.account_manager'),
    account_user: I18n.t('users.account_user')
  };

  // return deprecated option if user has it already
  if (roleName in deprecatedOptions) {
    return deprecatedOptions[roleName];
  }

  return roleName;
};

export const toTranslationKey = (value) => {
  return _.snakeCase(_.toLower(value));
};

export const getTranslationByParam = (basePath, field, prefixWithActivators) => {
  let prefix = _.find(prefixWithActivators, { prop: true });

  const formattedPrefix = prefix ? `.${prefix.key}_` : '.';
  return I18n.t(`${basePath}${formattedPrefix}${field}`);
};

/**
 * Takes a string phrase and breaks it into separate phrases
 * no bigger than 'maxWidth', breaks are made at complete words.
 */
export const splitString = (str, maxWidth) => {
  let sections = [];
  let words = str.split(' ');
  let temp = '';

  _.forEach(words, (word, index) => {
    if (temp.length > 0) {
      let concat = `${temp} ${word}`;

      if (concat.length > maxWidth) {
        sections.push(temp);
        temp = '';
      } else {
        if (index === words.length - 1) {
          sections.push(concat);
        } else {
          temp = concat;
        }
        return;
      }
    }

    if (index === words.length - 1) {
      sections.push(word);
      return;
    }

    if (word.length < maxWidth) {
      temp = word;
    } else {
      sections.push(word);
    }
  });
  return sections;
};

/**
 * Parse html code characters to plain chars
 * for example &#33; to !
 * @param {string} str
 * @return {string}
 */
export const parseHtmlCodeChars = (str) => {
  return str.replace(/&#([0-9]{2,4};)/g, (val) => (COMMON_HTML_CODE_CHARS[val] ? COMMON_HTML_CODE_CHARS[val] : val));
};

/**
 * @param {string} str
 * @param {number} maxLength
 */
export const trimToMaxLength = (str, maxLength) => {
  return str.length > maxLength ? `${str.substr(0, maxLength)}...` : str;
};
