import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

export default class AsideDropdownMenu extends React.Component {
  state = {
    isRenderTitle: this.props.isSidebarExpanded
  };

  element = null;
  timeoutRef = React.createRef(null);

  componentDidUpdate(prevProps) {
    if (prevProps.isSidebarExpanded !== this.props.isSidebarExpanded) {
      clearTimeout(this.timeoutRef.current);
      if (this.props.isSidebarExpanded) {
        this.timeoutRef.current = setTimeout(() => {
          this.setState({ isRenderTitle: true });
        }, 500);
      } else {
        this.setState({ isRenderTitle: false });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutRef.current);
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (
      this.props.isActive &&
      !nextProps.isActive &&
      this.element &&
      !this.element.classList.contains('closed') &&
      !this.props.opened
    ) {
      // Calling `click` function causes calling Bootstrap scripts which collapsing the dropdown.
      this.element.click();
    }
  };

  onClick = () => {
    if (!this.props.isActive) {
      this.props.callback();
    }
  };

  renderParent = () => {
    if (this.state.isRenderTitle) {
      return (
        <Fragment>
          {this.props.icon && <FontAwesome name={this.props.icon} />}
          <span className="l-nav__title">{this.props.title}</span>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {this.props.icon && <FontAwesome name={this.props.icon} />}
          <span className="l-nav__title">
            <hr />
          </span>
        </Fragment>
      );
    }
  };

  render = () => (
    <Fragment>
      {this.renderParent()}
      <div className={`w-full	${this.props.opened || this.props.isActive ? 'in' : ''}`}>{this.props.children}</div>
    </Fragment>
  );
}

AsideDropdownMenu.propTypes = {
  callback: PropTypes.func.isRequired,
  children: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
  isSidebarExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  opened: PropTypes.bool
};

AsideDropdownMenu.defaultProps = {
  icon: null,
  opened: false
};
