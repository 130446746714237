import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment, Suspense } from 'react';

import Aside from 'src/components/layout/Aside';
import { JiraIssueCollector } from 'src/components/utils/JiraIssueCollector';
import LoadingContainer from 'src/components/common/loading/LoadingContainer';
import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';
import { setSidebar } from 'src/redux/actions/navbar';

class SidebarExpansionStorageProvider {
  key = 'isSidebarExpanded';

  get = () => SessionStorageProvider.get(this.key) === 'true';
  set = (val) => SessionStorageProvider.set(this.key, val.toString());
}

class LoggedInLayout extends React.Component {
  storageProvider = new SidebarExpansionStorageProvider();

  constructor(props) {
    super(props);
    this.state = {
      isSidebarExpanded: this.storageProvider.get()
    };
  }

  triggerSideBarExpansion = () => {
    this.setState(
      (previousState) => {
        this.storageProvider.set(!previousState.isSidebarExpanded);
        return { isSidebarExpanded: !previousState.isSidebarExpanded };
      },
      () => {
        // Added setSidebar dispatched function because in the map legend is overlapping with overlay when sidebar is open and closed.
        // Map Legend width is dynamically calculated so. (Not Removed below line)
        this.props.setSidebar(this.state.isSidebarExpanded);
      }
    );
  };

  handleLoadingContainer = () => <LoadingContainer loading={true} className="loading--esg" />;

  render = () => {
    return (
      <Fragment>
        <Aside
          account_slug={this.props.user.account.slug}
          preview_ext={this.props.previewExt}
          isSidebarExpanded={this.state.isSidebarExpanded}
          triggerSideBarExpansionCallback={this.triggerSideBarExpansion}
          pathname={this.props.pathname}
          user={this.props.user}
          portal={this.props.portal}
          notifications={this.props.notifications}
          unseen={this.props.unseen}
        />
        <main role="main">
          <Suspense fallback={this.handleLoadingContainer()}>{this.props.content}</Suspense>
        </main>
        {/*jira collector (if just dev) move to AppWrapper*/}
        <JiraIssueCollector />
      </Fragment>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setSidebar: (locale) => dispatch(setSidebar(locale))
});

export default connect(null, mapDispatchToProps)(LoggedInLayout);

LoggedInLayout.propTypes = {
  content: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  user: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    }).isRequired,
    email: PropTypes.string.isRequired,
    ghost: PropTypes.shape({
      account_id: PropTypes.number,
      is_ghost: PropTypes.bool,
      user_id: PropTypes.number,
      user_username: PropTypes.string
    }),
    is_manager: PropTypes.bool,
    profile: PropTypes.shape({
      fullname: PropTypes.string,
      locale: PropTypes.string.isRequired,
      nickname: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  notifications: PropTypes.array,
  portal: PropTypes.any,
  previewExt: PropTypes.bool,
  unseen: PropTypes.number
};

LoggedInLayout.defaultProps = {
  notifications: [],
  portal: null,
  previewExt: null,
  unseen: 0
};
