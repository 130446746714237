import axios from 'axios';
import { FETCH_PERMISSIONS } from 'src/redux/actionTypes';
import { UserPermissionsURL } from 'src/apiUrls';

export const fetchPermissions = () => (dispatch) => {
  dispatch({ type: FETCH_PERMISSIONS.INVOKE });

  return axios
    .get(UserPermissionsURL)
    .then((response) =>
      dispatch({
        type: FETCH_PERMISSIONS.SUCCESS,
        payload: response.data
      })
    )
    .catch((error) =>
      dispatch({
        type: FETCH_PERMISSIONS.ERROR,
        error: error.response || 'error'
      })
    );
};
