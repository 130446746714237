import cx from 'classnames';
import React from 'react';

interface FlexRowProps {
  onClick?: (_e: React.SyntheticEvent) => void;
  align?: string;
  justify?: string;
  wrap?: string;
  spacer?: string;
  size?: string;
  addClass?: string;
  children: React.ReactNode;
  id?: string;
}

const FlexRow: React.FC<FlexRowProps> = ({
  onClick = null,
  align,
  justify,
  wrap,
  spacer,
  size,
  addClass,
  children,
  id
}: FlexRowProps) => {
  return (
    <div onClick={onClick} id={id} className={cx('l-flex-row', align, justify, wrap, spacer, size, addClass)}>
      {children}
    </div>
  );
};

export default FlexRow;
