import PropTypes from 'prop-types';
import React from 'react';

import WithAnyPermission from 'src/components/authorization/WithAnyPermission';

const WithPermission = (props) => <WithAnyPermission permissions={[props.permission]} children={props.children} />;

WithPermission.propTypes = {
  permission: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

WithPermission.defaultProps = {
  children: null
};

export default WithPermission;
