import { ActionCable } from 'react-actioncable-provider';
import React from 'react';

export const ActionCableUtils = {
  subscribeNotificationsChannel: (onNotificationReceived) => (
    <ActionCable channel={{ channel: 'NotificationsChannel' }} onReceived={onNotificationReceived} />
  ),

  subscribeUploadsChannel: (onReceivedUploadProgressTick) => (
    <ActionCable channel={{ channel: 'UploadsChannel' }} onReceived={onReceivedUploadProgressTick} />
  ),

  subscribeTurnOnOffGhostsChannel: (onTurnOnOffGhostReceived) => (
    <ActionCable channel={{ channel: 'TurnOnOffGhostsChannel' }} onReceived={onTurnOnOffGhostReceived} />
  )
};
