import _ from 'lodash';

import { hasPermission, permissionsList } from 'src/components/utils/Auth';
import { RbaViewStorageProvider } from 'src/components/common/view-switcher/RbaViewSwitcher';

export const RBA_VIEW_OPTIONS = {
  RBA: 'RBA',
  RLI: 'RLI',
  RMI: 'RMI',
  GSCDD: 'GSCDD'
};

// Only RBA users can pick a view.
// Map has 3 states (RBA, RLI, RMI) while other pages have 2.
export const allowedValue = (options, optionValue) => {
  let valueYouHaveAccessTo = null;

  if (hasPermission(permissionsList.treatAsRba)) {
    let optionThatExists = _.find(options, { value: optionValue });
    valueYouHaveAccessTo = (optionThatExists && optionThatExists.value) || RBA_VIEW_OPTIONS.RBA;
  } else if (hasPermission(permissionsList.treatAsRli)) {
    valueYouHaveAccessTo = RBA_VIEW_OPTIONS.RLI;
  } else if (hasPermission(permissionsList.treatAsRmi)) {
    valueYouHaveAccessTo = RBA_VIEW_OPTIONS.RMI;
  }

  return valueYouHaveAccessTo;
};

export const defaultRbaView = (includeRmi = true, includeGscdd = true) =>
  defaultValue(generateSelectOptions(includeRmi, includeGscdd), new RbaViewStorageProvider());

export const defaultValue = (options, storageProvider) => allowedValue(options, storageProvider.get());

export const generateSelectOptions = (includeRmi, includeGscdd = true) =>
  Object.entries(RBA_VIEW_OPTIONS)
    .filter(
      ([_key, value]) =>
        (includeRmi || value !== RBA_VIEW_OPTIONS.RMI) && (includeGscdd || value !== RBA_VIEW_OPTIONS.GSCDD)
    )
    .map(([key, value]) => ({ label: key, value: value }));
