import { FETCH_PORTALS } from 'src/redux/actionTypes';

const initialState = {
  data: {
    current: {},
    slugs: []
  },
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  }
};

export const portalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PORTALS.INVOKE:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_PORTALS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchingState: {
          error: null,
          hasFetched: true,
          isLoading: false
        }
      };
    case FETCH_PORTALS.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    default:
      return state;
  }
};
