import React from 'react';
import ReactDOM from 'react-dom/client';

/* Plugins below are used with assets pipeline yet */
import './app.css';
import 'font-awesome/css/font-awesome.css';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'sanitize.css/sanitize.css';
import 'eiq-storybook/build/styles/css/index.css';
import 'assets/stylesheets/main.scss';

import AppWrapper from 'src/components/spa/AppWrapper';
import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';
import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import reportWebVitals from 'reportWebVitals';

const MOUNT_NODE = document.getElementById('App');

const render = () => {
  SessionStorageProvider.cloneSessionStorage();
  const root = ReactDOM.createRoot(MOUNT_NODE);
  root.render(<AppWrapper />);
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise((resolve) => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render())
    .catch((err) => {
      throw err;
    });
} else {
  render();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
