import _ from 'lodash';

import { ERASE_CURRENT_USER, FETCH_CURRENT_USER, SET_LOGGED } from 'src/redux/actionTypes';

const initialState = {
  logged: null,
  data: {},
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  }
};

export const currentUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGGED:
      return {
        ...state,
        logged: action.payload
      };
    case FETCH_CURRENT_USER.INVOKE:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_CURRENT_USER.SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchingState: {
          error: null,
          // TODO it's hakish solution to handled the case when backend returnes empty user (e.g. when JWT expires)
          // fix it by returning 401 on backend, but display it only once on frontend
          hasFetched: !_.isEmpty(action.payload),
          isLoading: false
        }
      };
    case FETCH_CURRENT_USER.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    case ERASE_CURRENT_USER.SUCCESS:
      return initialState;
    case ERASE_CURRENT_USER.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    default:
      return state;
  }
};
