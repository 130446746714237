import { hasPermission, permissionsList } from 'src/components/utils/Auth';

export const parseReportNumber = (reportNumber: string) =>
  reportNumber.replaceAll(' ', '').replaceAll('(', '').replaceAll(')', '').replaceAll('/', '');

export const getOptionsBasedOnAuditRatingPermission = (options: string[]) => {
  const auditRatingOptions = ['audit_ratings', 'audit_rating'];
  return hasPermission(permissionsList.manageAuditRating)
    ? options
    : options.filter((option) => !auditRatingOptions.includes(option));
};

export const getColumnsBasedOnAuditRatingPermission = (columns: { [key: string]: boolean }) => {
  if (hasPermission(permissionsList.manageAuditRating)) {
    return columns;
  } else {
    const { rating, score, ...otherColumns } = columns;
    return otherColumns;
  }
};
