import { FETCH_NAVBAR, SET_SIDEBAR, UPDATE_NAVBAR } from 'src/redux/actionTypes';

const initialState = {
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  },
  isSidebarExpanded: null,
  notifications: [],
  previewExt: null,
  unseen: null,
  version: null
};

export const navbarReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_NAVBAR.INVOKE:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_NAVBAR.SUCCESS: {
      const { notifications, preview_ext, unseen, version } = action.payload;
      return {
        ...state,
        notifications,
        previewExt: preview_ext,
        unseen,
        version,
        fetchingState: {
          error: null,
          hasFetched: true,
          isLoading: false
        }
      };
    }
    case FETCH_NAVBAR.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    case SET_SIDEBAR:
      return {
        ...state,
        isSidebarExpanded: action.payload
      };
    case UPDATE_NAVBAR.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      };
    case UPDATE_NAVBAR.ERROR:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          error: action.error
        }
      };
    default:
      return state;
  }
};
