import { FETCH_TRANSLATIONS } from 'src/redux/actionTypes';

const initialState = {
  data: {},
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  }
};

export const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRANSLATIONS.INVOKE:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_TRANSLATIONS.SUCCESS:
      return {
        ...state,
        data: JSON.parse(action.payload.translation),
        fetchingState: {
          error: null,
          hasFetched: true,
          isLoading: false
        }
      };
    case FETCH_TRANSLATIONS.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    default:
      return state;
  }
};
