import { useEffect } from 'react';

import { hasPermission, permissionsList } from 'src/components/utils/Auth';

export const JiraIssueCollector = () => {
  const canSeeIssueCollector = hasPermission(permissionsList.reportJiraIssues);

  useEffect(() => {
    if (canSeeIssueCollector) {
      const script = document.createElement('script');
      script.src =
        'https://goodworldsolutions.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/eytyum/b/20/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=1bbf4d7f';
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
        document.querySelectorAll('.atlwdg-trigger').forEach((e) => e.remove());
      };
    }
  }, [canSeeIssueCollector]);

  return null;
};
