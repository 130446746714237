import { useSelector } from 'react-redux';
import React from 'react';

import { InternalServerError, RequestError } from 'src/components/errors/ServerError';
import { NotFound } from 'src/components/errors/NotFound';

const HAS_MAJOR_FETCHING_ERROR = 'hasMajorFetchingError';

export const RequestErrorHandler = ({ children }) => {
  const { error: errorPermissions } = useSelector((state) => state.permissions.fetchingState);
  const { error: errorTranslations } = useSelector((state) => state.translations.fetchingState);

  const { status } = useSelector((state) => state.error);

  const hasMajorFetchingError = errorPermissions || errorTranslations;

  const reportedError = hasMajorFetchingError ? HAS_MAJOR_FETCHING_ERROR : status;

  switch (reportedError) {
    case HAS_MAJOR_FETCHING_ERROR:
      return <RequestError />;
    case 500:
      return <InternalServerError />;
    case 404:
      return <NotFound />;
    default:
      return children;
  }
};
