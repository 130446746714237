export default class SessionStorageProvider {
  /**
   * Remove items from sessionStorage, except those defined in getExcludedFromClear
   */
  static clear = () => {
    Object.keys(sessionStorage).forEach((key) => {
      const excludedFromClear = SessionStorageProvider.getExcludedFromClear();
      if (!excludedFromClear.includes(key)) {
        sessionStorage.removeItem(key);
      }
    });
  };

  static get = (key) => {
    return sessionStorage.getItem(key);
  };

  /**
   * Returns array of storage keys that should not be removed on clear
   * @return {string[]}
   */
  static getExcludedFromClear = () => ['isSidebarExpanded'];

  static remove = (key) => {
    sessionStorage.removeItem(key);
  };

  static set = (key, value) => {
    sessionStorage.setItem(key, value);
  };

  // TODO find a better way to handle sessions between tabs
  static cloneSessionStorage = () => {
    // listen for changes to localStorage if sessionstorage is removed when open new tab
    if (window.addEventListener) {
      window.addEventListener(
        'storage',
        (event) => {
          if (!event) {
            event = window.event;
          }
          if (!event.newValue) return;
          if (event.key === 'getSessionStorage') {
            // another tab asked for the sessionStorage -> send it
            localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
            // the other tab should now have it, so we're done with it.
            setTimeout(() => {
              localStorage.removeItem('sessionStorage');
            }, 5000);
          } else if (event.key === 'sessionStorage') {
            // another tab sent data <- get it
            const data = JSON.parse(event.newValue);
            for (let key in data) {
              sessionStorage.setItem(key, data[key]);
            }
          }
        },
        false
      );
    }

    // Ask other tabs for session storage (this is ONLY to trigger event)
    if (!sessionStorage.length) {
      localStorage.setItem('getSessionStorage', JSON.stringify(sessionStorage));
      localStorage.removeItem('getSessionStorage');
    }
  };
}
