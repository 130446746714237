import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { filterSettingsReducer } from 'src/redux/reducers/filterSettings';
import { portalsReducer } from 'src/redux/reducers/portals';
import { currentUserReducer } from 'src/redux/reducers/currentUser';
import { errorReducer } from 'src/redux/reducers/error';
import { localeReducer } from 'src/redux/reducers/locale';
import { navbarReducer } from 'src/redux/reducers/navbar';
import { permissionsReducer } from 'src/redux/reducers/permissions';
import { requestReducer } from 'src/redux/reducers/request';
import { roleReducer } from 'src/redux/reducers/role';
import { translationsReducer } from 'src/redux/reducers/translations';

// After adding new reducer check if it can be persisted.
// If so add it to redux-persist whitelist in store.js
export const appReducer = (history) =>
  combineReducers({
    currentUser: currentUserReducer,
    error: errorReducer,
    filterSettings: filterSettingsReducer,
    locale: localeReducer,
    navbar: navbarReducer,
    permissions: permissionsReducer,
    portals: portalsReducer,
    role: roleReducer,
    router: connectRouter(history),
    request: requestReducer,
    translations: translationsReducer
  });
