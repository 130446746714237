import axios from 'axios';
import { buildPathForApi } from 'src/utils/buildPath';
import { TranslationsDataURL } from 'src/apiUrls';
import { FETCH_TRANSLATIONS } from 'src/redux/actionTypes';

export const getTranslationsActions = (initialAction) => (locale) => (dispatch) => {
  if (locale) {
    dispatch({ type: initialAction });

    return axios
      .get(buildPathForApi(TranslationsDataURL, { locale }))
      .then((response) =>
        dispatch({
          type: FETCH_TRANSLATIONS.SUCCESS,
          payload: response.data
        })
      )
      .catch((error) =>
        dispatch({
          type: FETCH_TRANSLATIONS.ERROR,
          error: error.response || 'error'
        })
      );
  }
};

export const fetchTranslations = getTranslationsActions(FETCH_TRANSLATIONS.INVOKE);
