import _ from 'lodash';
import Alert from 'react-s-alert';
import axios from 'axios';
import { ContactUsURL } from 'src/apiUrls';
import { connect as formikConnect, Form, Formik } from 'formik';
import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import EiqInputWrapper from 'src/components/common/Wrappers/EiqInputWrapper';
import { EiqTextarea } from 'eiq-storybook';
import { findEmailErrors } from 'src/classes/util/TextUtil';
import FlexRow from 'src/components/common/layout/flex/FlexRow';
import { I18n } from 'src/utils/translations';
import React, { Fragment, useState } from 'react';

interface ContactUsFormProps {
  onSubmitFail: () => void;
  onSubmitSuccess: () => void;
  user: User;
}

interface ContactUsStateInterface {
  email: string;
  question: string;
  _method: string;
  subject: string;
}

interface User {
  email: string;
}

const ContactUsForm: React.FC<ContactUsFormProps> = ({ onSubmitFail, onSubmitSuccess, user }) => {
  const [errors, setErrors] = useState<string[]>([]);

  const [initialValues] = useState<ContactUsStateInterface>({
    email: user.email || '',
    question: '',
    _method: 'POST',
    subject: ''
  });

  const emailValidation = (value: string) => {
    const errors = value ? findEmailErrors(value) : [];
    setErrors(errors);
  };

  const handleSubmit = (values: ContactUsStateInterface) => {
    if (!errors.length) {
      axios.post(ContactUsURL, values).then(onFormSuccess).catch(onFormFail);
    }
  };

  const onFormSuccess = () => {
    Alert.success(I18n.t('contact.success'));
    if (onSubmitSuccess) {
      onSubmitSuccess();
    }
  };

  const onFormFail = () => {
    Alert.error(I18n.t('contact.fail'));
    if (onSubmitFail) {
      onSubmitFail();
    }
  };

  return (
    <Fragment>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue, values }) => {
          return (
            <Form>
              <div className="form__block u-mb-0">
                <label className="required" htmlFor="contactUs_email">
                  {I18n.t('contact.name')}
                </label>
                <EiqInputWrapper
                  id="email"
                  name="email"
                  onChange={(e) => {
                    emailValidation(e.target.value);
                    setFieldValue('email', e.target.value);
                  }}
                  placeholder={I18n.t('contact.email_placeholder')}
                  required
                  value={values.email}
                  type="email"
                />
              </div>
              <div className="form__block u-mb-0">
                <label className="required" htmlFor="contactUs_subject">
                  {I18n.t('contact.subject')}
                </label>
                <EiqInputWrapper
                  id="subject"
                  name="subject"
                  onChange={(e) => setFieldValue('subject', e.target.value)}
                  placeholder={I18n.t('contact.subject_placeholder')}
                  required
                  type="text"
                  value={values.subject}
                />
              </div>
              <div className="form__block u-mb-0">
                <label className="required" htmlFor="contactUs_question">
                  {I18n.t('contact.message')}
                </label>
                <EiqTextarea
                  required
                  name="question"
                  onChange={(e) => setFieldValue('question', e.target.value)}
                  placeholder={I18n.t('contact.question_placeholder')}
                  rows={5}
                  value={values.question}
                />
              </div>
              <FlexRow justify="jc-center" spacer="u-mb-10">
                <EiqButtonWrapper
                  aria-label="send message"
                  id="submit-contact"
                  label={I18n.t('contact.send')}
                  size="btn--sm"
                  type="submit"
                  variant="btn--solid"
                  variantColor="high"
                />
              </FlexRow>
              {errors &&
                _.map(errors, (error, key) => (
                  <div key={key} className="text-center text--danger">
                    <span>{error}</span>
                  </div>
                ))}
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default formikConnect(ContactUsForm);
