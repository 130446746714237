const getActionTypesObject = (baseAction) => ({
  ERROR: `${baseAction}_ERROR`,
  INVOKE: baseAction,
  SUCCESS: `${baseAction}_SUCCESS`
});

const CLEAR_ROLE_DATA = 'CLEAR_ROLE_DATA';
const ERASE_CURRENT_USER_DATA = 'ERASE_CURRENT_USER_DATA';

const FETCH_PORTALS_DATA = 'FETCH_PORTALS_DATA';
const FETCH_CURRENT_USER_DATA = 'FETCH_CURRENT_USER_DATA';
const FETCH_NAVBAR_DATA = 'FETCH_NAVBAR_DATA';
const FETCH_PERMISSIONS_DATA = 'FETCH_PERMISSIONS_DATA';
const FETCH_ROLE_DATA = 'FETCH_ROLE_DATA';
const SET_LOGGED_DATA = 'SET_LOGGED_DATA';
const FETCH_SETTINGS_DATA = 'FETCH_SETTINGS';
const FETCH_TRANSLATIONS_DATA = 'FETCH_TRANSLATIONS_DATA';

const UPDATE_NAVBAR_DATA = 'UPDATE_NAVBAR_DATA';
const UPDATE_ROLE_DATA = 'UPDATE_ROLE_DATA';

export const CLEAR_ROLE = getActionTypesObject(CLEAR_ROLE_DATA);
export const ERASE_CURRENT_USER = getActionTypesObject(ERASE_CURRENT_USER_DATA);

export const FETCH_PORTALS = getActionTypesObject(FETCH_PORTALS_DATA);
export const FETCH_CURRENT_USER = getActionTypesObject(FETCH_CURRENT_USER_DATA);
export const FETCH_NAVBAR = getActionTypesObject(FETCH_NAVBAR_DATA);
export const FETCH_PERMISSIONS = getActionTypesObject(FETCH_PERMISSIONS_DATA);
export const FETCH_ROLE = getActionTypesObject(FETCH_ROLE_DATA);
export const FETCH_SETTINGS = getActionTypesObject(FETCH_SETTINGS_DATA);
export const FETCH_TRANSLATIONS = {
  ...getActionTypesObject(FETCH_TRANSLATIONS_DATA),
  INVOKE_REFETCH: 'FETCH_TRANSLATIONS_INVOKE_REFETCH'
};

export const UPDATE_NAVBAR = getActionTypesObject(UPDATE_NAVBAR_DATA);
export const UPDATE_ROLE = getActionTypesObject(UPDATE_ROLE_DATA);
export const UPDATE_SUPPORTED_FILTERS = 'UPDATE_SUPPORTED_FILTERS';

export const SET_LOGGED = SET_LOGGED_DATA;
export const SET_LOCALE = 'SET_LOCALE';
export const SET_SIDEBAR = 'SET_SIDEBAR';
export const SET_ERROR = 'SET_ERROR';
export const SET_REQUEST_INTERMINABLE = 'SET_REQUEST_INTERMINABLE';
