import React from 'react';
import svg500 from 'assets/images/error-500.svg';

const ServerError = ({ isInternalServerError }) => (
  <section className="l-container l-flex-centered">
    <img className="u-mb-60 u-img-fluid" src={svg500} alt="500: Internal Server Error" width="50%" />
    <div className="u-text-center u-mb-30">
      <h1 className="u-mb-15">Oops! This is unexpected.</h1>
      {isInternalServerError ? (
        <h3 className="u-mb-15">An internal server error has occured and we're working on fixing the problem.</h3>
      ) : (
        <h3 className="u-mb-15">An error with your request has occured.</h3>
      )}
      <h3 className="u-mb-60">
        Try to refresh this page or email <span className="text-black">{process.env.USER_SUPPORT_EMAIL}</span> if the
        problem persists.
      </h3>
    </div>
  </section>
);

export const InternalServerError = () => <ServerError isInternalServerError={true} />;

export const RequestError = () => <ServerError />;
