import axios from 'axios';
import { FETCH_NAVBAR, SET_SIDEBAR, UPDATE_NAVBAR } from 'src/redux/actionTypes';
import { NavbarInfoURL } from 'src/apiUrls';

export const fetchNavbar = () => (dispatch) => {
  dispatch({ type: FETCH_NAVBAR.INVOKE });

  return axios
    .get(NavbarInfoURL)
    .then((response) =>
      dispatch({
        type: FETCH_NAVBAR.SUCCESS,
        payload: response.data
      })
    )
    .catch((error) =>
      dispatch({
        type: FETCH_NAVBAR.ERROR,
        error: error.response
      })
    );
};

export const updateNavbar = (data) => (dispatch) => {
  dispatch({ type: UPDATE_NAVBAR.INVOKE });
  try {
    dispatch({
      type: UPDATE_NAVBAR.SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: UPDATE_NAVBAR.ERROR,
      error
    });
  }
};

export const setSidebar = (isSidebarExpanded) => (dispatch) => {
  dispatch({ type: SET_SIDEBAR, payload: isSidebarExpanded });
};
