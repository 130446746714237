import { FETCH_SETTINGS, UPDATE_SUPPORTED_FILTERS } from 'src/redux/actionTypes';
import { ProgramSettingsActions } from 'src/classes/util/RequestUtil';

export const fetchFilterSettings = () => (dispatch) => {
  dispatch({ type: FETCH_SETTINGS.INVOKE });

  return ProgramSettingsActions.getFilters(false, true)
    .then((response) => {
      dispatch({
        type: FETCH_SETTINGS.SUCCESS,
        payload: response.data.filters
      });

      const supportedFilters = Object.entries(response.data.supported_filters).reduce(
        (output, [key, { data_type, pages }]) => {
          pages.reduce((pageOutput, page) => {
            if (!(page in pageOutput)) {
              pageOutput[page] = {};
            }
            pageOutput[page][key] = data_type;
            return pageOutput;
          }, output);

          return output;
        },
        {}
      );

      dispatch({
        type: UPDATE_SUPPORTED_FILTERS,
        payload: supportedFilters
      });
    })
    .catch((error) =>
      dispatch({
        type: FETCH_SETTINGS.ERROR,
        error: error.response || 'error'
      })
    );
};

export const setFilterSettings = (data) => (dispatch) =>
  dispatch({
    type: FETCH_SETTINGS.SUCCESS,
    payload: data
  });
