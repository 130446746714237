import _ from 'lodash';
import Color from 'chartjs-color';
import colors from 'tailwindcss/colors';
import { getThemeColor } from 'src/utils/tailwindUtils';

export enum RiskCategory {
  EXTREME = 'extreme',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  NO_DATA = 'no_data',
  'NO-DATA' = 'no-data'
}

export const RISK_COLOR_STEPS = [2.5, 5.0, 7.5];
export const ASSESSMENT_RISK_COLOR_STEPS = [5.1, 7.1, 9.1];

/**
 * Returns red/yellow/orange/green color based on value from 1 to 10
 *  *
 * @param value the number to format
 * @param steps three-step array with coloring breakpoints
 * @return {string} returns color in HEX format
 */
export const conditionalFormatting = (value = 0, steps = RISK_COLOR_STEPS): string => {
  let color: string = getThemeColor('low');

  if (value < steps[0]) {
    color = getThemeColor('extreme');
  } else if (value < steps[1]) {
    color = getThemeColor('high');
  } else if (value < steps[2]) {
    color = getThemeColor('medium');
  }

  return color;
};

/**
 * Returns red/yellow/orange/green color based on risk name
 */
export const conditionalFormattingByName = (value: string): string => {
  switch (true) {
    case value === RiskCategory.EXTREME:
      return getThemeColor('extreme');
    case value === RiskCategory.HIGH:
      return getThemeColor('high');
    case value === RiskCategory.MEDIUM:
      return getThemeColor('medium');
    case value === RiskCategory.NO_DATA:
      return getThemeColor('no-data');
    default:
      return getThemeColor('low');
  }
};

export const formatNumber = (number: string | number): string => number && Number(number).toLocaleString();

export const positiveColor = (lowIsGood = false): string =>
  lowIsGood ? getThemeColor('extreme') : getThemeColor('low');

export const negativeColor = (lowIsGood = true): string =>
  lowIsGood ? getThemeColor('low') : getThemeColor('extreme');

export const conditionalLabel = (value, steps = RISK_COLOR_STEPS, isBinary = false): string => {
  if (isBinary) return value === 1 ? RiskCategory.EXTREME : RiskCategory['NO-DATA'];

  if (value !== 0 && !value) return RiskCategory['NO-DATA'];
  if (value < steps[0]) return RiskCategory.EXTREME;
  if (value < steps[1]) return RiskCategory.HIGH;
  if (value < steps[2]) return RiskCategory.MEDIUM;
  return RiskCategory.LOW;
};

export const conditionalLabelColor = (value, steps = RISK_COLOR_STEPS): string =>
  value === null || value === undefined ? RiskCategory['NO-DATA'] : conditionalLabel(value, steps);

export const conditionalLabelTextColor = (value, colorCode = false) => {
  if (value === RiskCategory.LOW || value === RiskCategory.MEDIUM) {
    return colorCode ? colors.black : 'black';
  }
  return colorCode ? colors.white : 'white';
};

export const riskManagementLabel = (value: string) => {
  switch (conditionalLabelColor(value)) {
    case RiskCategory.EXTREME:
      return 'poor';
    case RiskCategory.HIGH:
      return 'moderate';
    case RiskCategory.MEDIUM:
      return 'good';
    case RiskCategory.LOW:
      return 'strong';
    default:
      return RiskCategory['NO-DATA'];
  }
};

const normalizeColorValue = (cVal: number): number => _.clamp(cVal, 0, 255);

/**
 * Returns modified color hex
 *
 * @param {string} colorHex - can be provided with or without # at the beginning
 * @param {integer} alterationAmount - positive will lighten, negative will darken the color. It's amount not percent
 * @return {string}
 */
export const lightenDarkenColor = (colorHex: string, alterationAmount: number): string => {
  let usePound = false;
  if (colorHex[0] === '#') {
    colorHex = colorHex.slice(1);
    usePound = true;
  }

  const num = parseInt(colorHex, 16);

  let r = (num >> 16) + alterationAmount;
  r = normalizeColorValue(r);

  let g = (num & 0x0000ff) + alterationAmount;
  g = normalizeColorValue(g);

  let b = ((num >> 8) & 0x00ff) + alterationAmount;
  b = normalizeColorValue(b);

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

/**
 * Dynamically returns color which is lighter than color, which is specified in color param.
 * @param color that we start with
 * @param value decimal value between 1.0 and 0.0 that specifies how much lighter output color will be
 */
export const lightenUpColor = (color: string, value: number): string => Color(color).alpha(value).rgbaString();

/**
 * Returns color corresponding to letter param.
 * @param value string values: N/A, A, B, C, D
 */
export const conditionalFormattingByLetter = (value: 'A' | 'B' | 'C' | 'D' | 'N/A', colorCode = false) => {
  if (value === 'A') return colorCode ? getThemeColor('low') : 'bg-low';
  else if (value === 'B') return colorCode ? getThemeColor('medium') : 'bg-medium';
  else if (value === 'C') return colorCode ? getThemeColor('high') : 'bg-high';
  else if (value === 'D') return colorCode ? getThemeColor('extreme') : 'bg-extreme';
  else return colorCode ? getThemeColor('no-data') : 'bg-no_data';
};

/**
 * Capitalizes each word in a given string
 * @param value string
 */
export const capitalizeString = (value: string): string => value.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());

/**
 * Returns background color based on user status
 * @param status string
 */
type Status = 'low' | 'high' | 'extreme';
export const userStatusColor = (status: string): Status => {
  let value: Status = 'low';
  if (status === 'inactive') {
    value = 'high';
  } else if (status === 'deactivated' || status === 'disabled') {
    value = 'extreme';
  }
  return value;
};
