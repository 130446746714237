import _ from 'lodash';

import { I18n } from 'src/utils/translations';
import { toTranslationKey } from 'src/classes/util/TextUtil';

export const YES_EXPECTED_INDICES = ['osh_framework'];
export const LEVERAGE_VALUES = ['low_leverage', 'medium_leverage', 'high_leverage', 'extreme_leverage'];
export const MATERIALITY_VALUES = [
  'direct_sole_sourced',
  'direct_multi_sourced',
  'direct_commodities',
  'indirect_offsite',
  'indirect_both',
  'indirect_onsite'
];
export const SPEND_VALUES = ['low_spend', 'medium_spend', 'high_spend'];
export const STATUS_VALUES = ['not_started', 'in_progress', 'completed'];
export const CONFIDENCE = 'confidence';
export const CAHRA = 'cahras';
export const DODD_FRANK = 'dodd_frank_act';
export const INDIVIDUAL_MAP_SANCTIONS = [CAHRA, DODD_FRANK];
export const OVERALL_PILLAR = 'overall';
export const RBA_PILLARS = {
  labor: 'labor',
  healthAndSafety: 'health_and_safety',
  environment: 'environment',
  businessEthics: 'business_ethics',
  managementSystems: 'management_systems'
};
export const SUB_MAPPING_GRL_TAB = [
  'overall',
  'labor',
  'health_and_safety',
  'environment',
  'business_ethics',
  'management_systems',
  'rli'
];
export const SECOND_LEVEL_CATEGORIES = ['geography', 'civil_society', 'supply_chain'];
export const FACTORY_RISKS_OVERALL = [
  'riskExposure',
  'civilSociety',
  'product',
  'supplyChain',
  'workerSentiment',
  'sentinel',
  'leverage',
  'internalRisk',
  'riskManagement',
  'saq',
  'assessment',
  'cap',
  'elearning',
  'workerVoice'
];
export const FACTORY_RISKS_EXPOSURE = [
  'civilSociety',
  'product',
  'supplyChain',
  'workerSentiment',
  'sentinel',
  'leverage',
  'internalRisk'
];
export const FACTORY_RISKS_MANAGEMENT = ['saq', 'assessment', 'cap', 'elearning', 'workerVoice'];
export const SUPPLIER_RISKS = ['civilSociety', 'supplyChain', 'product', 'spend', 'materiality'];

export function getStatusValues() {
  return _.map(STATUS_VALUES, (status) => ({
    value: status,
    label: I18n.t(`suppliers.risk_list.status.${toTranslationKey(status)}`)
  }));
}

export function getLeverageValues() {
  return _.map(LEVERAGE_VALUES, (leverage) => ({
    value: leverage,
    label: I18n.t(`suppliers.risk_list.leverage_types.${leverage}`)
  }));
}

export function getMaterialityValues() {
  return _.map(MATERIALITY_VALUES, (materiality) => ({
    value: materiality,
    label: I18n.t(`suppliers.risk_list.materiality_types.${materiality}`)
  }));
}

export function getSpendValues() {
  return _.map(SPEND_VALUES, (spend) => ({
    value: spend,
    label: I18n.t(`suppliers.risk_list.spend_types.${spend}`)
  }));
}

/**
 * Entity representing supplier risk data.
 */
export class SupplierRiskData {
  assessmentScore = null;
  capScore = null;
  confidenceScore = null;
  elearningScore = null;
  geographyScore = null;
  internalRiskScore = null;
  leverageScore = null;
  materialityScore = null;
  overallScore = null;
  productScore = null;
  riskExposureScore = null;
  riskManagementScore = null;
  saqScore = null;
  sentinelScore = null;
  spendScore = null;
  supplyChainScore = null;
  workerSentimentScore = null;
  workerVoiceScore = null;

  constructor(riskData) {
    this.assessmentScore = riskData.assessmentScore;
    this.capScore = riskData.capScore;
    this.confidenceScore = riskData.confidenceScore;
    this.elearningScore = riskData.elearningScore;
    this.geographyScore = riskData.geographyScore;
    this.internalRiskScore = riskData.internalRiskScore;
    this.leverageScore = riskData.leverageScore;
    this.materialityScore = riskData.materialityScore;
    this.overallScore = riskData.overallScore;
    this.productScore = riskData.productScore;
    this.riskManagementScore = riskData.riskManagementScore;
    this.riskExposureScore = riskData.riskExposureScore;
    this.saqScore = riskData.saqScore;
    this.sentinelScore = riskData.sentinelScore;
    this.spendScore = riskData.spendScore;
    this.supplyChainScore = riskData.supplyChainScore;
    this.workerSentimentScore = riskData.workerSentimentScore;
    this.workerVoiceScore = riskData.workerVoiceScore;
  }

  static fromDto = (dto) => {
    return new SupplierRiskData({
      assessmentScore: dto.assessment_score,
      capScore: dto.cap_score,
      confidenceScore: dto.confidence_score,
      elearningScore: dto.elearning_score,
      geographyScore: dto.civil_society_score,
      internalRiskScore: dto.internal_risk_score,
      leverageScore: dto.leverage_score,
      materialityScore: dto.materiality_score,
      overallScore: dto.overall_score,
      productScore: dto.product_score,
      riskManagementScore: dto.risk_management_score,
      riskExposureScore: dto.risk_exposure_score,
      sentinelScore: dto.sentinel_score,
      saqScore: dto.saq_score,
      spendScore: dto.spend_score,
      supplyChainScore: dto.supply_chain_score,
      workerSentimentScore: dto.worker_sentiment_score,
      workerVoiceScore: dto.worker_voice_score
    });
  };
}

/**
 * Entity representing supplier data.
 */
export default class SupplierData {
  name = null;

  countryId = null;
  countryName = null;

  businessUnit = null;
  division = null;
  internalRisk = null;
  region = null;
  isVendor = null;
  vendors = null;

  productCategoryId = null;
  productCategoryName = null;
  edited = null;

  assessmentCount = null;
  articles_count = null;
  leverage = null;
  materiality = null;
  sector = null;
  sector_id = null;
  spend = null;
  sources = null;

  riskData = null;
  lowLevelScoresInfo = null;

  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.businessUnit = data.businessUnit;
    this.countryId = data.countryId;
    this.countryName = data.countryName;
    this.division = data.division;
    this.region = data.region;
    this.productCategoryId = data.productCategoryId;
    this.productCategoryName = data.productCategoryName;
    this.edited = data.edited;
    this.assessmentCount = data.assessmentCount;
    this.articles_count = data.articles_count;
    this.leverage = data.leverage;
    this.materiality = data.materiality;
    this.sector = data.sector;
    this.sector_id = data.sector_id;
    this.spend = data.spend;
    this.sources = data.sources;
    this.riskData = data.riskData;
    this.lowLevelScoresInfo = data.lowLevelScoresInfo;
    this.isVendor = data.isVendor;
    this.vendors = data.vendors;
    this.internalRisk = data.internalRisk;
  }

  static fromDto = (dto) => {
    return new SupplierData({
      id: dto.id,
      name: dto.name,
      businessUnit: dto.business_unit,
      countryId: dto.country_id,
      countryName: dto.country_name,
      division: dto.division,
      region: dto.region,
      productCategoryId: dto.product_category_id,
      productCategoryName: dto.product_category_name,
      assessmentCount: dto.assessment_count,
      leverage: dto.leverage,
      materiality: dto.materiality,
      sector: dto.sector,
      sector_id: dto.sector_id,
      spend: dto.spend,
      sources: dto.sources,
      riskData: dto.risk_data && SupplierRiskData.fromDto(dto.risk_data),
      lowLevelScoresInfo: dto.low_level_scores_info,
      isVendor: dto.is_vendor,
      vendors: dto.vendors,
      internalRisk: dto.internal_risk_score,
      articles_count: dto.articles_count,
      edited: dto.edited
    });
  };
}
