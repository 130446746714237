import axios from 'axios';
import { ERASE_CURRENT_USER, FETCH_CURRENT_USER, SET_LOGGED } from 'src/redux/actionTypes';
import { CurrentUserDataURL } from 'src/apiUrls';

export const fetchCurrentUser = () => (dispatch) => {
  dispatch({ type: FETCH_CURRENT_USER.INVOKE });

  return axios
    .get(CurrentUserDataURL)
    .then((response) =>
      dispatch({
        type: FETCH_CURRENT_USER.SUCCESS,
        payload: response.data
      })
    )
    .catch((error) =>
      dispatch({
        type: FETCH_CURRENT_USER.ERROR,
        error: error.response || 'error'
      })
    );
};

export const eraseCurrentUser = () => (dispatch) => {
  try {
    dispatch({ type: ERASE_CURRENT_USER.SUCCESS });
  } catch (error) {
    dispatch({
      type: ERASE_CURRENT_USER.ERROR,
      error
    });
  }
};

export const setLogged = (data) => (dispatch) => {
  dispatch({ type: SET_LOGGED, payload: data });
};
