import { every } from 'lodash';

import { includesOneOf } from 'src/components/utils/Array';
import { store } from 'src/redux/store';

export const permissionsList = {
  accountEntitlementsManage: 'account_entitlements_manage',
  accountUsersManage: 'account_users_manage',
  accountsManage: 'accounts_manage',
  accountsView: 'accounts_view',
  addNotifications: 'add_notifications',
  allowGhosting: 'allow_ghosting',
  allowGhostingAsAUser: 'allow_ghosting_as_a_user',
  analyticsExport: 'analytics_export',
  analyticsPageView: 'analytics_page_view',
  articlesPageView: 'articles_page_view',
  aruvioLinkageView: 'aruvio_linkage_view',
  assessmentsEditMode: 'assessments_edit_mode',
  assessmentsExport: 'assessments_export',
  assessmentsView: 'assessments_view',
  canDownloadMethodologyFile: 'can_download_methodology_file',
  changeAssessmentsData: 'change_assessments_data',
  changeFactoryBaseData: 'change_factory_base_data',
  countriesDetailsExport: 'countries_details_export',
  countryRiskLandscapeView: 'country_risk_landscape_view',
  customizeWeights: 'customize_weights',
  downloadAuditReports: 'download_audit_reports',
  environmentalMetricsView: 'environmental_metrics_view',
  factoriesEditMode: 'factories_edit_mode',
  groupsManage: 'groups_manage',
  hideFactories: 'hide_factories',
  manageAuditRating: 'manage_audit_rating',
  manageAuditReports: 'manage_audit_reports',
  manageDefaultWeights: 'manage_default_weights',
  manageUploadedSites: 'manage_uploaded_sites',
  mergeFactories: 'merge_factories',
  portalsManage: 'portals_manage',
  portalsView: 'portals_view',
  productRiskLandscapeView: 'product_risk_landscape_view',
  rbaOnlineLinkageView: 'rba_online_linkage_view',
  rbaSwitcher: 'rba_switcher',
  riskIndexCustomization: 'risk_index_customization',
  riskLandscapeExport: 'risk_landscape_export',
  riskLandscapeView: 'risk_landscape_view',
  riskLandscapeWeighting: 'risk_landscape_weighting',
  riskYearSelector: 'risk_year_selector',
  rolesManage: 'roles_manage',
  scorecardArticlesView: 'scorecard_articles_view',
  scorecardRiskManagementView: 'scorecard_risk_management_view',
  scorecardRiskScoresView: 'scorecard_risk_scores_view',
  seeActivityLogs: 'see_activity_logs',
  seeNewDashboard: 'see_new_dashboard',
  seeOldDashboard: 'see_old_dashboard',
  seeProgramSettings: 'see_program_settings',
  sitePerformanceExport: 'site_performance_export',
  sitePerformanceView: 'site_performance_view',
  staffManage: 'staff_manage',
  suppliersView: 'suppliers_view',
  systemAdminAccess: 'system_admin_access',
  treatAsRba: 'treat_as_rba',
  treatAsRli: 'treat_as_rli',
  treatAsRmi: 'treat_as_rmi',
  treatAsStaff: 'treat_as_staff',
  trendReportsExport: 'trend_reports_export',
  trendReportsManage: 'trend_reports_manage',
  trendReportsView: 'trend_reports_view',
  viewTrendReportsV3: 'view_trend_reports_v3',
  reportBuilderView: 'report_builder_view',
  reportBuilderManage: 'manage_reports',
  reportJiraIssues: 'report_jira_issues',
  workerEngagementManage: 'worker_engagement_manage',
  workerEngagementView: 'worker_engagement_view'
};

/**
 * Returns true if user has all of given permissions, false otherwise.
 * @param permissions permissions to test
 */
export function hasAllPermissions(permissions) {
  const userPermissions = store.getState().permissions.data;

  return every(permissions, (item) => userPermissions.includes(item));
}

/**
 * Check if user has any of allowed permissions
 *
 * @param {string[]} allowedPermissions - array of allowed permissions
 * @param {string[]} [userPermissions] - can be passed or will be fetched from sessionStorage
 * @returns {boolean} - true if user has at least one of allowedPermissions
 */
export function hasAnyPermission(allowedPermissions, userPermissions) {
  /** @see PersistAuthUser - for userPermissions structure */
  userPermissions = userPermissions || store.getState().permissions.data;
  return includesOneOf(allowedPermissions, userPermissions);
}

export function hasPermission(allowedPermission) {
  return hasAnyPermission([allowedPermission]);
}

export const isManager = () => store.getState().currentUser.data.is_manager;

export const usesFactory = () => store.getState().currentUser.data.portal.distributor_kind === 'factory';

export const isSystemAdministration = () =>
  store.getState().currentUser.data.roles.some((item) => item.name === 'System Administrator');

export const isGhost = () => store.getState().currentUser.data.ghost.is_ghost;
