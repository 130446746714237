import _ from 'lodash';
import Alert from 'react-s-alert';
import axios from 'axios';

import { currentJWT, revokeToken } from 'src/utils/JWTUtils';
import { setError } from 'src/redux/actions/error';
import { setRequestInterminable } from 'src/redux/actions/request';
import { SignInURL } from 'src/apiUrls';
import { store } from 'src/redux/store';
import history from 'src/history';

const getPathname = (url) => {
  if (url.startsWith('https') || url.startsWith('http')) {
    const { pathname } = new URL(url);
    return pathname;
  } else {
    const pathname = url.includes('?') ? url.split('?')[0] : url;
    return pathname;
  }
};

export const AxiosConfig = () => {
  const TIME_TO_ALERT = 10000;
  let requestIntervals = {};
  const cancelTokens = {};

  history.listen(() => {
    _.forOwn(requestIntervals, (value, url) => {
      clearTimeout(value.interval);
      delete requestIntervals[url];
    });
    if (store.getState().request.interminable) {
      store.dispatch(setRequestInterminable(false));
    }
  });

  const requestHandler = (url) => {
    const startTime = Date.now();

    if (requestIntervals[url]) {
      clearTimeout(requestIntervals[url].interval);
      delete requestIntervals[url];
    }

    if (!store.getState().request.interminable) {
      requestIntervals[url] = {
        interval: setTimeout(() => {
          if (!store.getState().request.interminable) {
            store.dispatch(setRequestInterminable(true));
          }
        }, TIME_TO_ALERT),
        startTime: startTime
      };
    }
  };

  const jwtInterceptor = (config) => {
    const addCancelToken = (pathname) => {
      if (config?.isLatest) {
        let source = axios.CancelToken.source();
        config.cancelToken = source.token;
        if (pathname in cancelTokens) {
          treatRequestAsCompleted(cancelTokens[pathname].url);
          cancelTokens[pathname].source.cancel();
        }

        cancelTokens[pathname] = { source, url: config.url };
      }
    };

    requestHandler(config.url);

    if (!currentJWT()) {
      return config;
    }

    // Add JWT header only to 'trusted' domains in axios calls
    if (config.url.startsWith('https') || config.url.startsWith('http')) {
      const { origin, pathname } = new URL(config.url);
      addCancelToken(pathname);

      // eslint-disable-next-line no-undef
      const allowedOrigins = [process.env.HOST_DEV, process.env.URL_STAGING, process.env.HOST_PROD];

      if (allowedOrigins.includes(origin)) {
        config.headers.authorization = currentJWT();
      }
    } else {
      const pathname = getPathname(config.url);
      addCancelToken(pathname);
      config.headers.authorization = currentJWT();
    }
    return config;
  };

  const treatRequestAsCompleted = (url) => {
    if (url) {
      const path = ('' + url).replace(/^.*\/\/[^/]+/, '');

      if (requestIntervals[path]) {
        clearTimeout(requestIntervals[path].interval);
        delete requestIntervals[path];
      }
    }

    const hasInterminableRequest = Object.values(requestIntervals).some(
      ({ startTime }) => Date.now() - startTime > TIME_TO_ALERT
    );

    !hasInterminableRequest && store.dispatch(setRequestInterminable(false));
  };

  const responseHandler = (response) => {
    const url = response?.config?.url;
    if (response?.config?.isLatest) {
      const pathname = getPathname(url);
      delete cancelTokens[pathname];
    }

    treatRequestAsCompleted(url);

    return response;
  };

  const responseError = (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
    if (error.response) {
      const { status, data, request, config } = error.response;

      treatRequestAsCompleted(request?.responseURL);

      if (!config?.throwError && status === 401 && getPathname(config?.url) !== SignInURL) {
        Alert.error(data.error);
        return new Promise(revokeToken);
      } else if ((status === 404 || status === 500) && config?.throwError) {
        store.dispatch(setError({ status }));
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  };

  axios.interceptors.request.use(jwtInterceptor);
  axios.interceptors.response.use((res) => responseHandler(res), responseError);
  axios.defaults.baseURL = `${process.env.BACKEND_URL}:${process.env.BACKEND_PORT}`;
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
  return null;
};
