import { useEffect, useRef } from 'react';

import { includesOneOf } from 'src/components/utils/Array';
import { store } from 'src/redux/store';

/**
 * Returns true if user has all of given permissions, false otherwise.
 * @param permissions permissions to test
 */
export const hasAllPermissions = (permissions) => {
  const userPermissions = store.getState().currentUser.data.permissions;
  return permissions.every((item) => userPermissions.includes(item));
};

/**
 * Check if user has any of allowed permissions
 *
 * @param {string[]} allowedPermissions - array of allowed permissions
 * @param {string[]} [selectedPermissions] - can be passed or will be fetched from sessionStorage
 * @returns {boolean} - true if user has at least one of allowedPermissions
 */
export const hasAnyPermission = (allowedPermissions, selectedPermissions) => {
  const currentUserPermissions = store.getState().currentUser.data.permissions;
  const userPermissions = selectedPermissions || currentUserPermissions;
  return includesOneOf(allowedPermissions, userPermissions);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const hasPermission = (allowedPermission) => hasAnyPermission([allowedPermission]);
