import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import AdminMenu from 'src/components/layout/aside/menus/AdminMenu';
import AsideDropdownMenu from 'src/components/layout/aside/AsideDropdownMenu';
import AsideHeader from 'src/components/layout/aside/AsideHeader';
import AsideItem from 'src/components/layout/aside/AsideItem';
import { buildPath } from 'src/utils/buildPath';
import DropdownItem from 'src/components/layout/header/DropdownItem.jsx';
import FlexColumn from 'src/components/common/layout/flex/FlexColumn';
import { getDistributorTranslation } from 'src/classes/suppliers/SuppliersUtil';
import { hasAnyPermission, hasPermission, isManager, permissionsList } from 'src/components/utils/Auth';
import { I18n } from 'src/utils/translations';
import IconAnalytics from 'assets/images/sidebar/icon_analytics.svg';
import IconAccounts from 'assets/images/sidebar/icon_accounts.svg';
import IconAssessment from 'assets/images/sidebar/icon_assessment.svg';
import IconDashboard from 'assets/images/sidebar/icon_dashboard.svg';
import IconEngagement from 'assets/images/sidebar/icon_engagement.svg';
import IconEnv from 'assets/images/sidebar/icon_env.svg';
import IconFactory from 'assets/images/sidebar/icon_factory.svg';
import IconGeography from 'assets/images/sidebar/icon_geography.svg';
import IconGlobal from 'assets/images/sidebar/icon_global.svg';
import IconOverview from 'assets/images/sidebar/icon_overview.svg';
import IconProduct from 'assets/images/sidebar/icon_product.svg';
import IconReport from 'assets/images/sidebar/icon_report.svg';
import IconSentinel from 'assets/images/sidebar/icon_sentinel.svg';
import IconSurveys from 'assets/images/sidebar/icon_surveys.svg';
import IconUpload from 'assets/images/sidebar/icon_upload.svg';
import PortalMenuProvider from 'src/components/layout/aside/menus/PortalMenu.provider';
import { Routes } from 'src/routesConfig';
import WithPermission from 'src/components/authorization/WithPermission';

export default class Aside extends React.Component {
  // The order of the keys match the order of the items in the sidebar.
  keys = {
    undefined: 'undefined',
    home: '',

    risk_landscape: 'risk-landscape',
    global_risk_landscape: 'risk-landscape/global',
    country_risk_landscape: 'risk-landscape/geography',
    product_risk_landscape: 'risk-landscape/product',

    articles: 'sentinel',

    segmentation: 'segmentation',
    suppliers_upload: 'upload-site-data',
    suppliers: 'segmentation/site-performance',
    analytics: 'analytics',

    environmental_metrics: 'environmental-metrics',
    my_program: 'my-program',
    trend_reports: 'my-program/program-trend-reports',
    risk_overview: 'risk-management/overview',
    report_builder: 'report-builder',

    overview: 'worker-engagement',
    programs: 'programs',

    assessments: 'assessments',

    admin: 'admin1', // Note: this is not a typo mistake. prevented to activating the class for the admin menu.
    system_admin: 'system-admin',
    surveys: 'surveys',

    accounts: 'accounts',
    language: 'language'
  };

  constructor(props) {
    super(props);
    this.state = { active: this.getActiveFromPath() || '' };
    this.NavMenuRef = React.createRef();
    this.AdminMenuRef = React.createRef();
  }

  handleScroll = () => {
    this.setState({
      AdminMenuRefOffsetX: this.AdminMenuRef.current?.offsetTop - this.AdminMenuRef.current?.scrollTop
    });
  };

  componentDidMount = () => {
    // for position recalculation when the height of the menu changes
    this.NavMenuRef.current.addEventListener('click', this.handleScroll);
    this.handleScroll();
  };

  componentWillUnmount = () => {
    this.NavMenuRef.current.removeEventListener('scroll', this.handleScroll);
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.isSidebarExpanded !== this.props.isSidebarExpanded) {
      this.handleScroll();
    }
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ active: this.getActiveFromPath() });
    }
  };

  getActiveFromPath = () => {
    let path = this.props.pathname;

    // 1. Root path provides to home page.
    if (path === '/') {
      return this.keys.home;
    }

    // 3. Users are available under admin section.
    if (path.includes('users')) {
      return this.keys.manage_users;
    }

    // 4. Supplier list path is also included in other supplier pages.
    if (path.includes('suppliers') && !path.includes(this.keys.suppliers_upload)) {
      return this.keys.suppliers;
    }

    if (path.includes('dashboards')) {
      return this.keys.report_builder;
    }

    const key = Object.values(this.keys)
      .reverse()
      .find((key) => path.includes(key));
    return isEmpty(key) ? this.keys.undefined : key;
  };

  commonProps = (key, title) => {
    return {
      callback: () => this.setState({ active: key }),
      isActive: `/${this.state.active}/`.includes(`/${key}/`),
      key: key,
      title: title,
      isSidebarExpanded: this.props.isSidebarExpanded
    };
  };

  // Rendering

  renderEngagementMenu = () => (
    <Fragment key="engagement">
      <AsideItem
        id="engagement"
        svgImage={IconEngagement}
        href={Routes.Engagement.path}
        {...this.commonProps(this.keys.overview, getDistributorTranslation('header', 'overview'))}
      />

      {/*// TODO: Fix this later - only account managers should see this*/}
      <AsideItem
        id="surveys"
        svgImage={IconSurveys}
        href={Routes.Programs.path}
        {...this.commonProps(this.keys.surveys, I18n.t('programs'))}
      />
    </Fragment>
  );

  renderActionMenu = () => {
    return (
      <Fragment>
        {hasPermission(permissionsList.accountsView) && (
          <AsideItem
            id="toolbar-accounts"
            href={Routes.Accounts.path}
            svgImage={IconAccounts}
            {...this.commonProps(this.keys.accounts, I18n.t('header.accounts'))}
          />
        )}
      </Fragment>
    );
  };

  renderRiskLandscapeMenu = () => {
    const items = [];

    if (hasAnyPermission([permissionsList.treatAsRba, permissionsList.treatAsRli, permissionsList.treatAsRmi])) {
      if (hasPermission(permissionsList.riskLandscapeView)) {
        items.push(
          <AsideItem
            id="risk-landscape-global"
            svgImage={IconGlobal}
            href={Routes.RiskLandscape.path}
            {...this.commonProps(this.keys.global_risk_landscape, getDistributorTranslation('risk_landscape', 'title'))}
          />
        );
      }
    }

    if (hasPermission(permissionsList.countryRiskLandscapeView)) {
      items.push(
        <AsideItem
          id="risk-landscape-geography"
          svgImage={IconGeography}
          href={Routes.CountryRiskLandscape.path}
          {...this.commonProps(this.keys.country_risk_landscape, I18n.t('risk_landscape.country.title'))}
        />
      );
    }

    if (hasPermission(permissionsList.productRiskLandscapeView)) {
      items.push(
        <AsideItem
          id="risk-landscape-product"
          svgImage={IconProduct}
          href={Routes.ProductRiskLandscape.path}
          {...this.commonProps(this.keys.product_risk_landscape, I18n.t('risk_landscape.product.title'))}
        />
      );
    }

    if (hasPermission(permissionsList.articlesPageView)) {
      items.push(
        <AsideItem
          id="sentinel-events"
          href={Routes.Articles.path}
          svgImage={IconSentinel}
          {...this.commonProps(this.keys.articles, I18n.t('header.articles'))}
        />
      );
    }

    if (items.length === 0) {
      return null;
    }

    return (
      <AsideDropdownMenu
        {...this.commonProps(this.keys.risk_landscape, I18n.t('risk_landscape.nav_bar_title'))}
        opened={true}
        id="risk-landscape">
        {items}
      </AsideDropdownMenu>
    );
  };

  renderMyProgramMenu = () => {
    const items = [];

    if (hasPermission(permissionsList.viewTrendReportsV3)) {
      items.push(
        <AsideItem
          id="risk-management-overview"
          svgImage={IconOverview}
          href={Routes.RiskManagementOverView.path}
          {...this.commonProps(this.keys.risk_overview, I18n.t('header.overview'))}
        />
      );
    }

    if (hasPermission(permissionsList.trendReportsView)) {
      items.push(
        <AsideItem
          id="program-trend-reports"
          href={Routes.TrendReports.path}
          svgImage={IconReport}
          {...this.commonProps(this.keys.trend_reports, getDistributorTranslation('header', 'trend_reports'))}
        />
      );
    }

    if (hasPermission(permissionsList.environmentalMetricsView)) {
      items.push(
        <AsideItem
          id="environmental-metrics"
          href={Routes.EnvironmentalMetrics.path}
          svgImage={IconEnv}
          {...this.commonProps(this.keys.environmental_metrics, I18n.t('environmental_metrics.title'))}
        />
      );
    }

    if (hasPermission(permissionsList.reportBuilderView)) {
      items.push(
        <AsideItem
          id="report-builder"
          href={Routes.ReportBuilder.path}
          icon="pie-chart"
          {...this.commonProps(this.keys.report_builder, I18n.t('header.report_builder'))}
        />
      );
    }

    if (hasPermission(permissionsList.workerEngagementView)) {
      items.push(this.renderEngagementMenu());
    }

    if (hasPermission(permissionsList.assessmentsView)) {
      items.push(
        <AsideItem
          id="assessments"
          href={Routes.Assessments.path}
          svgImage={IconAssessment}
          {...this.commonProps(this.keys.assessments, I18n.t('header.assessments'))}
        />
      );
    }

    if (items.length === 0) {
      return null;
    }

    return (
      <AsideDropdownMenu
        {...this.commonProps(this.keys.my_program, I18n.t('header.my_program'))}
        opened={true}
        id="my-program">
        {items}
      </AsideDropdownMenu>
    );
  };

  renderSegmentationMenu = () => {
    const items = [];
    const listPermissions = [permissionsList.treatAsRba, permissionsList.treatAsRli];

    if (
      hasPermission(permissionsList.manageUploadedSites) ||
      (hasPermission(permissionsList.factoriesEditMode) && hasPermission(permissionsList.changeFactoryBaseData)) ||
      (hasPermission(permissionsList.assessmentsEditMode) && hasPermission(permissionsList.changeAssessmentsData))
    ) {
      items.push(
        <AsideItem
          id="segmentation-upload"
          svgImage={IconUpload}
          href={Routes.SuppliersUpload.path}
          {...this.commonProps(this.keys.suppliers_upload, getDistributorTranslation('suppliers.upload.page', 'title'))}
        />
      );
    }

    if (hasPermission(permissionsList.sitePerformanceView) && hasAnyPermission(listPermissions)) {
      items.push(
        <AsideItem
          id="segmentation-site-performance"
          svgImage={IconFactory}
          href={Routes.SuppliersRiskList.path}
          {...this.commonProps(this.keys.suppliers, getDistributorTranslation('suppliers.risk_list.page', 'title'))}
        />
      );
    }

    if (hasPermission(permissionsList.analyticsPageView) && hasAnyPermission(listPermissions)) {
      items.push(
        <AsideItem
          id="segmentation-analytics"
          href={Routes.Analytics.path}
          svgImage={IconAnalytics}
          {...this.commonProps(this.keys.analytics, I18n.t('header.analytics'))}
        />
      );
    }

    if (items.length === 0) {
      return null;
    }

    return (
      <AsideDropdownMenu
        {...this.commonProps(this.keys.segmentation, I18n.t('suppliers.segmentation.title'))}
        opened={true}>
        {items}
      </AsideDropdownMenu>
    );
  };

  getAdminMenuItems = () => {
    const items = [];

    hasPermission(permissionsList.portalsManage) &&
      items.push(
        <DropdownItem
          id="admin-portals"
          key="admin-portals-key"
          action={Routes.Portals.path}
          isInternalLink
          title={I18n.t('header.manage_portals')}
        />
      );

    hasPermission(permissionsList.staffManage) &&
      items.push(
        <DropdownItem
          id="admin-users"
          key="admin-users-key"
          action={Routes.UsersIndex.path}
          isInternalLink
          title={I18n.t('header.manage_users')}
        />
      );

    hasPermission(permissionsList.rolesManage) &&
      items.push(
        <DropdownItem
          id="admin-roles"
          key="admin-roles-key"
          action={Routes.UsersRoles.path}
          isInternalLink
          title={I18n.t('header.manage_roles')}
        />
      );

    hasPermission(permissionsList.groupsManage) &&
      items.push(
        <DropdownItem
          id="admin-groups"
          key="admin-groups-key"
          action={Routes.UsersGroups.path}
          isInternalLink
          title={I18n.t('header.manage_groups')}
        />
      );

    hasPermission(permissionsList.seeProgramSettings) &&
      (hasPermission(permissionsList.treatAsStaff) || isManager()) &&
      items.push(
        <DropdownItem
          id="admin-program-settings"
          key="admin-program-settings-key"
          action={Routes.ProgramSettings.path}
          isInternalLink
          title={I18n.t('header.program_settings')}
        />
      );

    hasAnyPermission([permissionsList.addNotifications, permissionsList.systemAdminAccess]) &&
      items.push(
        <DropdownItem
          id="admin-notification"
          key="admin-notification-key"
          action={Routes.SendNotification.path}
          isInternalLink
          title={I18n.t('header.add_notification')}
        />
      );

    // Staff with 'Manage Staff' - they can see staff page
    // Managers - they can see manage users page
    // You cannot have both
    if (!hasPermission(permissionsList.treatAsStaff) && hasPermission(permissionsList.accountUsersManage)) {
      items.push(
        <DropdownItem
          id="manage-users"
          key="manage-users-key"
          action={buildPath(Routes.AccountUsersView.path, { slug: this.props.account_slug })}
          isInternalLink
          title={I18n.t('header.manage_users')}
        />
      );
    }

    if (items.length === 0) {
      return null;
    }

    return items;
  };

  render = () => {
    const { AdminMenuRefOffsetX } = this.state;
    const { account, ghost } = this.props.user;
    const hasDashboardPermission = hasAnyPermission([permissionsList.seeNewDashboard, permissionsList.seeOldDashboard]);

    return (
      <PortalMenuProvider>
        <aside
          id="aside-navigation"
          className={`bg-navy-100 l-aside is-${this.props.isSidebarExpanded ? 'open' : 'closed'}`}>
          <nav role="navigation" className="l-nav">
            <AsideHeader
              portal={this.props.portal}
              callback={this.props.triggerSideBarExpansionCallback}
              isSidebarExpanded={this.props.isSidebarExpanded}
            />
            <div className="scrollable-nav">
              <div ref={this.NavMenuRef} className="l-nav__menu" onScroll={this.handleScroll}>
                <FlexColumn spacer="mt-6">
                  {ghost.is_ghost && this.props.isSidebarExpanded ? (
                    <span className="text-[13px] px-2 mb-2 text-mint-100">
                      {I18n.t('header.logged_in_as', { name: account.name })}
                    </span>
                  ) : null}
                </FlexColumn>
                {hasDashboardPermission && (
                  <AsideItem
                    id="dashboard"
                    href={Routes.Home.path}
                    svgImage={IconDashboard}
                    {...this.commonProps(this.keys.home, I18n.t('home.home'))}
                  />
                )}

                {this.renderRiskLandscapeMenu()}

                <WithPermission permission={permissionsList.suppliersView}>
                  {this.renderSegmentationMenu()}
                </WithPermission>

                {this.renderMyProgramMenu()}

                {this.getAdminMenuItems() && (
                  <AdminMenu
                    ref={this.AdminMenuRef}
                    fixedMenuXPosition={AdminMenuRefOffsetX}
                    asideItemProps={this.commonProps(this.keys.admin, I18n.t('admin'))}
                    menuItems={this.getAdminMenuItems()}
                    {...this.props}
                  />
                )}

                {this.renderActionMenu()}
              </div>
              <span className="version_name">{process.env.__APPVERSION__}</span>
            </div>
          </nav>
        </aside>
      </PortalMenuProvider>
    );
  };
}

Aside.propTypes = {
  notifications: PropTypes.array.isRequired,
  pathname: PropTypes.string.isRequired,
  triggerSideBarExpansionCallback: PropTypes.func.isRequired,
  unseen: PropTypes.number.isRequired,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    account: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    email: PropTypes.string.isRequired,
    ghost: PropTypes.shape({
      account_id: PropTypes.number,
      is_ghost: PropTypes.bool,
      user_id: PropTypes.number,
      user_username: PropTypes.string
    }),
    is_manager: PropTypes.bool,
    profile: PropTypes.shape({
      fullname: PropTypes.string,
      locale: PropTypes.string.isRequired.isRequired,
      nickname: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  account_slug: PropTypes.string,
  isSidebarExpanded: PropTypes.bool,
  portal: PropTypes.any,
  preview_ext: PropTypes.bool
};

Aside.defaultProps = {
  account_slug: null,
  isSidebarExpanded: true,
  portal: null,
  preview_ext: null
};
