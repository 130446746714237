import PropTypes from 'prop-types';
import React from 'react';

import AsideItem from 'src/components/layout/aside/AsideItem';
import IconAdmin from 'assets/images/sidebar/icon_admin.svg';
import PortalMenu from 'src/components/layout/aside/menus/PortalMenu';
import PortalMenuContext from 'src/components/layout/aside/menus/PortalMenu.context';

const AdminMenu = (props) => {
  const getAsideItem = (value) => {
    return (
      <AsideItem
        id="toolbar-user"
        svgImage={IconAdmin}
        aElemProps={{
          'data-toggle': 'dropdown',
          onClick: ({ nativeEvent }) => {
            nativeEvent.stopImmediatePropagation();
            value.handleOpenPortalMenu('admin-menu');
          }
        }}
        {...props.asideItemProps}
      />
    );
  };

  const getPortalMenu = () => {
    const { isSidebarExpanded } = props.asideItemProps;
    return (
      <PortalMenu>
        <ul
          ref={(nodeElem) => {
            if (!nodeElem) {
              return;
            }
            const elemOffset = props.fixedMenuXPosition;
            if (window.innerHeight > 820 && elemOffset < 798) {
              nodeElem.style.top = `${elemOffset}px`;
              nodeElem.style.bottom = 'auto';
            } else {
              nodeElem.style.bottom = '50px';
              nodeElem.style.top = 'auto';
            }
          }}
          className={`bg-navy-100 dropdown__menu dropdown_admin dropdown__portal ${
            isSidebarExpanded ? ' dropdown__portal--expanded' : ''
          }`}>
          {props.menuItems}
        </ul>
      </PortalMenu>
    );
  };

  return (
    <PortalMenuContext.Consumer>
      {(value) => (
        <li ref={props.innerRef} className="dropdown">
          {getAsideItem(value)}
          {value.activeSubmenu === 'admin-menu' && getPortalMenu()}
        </li>
      )}
    </PortalMenuContext.Consumer>
  );
};

AdminMenu.propTypes = {
  asideItemProps: PropTypes.object.isRequired,
  account_slug: PropTypes.string,
  fixedMenuXPosition: PropTypes.number,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })]),
  isSidebarExpanded: PropTypes.bool
};

AdminMenu.defaultProps = {
  account_slug: null,
  fixedMenuXPosition: 0,
  innerRef: undefined,
  isSidebarExpanded: false
};

export default React.forwardRef((props, ref) => <AdminMenu innerRef={ref} {...props} />);
