export const generateEnvTitle = () => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return ' - Dev';
    case 'staging':
      return ' - Staging';
    case 'test':
      return ' - Test';
    default:
      return '';
  }
};
