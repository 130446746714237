import { ActionMeta, OnChangeValue } from 'react-select';
import { EiqSelectOption, EiqSelectName, EiqSelect, EiqSelectProps } from 'eiq-storybook';
import React, { useCallback } from 'react';

import { customStartCase } from 'src/utils/filterUtils';
import FiltersUtils from 'src/classes/common/FiltersUtils';
import { GA_CATEGORY_FILTER } from 'src/classes/common/Constants';

interface EiqSelectWrapperProps extends EiqSelectProps {
  actionLabel?: string;
  gaCategory?: string;
  hasGaApi?: boolean;
}

type Option = { value?: string };

const EiqSelectWrapper: React.FC<EiqSelectWrapperProps> = ({
  actionLabel,
  gaCategory = GA_CATEGORY_FILTER,
  hasGaApi = true,
  onChange,
  placeholder,
  ...rest
}) => {
  const handleChange = useCallback(
    (
      name: EiqSelectName,
      selectedOption: OnChangeValue<EiqSelectOption, boolean>,
      actionMeta: ActionMeta<EiqSelectOption>
    ) => {
      if (hasGaApi && actionLabel) {
        let gaValue = '';

        if (typeof selectedOption === 'object' && !Array.isArray(selectedOption) && (selectedOption as Option)?.value) {
          gaValue = `${(selectedOption as Option).value}`;
        } else if (
          typeof actionMeta.option === 'object' &&
          !Array.isArray(actionMeta.option) &&
          (actionMeta.option as { value: string })?.value
        ) {
          gaValue = `${(actionMeta.option as { value: string })?.value}`;
        }

        if (gaValue) {
          FiltersUtils.googleAnalyticsEvents(gaCategory, actionLabel, gaValue);
        }
      }

      onChange(name, selectedOption, actionMeta);
    },
    [actionLabel, gaCategory, hasGaApi, onChange]
  );

  return <EiqSelect onChange={handleChange} placeholder={placeholder ? customStartCase(placeholder) : ''} {...rest} />;
};

export default EiqSelectWrapper;
