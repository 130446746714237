import { DATA_TYPE_DATE_RANGE, DATA_TYPE_HIERARCHY_PICKLIST, DATA_TYPE_PICKLIST } from 'src/classes/common/Constants';

export const FILTER_TYPES = {
  DATE_PICKER: 'datePicker',
  DATE_RANGE_PICKER: 'dateRangePicker',
  DROPDOWN: 'dropdown',
  HIERARCHY_DROPDOWN: 'hierarchyDropdown',
  MULTI_DROPDOWN: 'multiDropdown',
  RADIO_GROUP: 'radioGroup',
  SEARCH: 'search',
  SWITCHER: 'switcher'
};

export const ANALYTICS_FILTER = {
  countries: { name: 'countries', type: FILTER_TYPES.MULTI_DROPDOWN },
  dateRange: { name: 'dateRange', type: FILTER_TYPES.DATE_RANGE_PICKER },
  levels: { name: 'levels', type: FILTER_TYPES.MULTI_DROPDOWN },
  leverages: { name: 'leverages', type: FILTER_TYPES.MULTI_DROPDOWN },
  materiality: { name: 'materiality', type: FILTER_TYPES.MULTI_DROPDOWN },
  products: { name: 'products', type: FILTER_TYPES.HIERARCHY_DROPDOWN },
  provinces: { name: 'provinces', type: FILTER_TYPES.MULTI_DROPDOWN },
  sentinel_articles: { name: 'sentinel_articles', type: FILTER_TYPES.RADIO_GROUP },
  sources: { name: 'sources', type: FILTER_TYPES.MULTI_DROPDOWN },
  spends: { name: 'spends', type: FILTER_TYPES.MULTI_DROPDOWN },
  vendors: { name: 'vendors', type: FILTER_TYPES.MULTI_DROPDOWN }
};

export const GEOGRAPHY_RISK_LANDSCAPE_FILTER = {
  country: { name: 'country', type: FILTER_TYPES.DROPDOWN },
  province: { name: 'province', type: FILTER_TYPES.DROPDOWN },
  year: { name: 'year', type: FILTER_TYPES.DATE_PICKER }
};

export const GLOBAL_RISK_LANDSCAPE_FILTER = {
  countries: { name: 'countries', type: FILTER_TYPES.MULTI_DROPDOWN },
  distributorType: { name: 'distributorType', type: FILTER_TYPES.DROPDOWN },
  provinces: { name: 'provinces', type: FILTER_TYPES.MULTI_DROPDOWN },
  subCategories: { name: 'subCategories', type: FILTER_TYPES.DROPDOWN },
  view: { name: 'view', type: FILTER_TYPES.DROPDOWN },
  year: { name: 'year', type: FILTER_TYPES.DATE_PICKER }
};

export const SENTINEL_FILTER = {
  dateRange: { name: 'dateRange', type: FILTER_TYPES.DATE_RANGE_PICKER },
  incident_country: { name: 'incident_country', type: FILTER_TYPES.MULTI_DROPDOWN },
  issues: { name: 'issues', type: FILTER_TYPES.MULTI_DROPDOWN },
  my_program: { name: 'my_program', type: FILTER_TYPES.SWITCHER },
  products: { name: 'products', type: FILTER_TYPES.MULTI_DROPDOWN },
  risk: { name: 'risk', type: FILTER_TYPES.MULTI_DROPDOWN },
  search: { name: 'search', type: FILTER_TYPES.SEARCH },
  site_country: { name: 'site_country', type: FILTER_TYPES.MULTI_DROPDOWN },
  source: { name: 'source', type: FILTER_TYPES.MULTI_DROPDOWN },
  topics: { name: 'topics', type: FILTER_TYPES.MULTI_DROPDOWN }
};

export const SUPPLIERS_FILTER = {
  countries: { name: 'countries', type: FILTER_TYPES.MULTI_DROPDOWN },
  dateRange: { name: 'dateRange', type: FILTER_TYPES.DATE_RANGE_PICKER },
  levels: { name: 'levels', type: FILTER_TYPES.MULTI_DROPDOWN },
  leverages: { name: 'leverages', type: FILTER_TYPES.MULTI_DROPDOWN },
  materiality: { name: 'materiality', type: FILTER_TYPES.MULTI_DROPDOWN },
  name: { name: 'name', type: FILTER_TYPES.SEARCH },
  products: { name: 'products', type: FILTER_TYPES.HIERARCHY_DROPDOWN },
  provinces: { name: 'provinces', type: FILTER_TYPES.MULTI_DROPDOWN },
  sentinel_articles: { name: 'sentinel_articles', type: FILTER_TYPES.RADIO_GROUP },
  sources: { name: 'sources', type: FILTER_TYPES.MULTI_DROPDOWN },
  spends: { name: 'spends', type: FILTER_TYPES.MULTI_DROPDOWN },
  vendors: { name: 'vendors', type: FILTER_TYPES.MULTI_DROPDOWN }
};

type FiltersOptionsArray = { label: string; value: string; children?: unknown[] }[];
type FiltersOptionsObject = { [key: string]: FiltersOptionsArray };

export function getOptionsFromMultiLevel(options: FiltersOptionsArray) {
  const result = options.reduce((acc, { children, ...rest }) => {
    if (children?.length > 0) {
      return [...acc, rest, ...getOptionsFromMultiLevel(children as FiltersOptionsArray)];
    }

    return [...acc, rest];
  }, []);

  return result;
}

export function getOptionsAsObjectFromMultiLevel(options: FiltersOptionsArray) {
  const result = options.reduce((acc, { children, ...rest }) => {
    if (children?.length > 0) {
      return { ...acc, [rest.value]: rest, ...getOptionsAsObjectFromMultiLevel(children as FiltersOptionsArray) };
    }

    return { ...acc, [rest.value]: rest };
  }, {});

  return result;
}

export const customStartCase = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const getFilterFromUrl = ({
  customFilters = [],
  defaultFilterValues = {},
  filters,
  filtersOptions,
  filterTypes,
  ignoreFilters = [],
  supportedFilters = {}
}: {
  customFilters?: { name: string; options: FiltersOptionsArray }[];
  defaultFilterValues?: { [key: string]: { data_type: string } };
  filters: { [key: string]: unknown };
  filtersOptions: {
    [key: string]: FiltersOptionsArray | FiltersOptionsObject;
  };
  filterTypes: { [key: string]: { type: string } };
  ignoreFilters?: string[];
  supportedFilters?: { [key: string]: string };
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const newSearchParam = new URLSearchParams('');

  const hiddenFilters = {};
  for (const [key, value] of urlParams.entries()) {
    if (!(key in filters) && key in supportedFilters) {
      hiddenFilters[key] = value;
    }
  }

  const result = [...Object.entries(filters), ...Object.entries(hiddenFilters)].reduce((output, [key, value]) => {
    let filterType = filterTypes[key]?.type;

    if (!filterType && defaultFilterValues[key]?.data_type) {
      filterType = defaultFilterValues[key].data_type;
    } else if (!filterType && supportedFilters[key]) {
      filterType = supportedFilters[key];
    }

    const data = urlParams.get(key);
    if (filterType && data) {
      try {
        if (filterType === FILTER_TYPES.SEARCH) {
          output[key] = data;

          if (output[key]) {
            newSearchParam.set(key, output[key]);
          }
        } else if (filterType === FILTER_TYPES.DATE_PICKER) {
          output[key] = data;

          if (output[key]) {
            newSearchParam.set(key, output[key]);
          }
        } else if (filterType === FILTER_TYPES.DATE_RANGE_PICKER || filterType === 'DateRange') {
          const dateRange = JSON.parse(data);

          output[key] = {
            from: dateRange?.from,
            to: dateRange?.to
          };

          if (output[key].from && output[key].to) {
            newSearchParam.set(key, JSON.stringify(output[key]));
          }
        } else if (filterType === FILTER_TYPES.MULTI_DROPDOWN || filterType === 'Picklist') {
          const selectedOptions = JSON.parse(data);
          let availableOptions = filtersOptions[key];

          if (!Array.isArray(availableOptions)) {
            availableOptions = Object.values(availableOptions).reduce((acc, data: FiltersOptionsArray) => {
              return [...acc, ...data];
            }, []);
          }

          output[key] = availableOptions.filter(({ value }) => selectedOptions.includes(value));

          if (output[key].length > 0) {
            newSearchParam.set(key, JSON.stringify(output[key].map(({ value }) => value)));
          }
        } else if (filterType === FILTER_TYPES.DROPDOWN) {
          const availableOptions = filtersOptions[key] as FiltersOptionsArray;
          output[key] = availableOptions.find(({ value }) => String(value) === data) || null;

          if (output[key] && output[key].value) {
            newSearchParam.set(key, output[key].value);
          }
        } else if (filterType === FILTER_TYPES.HIERARCHY_DROPDOWN || filterType === DATA_TYPE_HIERARCHY_PICKLIST) {
          const selectedOptions = JSON.parse(data);
          const availableOptions = getOptionsFromMultiLevel(filtersOptions[key] as FiltersOptionsArray);

          output[key] = availableOptions.filter(({ value }) => selectedOptions.includes(value));

          if (output[key].length > 0) {
            newSearchParam.set(key, JSON.stringify(output[key].map(({ value }) => value)));
          }
        } else if (filterType === FILTER_TYPES.RADIO_GROUP) {
          const availableOptions = filtersOptions[key] as FiltersOptionsArray;
          output[key] = availableOptions.find(({ value }) => value === data)?.value || availableOptions[0].value;

          if (output[key]) {
            newSearchParam.set(key, output[key]);
          }
        } else if (filterType === FILTER_TYPES.SWITCHER) {
          output[key] = data === 'true';

          if (output[key]) {
            newSearchParam.set(key, output[key]);
          }
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      output[key] = value;

      if (filterType === FILTER_TYPES.SEARCH && output[key]) {
        newSearchParam.set(key, output[key]);
      } else if (filterType === FILTER_TYPES.DATE_PICKER && output[key]) {
        newSearchParam.set(key, output[key]);
      } else if (
        (filterType === FILTER_TYPES.DATE_RANGE_PICKER || filterType === DATA_TYPE_DATE_RANGE) &&
        output[key]?.from &&
        output[key]?.to
      ) {
        newSearchParam.set(key, JSON.stringify(output[key]));
      } else if (
        (filterType === FILTER_TYPES.MULTI_DROPDOWN ||
          filterType === DATA_TYPE_PICKLIST ||
          filterType === FILTER_TYPES.HIERARCHY_DROPDOWN ||
          filterType === DATA_TYPE_HIERARCHY_PICKLIST) &&
        output[key]?.length > 0 &&
        output[key][0]?.value
      ) {
        newSearchParam.set(key, JSON.stringify(output[key].map(({ value }) => value)));
      } else if (filterType === FILTER_TYPES.DROPDOWN && output[key] && output[key]?.value) {
        newSearchParam.set(key, output[key].value);
      } else if (filterType === FILTER_TYPES.RADIO_GROUP && output[key]) {
        newSearchParam.set(key, output[key]);
      } else if (filterType === FILTER_TYPES.SWITCHER && output[key] === true) {
        newSearchParam.set(key, output[key]);
      }
    }

    return output;
  }, {});

  customFilters.forEach(({ name: key, options }) => {
    const data = urlParams.get(key) || filters[key];
    const value = options.find(({ value }) => value === data);

    if (data && value && result[key]) {
      result[key] = value.value;
      newSearchParam.set(key, result[key]);
    }
  });

  ignoreFilters.forEach((key) => {
    const data = urlParams.get(key);
    if (data) {
      newSearchParam.set(key, data);
    }
  });

  window.history.replaceState(null, '', `?${newSearchParam.toString()}`);
  return result;
};
