import { lazy } from 'react';

import {
  AcceptInvitationRoute,
  AcceptPendingTermsRoute,
  AccountRoute,
  AccountUsersRoute,
  AccountsRoute,
  AnalyticsRoute,
  ArticlesRoute,
  AssessmentsRoute,
  CountryRiskRoute,
  DashboardEditRoute,
  DashboardRoute,
  EditPasswordRoute,
  EngagementRoute,
  EnvironmentalMetricsRoute,
  EsgRatingsRoute,
  EsgSiteRoute,
  FaqRoute,
  GroupEditRoute,
  HomeRoute,
  ManageGroupsRoute,
  NotificationsHistoryRoute,
  PortalEditRoute,
  PortalLoginRoute,
  PortalsRoute,
  PreviewLoginScreenRoute,
  ProductRiskProfileRoute,
  ProductRiskRoute,
  ProgramRoute,
  ProgramsRoute,
  ProgramSettingsRoute,
  ReportBuilderRoute,
  RiskLandscapeIndexRoute,
  SendNotificationRoute,
  SingleSignOnHandlerGetAuthRoute,
  SingleSignOnHandlerRoute,
  SupplierRoute,
  SuppliersRoute,
  SuppliersUploadRoute,
  TermsConditionsRoute,
  TrendReportsRoute,
  RiskManagementOverViewRoute,
  UserProfileEditRoute,
  UserProfileRoute,
  UsersEditRoleRoute,
  UsersNewRoleRoute,
  UsersRolesRoute,
  UsersRoute,
  VisualizationViewRoute
} from 'src/routes';
import { permissionsList } from 'src/components/utils/Auth';
import { Types } from 'src/components/utils/WithPermissions';

const AcceptPendingTermsScreen = lazy(() => import('src/components/guests/invitations/AcceptPendingTermsScreen'));
const AccountsIndex = lazy(() => import('src/components/accounts/index/AccountsIndex'));
const AccountUsersView = lazy(() => import('src/components/accounts/show/users/AccountUsersView'));
const AccountView = lazy(() => import('src/components/accounts/show/AccountView'));
const AnalyticsPageView = lazy(() => import('src/components/analytics/index/AnalyticsPageView'));
const ArticlesPageView = lazy(() => import('src/components/articles/ArticlesPageView'));
const AssessmentsView = lazy(() => import('src/components/assessments/index/AssessmentsView'));
const CountryRiskLandscapeView = lazy(() => import('src/components/risk-landscape/country/CountryRiskLandscapeView'));
const DashboardEditorViewContainer = lazy(() =>
  import('src/components/report-builder/index/dashboard-editor/DashboardEditorViewContainer')
);
const DashboardView = lazy(() => import('src/components/report-builder/index/DashboardView'));
const DashboardLibraryView = lazy(() => import('src/components/report-builder/index/DashboardLibraryView'));
const EditPasswordView = lazy(() => import('src/components/guests/passwords/EditPasswordView'));
const EngagementView = lazy(() => import('src/components/engagement/index/EngagementView'));
const EnvironmentalMetricsView = lazy(() => import('src/components/env-metrics/EnvironmentalMetricsView'));
const EsgRatingsView = lazy(() => import('src/components/esg-ratings/EsgRatingsView'));
const EsgSiteView = lazy(() => import('src/components/esg-ratings/EsgSiteView'));
const FaqView = lazy(() => import('src/components/faq/FaqView'));
const GroupsIndexView = lazy(() => import('src/components/groups/index/GroupsIndexView'));
const GroupView = lazy(() => import('src/components/groups/show/GroupView'));
const HomeView = lazy(() => import('src/components/home/HomeView'));
const JoinAccountView = lazy(() => import('src/components/guests/invitations/JoinAccountView'));
const NotificationHistoryView = lazy(() => import('src/components/notifications/index/NotificationHistoryView'));
const NotificationView = lazy(() => import('src/components/notifications/new/NotificationView'));
const LoginView = lazy(() => import('src/components/guests/login/LoginView'));
const OverviewRiskManagementView = lazy(() =>
  import('src/components/trend-reports-v3/overview/OverviewRiskManagementView')
);
const PortalIndexViewContainer = lazy(() => import('src/components/portals/index/PortalIndexViewContainer'));
const PortalViewContainer = lazy(() => import('src/components/portals/show/PortalViewContainer'));
const PreviewLoginScreenContainer = lazy(() => import('src/components/portals/PreviewLoginScreenContainer'));
const ProductRiskLandscapeListView = lazy(() =>
  import('src/components/risk-landscape/product/list/ProductRiskLandscapeListView')
);
const ProductRiskLandscapeSingleView = lazy(() =>
  import('src/components/risk-landscape/product/single/ProductRiskLandscapeSingleView')
);
const ProgramView = lazy(() => import('src/components/programs/show/ProgramView'));
const ProgramsView = lazy(() => import('src/components/accounts/show/programs/ProgramsView'));
const ProgramSettingsView = lazy(() => import('src/components/program-settings/ProgramSettingsView'));
const RiskLandscapeView = lazy(() => import('src/components/risk-landscape/index/RiskLandscapeView'));
const RoleEditorView = lazy(() => import('src/components/roles/show/RoleEditorView'));
const RolesListView = lazy(() => import('src/components/roles/index/RolesListView'));
const SingleSignOnHandlerGetAuthView = lazy(() => import('src/components/guests/login/SingleSignOnHandlerGetAuthView'));
const SingleSignOnHandlerView = lazy(() => import('src/components/guests/login/SingleSignOnHandlerView'));
const SuppliersRiskListView = lazy(() => import('src/components/suppliers/list/SuppliersRiskListView'));
const SuppliersUploadView = lazy(() => import('src/components/suppliers/upload/SuppliersUploadView'));
const SupplierView = lazy(() => import('src/components/suppliers/show/SupplierView'));
const TermsConditionsView = lazy(() => import('src/components/guests/TermsConditionsView'));
const TrendReportsView = lazy(() => import('src/components/trend-reports/TrendReportsView'));
const UserEditView = lazy(() => import('src/components/users/edit/UserEditView'));
const UsersIndexView = lazy(() => import('src/components/users/index/UsersIndexView'));
const UserView = lazy(() => import('src/components/users/view/UserView'));
const VisualizationView = lazy(() => import('src/components/programs/show/VisualizationView'));

/* PLEASE DON'T sort that object since it works this way: first route with matching path is going to be rendered */
export const Routes = {
  AcceptPendingTerms: {
    path: AcceptPendingTermsRoute,
    component: AcceptPendingTermsScreen
  },
  Accounts: {
    path: AccountsRoute,
    component: AccountsIndex,
    params: {
      exact: true
    },
    permissions: [permissionsList.accountsView]
  },
  AccountUsersView: {
    path: AccountUsersRoute,
    component: AccountUsersView,
    params: {},
    permissions: []
  },
  AccountView: {
    path: AccountRoute,
    component: AccountView,
    params: {
      exact: true
    }
  },
  Analytics: {
    path: AnalyticsRoute,
    component: AnalyticsPageView,
    params: {},
    permissions: [permissionsList.analyticsPageView]
  },
  Articles: {
    path: ArticlesRoute,
    component: ArticlesPageView,
    params: {},
    permissions: [permissionsList.articlesPageView]
  },
  Assessments: {
    path: AssessmentsRoute,
    component: AssessmentsView,
    params: {},
    permissions: [permissionsList.assessmentsView]
  },
  CountryRiskLandscape: {
    path: CountryRiskRoute,
    component: CountryRiskLandscapeView,
    params: {},
    permissions: [permissionsList.countryRiskLandscapeView]
  },
  DashboardEditor: {
    path: DashboardEditRoute,
    component: DashboardEditorViewContainer,
    params: {}
  },
  DashboardView: {
    path: DashboardRoute,
    component: DashboardView,
    params: {}
  },
  Engagement: {
    path: EngagementRoute,
    component: EngagementView,
    params: {},
    permissions: [permissionsList.workerEngagementView]
  },
  EnvironmentalMetrics: {
    path: EnvironmentalMetricsRoute,
    component: EnvironmentalMetricsView,
    params: {},
    permissions: [permissionsList.environmentalMetricsView]
  },
  EsgRating: {
    path: EsgRatingsRoute,
    component: EsgRatingsView,
    params: {
      exact: true
    },
    isWithoutAuthentication: true
  },
  EsgSite: {
    path: EsgSiteRoute,
    component: EsgSiteView,
    params: {},
    isWithoutAuthentication: true
  },
  Faq: {
    path: FaqRoute,
    component: FaqView,
    params: {}
  },
  GroupEdit: {
    path: GroupEditRoute,
    component: GroupView,
    params: {
      exact: true
    },
    permissions: [permissionsList.groupsManage]
  },
  Home: {
    path: HomeRoute,
    component: HomeView,
    params: {
      exact: true
    },
    permissions: [permissionsList.seeNewDashboard, permissionsList.seeOldDashboard],
    permissionsType: Types.Any
  },
  NotificationsHistory: {
    path: NotificationsHistoryRoute,
    component: NotificationHistoryView,
    params: {
      exact: true
    }
  },
  Portals: {
    path: PortalsRoute,
    component: PortalIndexViewContainer,
    params: {
      exact: true
    },
    permissions: [permissionsList.portalsManage]
  },
  PortalEdit: {
    path: PortalEditRoute,
    component: PortalViewContainer,
    params: {},
    permissions: [permissionsList.portalsManage]
  },
  PreviewLoginScreenRoute: {
    path: PreviewLoginScreenRoute,
    component: PreviewLoginScreenContainer,
    params: {},
    isFullScreen: true
  },
  ProductRiskLandscape: {
    path: ProductRiskRoute,
    component: ProductRiskLandscapeListView,
    params: {},
    permissions: [permissionsList.productRiskLandscapeView]
  },
  ProductRiskProfile: {
    path: ProductRiskProfileRoute,
    component: ProductRiskLandscapeSingleView,
    params: {},
    permissions: [permissionsList.productRiskLandscapeView]
  },
  Program: {
    path: ProgramRoute,
    component: ProgramView,
    params: {
      exact: true
    }
  },
  Programs: {
    path: ProgramsRoute,
    component: ProgramsView,
    params: {
      exact: true
    }
  },
  ProgramSettings: {
    path: ProgramSettingsRoute,
    component: ProgramSettingsView,
    params: {},
    permissions: [permissionsList.seeProgramSettings]
  },
  ReportBuilder: {
    path: ReportBuilderRoute,
    component: DashboardLibraryView,
    params: {}
  },
  RiskLandscape: {
    path: RiskLandscapeIndexRoute,
    component: RiskLandscapeView,
    params: {},
    permissions: [permissionsList.riskLandscapeView]
  },
  RiskManagementOverView: {
    path: RiskManagementOverViewRoute,
    component: OverviewRiskManagementView,
    params: {},
    permissions: [permissionsList.viewTrendReportsV3]
  },
  SendNotification: {
    path: SendNotificationRoute,
    component: NotificationView,
    params: {},
    permissions: [permissionsList.addNotifications]
  },
  SuppliersUpload: {
    path: SuppliersUploadRoute,
    component: SuppliersUploadView,
    params: {
      exact: true
    }
  },
  SupplierView: {
    path: SupplierRoute,
    component: SupplierView,
    params: {
      exact: true
    },
    permissions: [permissionsList.suppliersView]
  },
  SuppliersRiskList: {
    path: SuppliersRoute,
    component: SuppliersRiskListView,
    params: {
      exact: true
    },
    permissions: [permissionsList.suppliersView]
  },
  TermsConditions: {
    path: TermsConditionsRoute,
    component: TermsConditionsView,
    isWithoutAuthentication: true,
    params: {
      exact: true
    }
  },
  TrendReports: {
    path: TrendReportsRoute,
    component: TrendReportsView,
    params: {},
    permissions: [permissionsList.trendReportsView]
  },
  UserProfile: {
    path: UserProfileRoute,
    component: UserView,
    params: {
      exact: true
    }
  },
  UserProfileEdit: {
    path: UserProfileEditRoute,
    component: UserEditView,
    params: {}
  },
  UsersGroups: {
    path: ManageGroupsRoute,
    component: GroupsIndexView,
    params: {
      exact: true
    },
    permissions: [permissionsList.groupsManage]
  },
  UsersIndex: {
    path: UsersRoute,
    component: UsersIndexView,
    params: {
      exact: true
    },
    permissions: [permissionsList.accountUsersManage]
  },
  UsersNewRole: {
    path: UsersNewRoleRoute,
    component: RoleEditorView,
    params: {
      exact: true
    },
    permissions: [permissionsList.rolesManage]
  },
  UsersEditRole: {
    path: UsersEditRoleRoute,
    component: RoleEditorView,
    params: {
      exact: true
    },
    permissions: [permissionsList.rolesManage]
  },
  UsersRoles: {
    path: UsersRolesRoute,
    component: RolesListView,
    params: {
      exact: true
    },
    permissions: [permissionsList.rolesManage]
  },
  VisualizationView: {
    path: VisualizationViewRoute,
    component: VisualizationView,
    params: {}
  },
  EditPasswordPage: {
    path: EditPasswordRoute,
    component: EditPasswordView,
    isWithoutAuthentication: true,
    prohibitIfLogged: true
  },
  AcceptInvitationPage: {
    path: AcceptInvitationRoute,
    component: JoinAccountView,
    isWithoutAuthentication: true,
    prohibitIfLogged: true
  },
  PortalLoginPath: {
    path: PortalLoginRoute,
    component: LoginView,
    isWithoutAuthentication: true,
    params: {
      exact: true
    },
    prohibitIfLogged: true
  }
};

export const LoginPage = {
  path: HomeRoute,
  Component: LoginView,
  isWithoutAuthentication: true,
  params: {
    exact: true
  }
};

export const SingleSignOnPage = {
  path: SingleSignOnHandlerRoute,
  Component: SingleSignOnHandlerView,
  isWithoutAuthentication: true,
  params: {
    exact: true
  }
};

export const SingleSignOnGetAuthPage = {
  path: SingleSignOnHandlerGetAuthRoute,
  Component: SingleSignOnHandlerGetAuthView,
  params: {
    exact: true
  }
};
