import { matchPath } from 'react-router-dom';

import { Routes } from 'src/routesConfig';

/* paramsExcluded = string || array || undefined */
export const isCurrentPathWithoutParams = (currentPath, paramsExcluded) =>
  !!Object.values(Routes).filter((route) => {
    const isCurrentPath = !!matchPath(currentPath, {
      path: route.path,
      exact: true
    });

    if (!paramsExcluded) {
      return isCurrentPath;
    }

    let pathMeetsConditions;
    if (typeof paramsExcluded === 'string') {
      pathMeetsConditions = !route[paramsExcluded];
    } else {
      pathMeetsConditions = paramsExcluded.every((param) => !route[param]);
    }

    return isCurrentPath && pathMeetsConditions;
  }).length;
