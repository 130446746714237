import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { I18n } from 'src/utils/translations';

import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import FlexRow from 'src/components/common/layout/flex/FlexRow';

export default class InfoModalContent extends React.Component {
  render = () => {
    if (!this.props.notification) {
      return null;
    }

    return (
      <Fragment>
        <div dangerouslySetInnerHTML={{ __html: this.props.notification.html_message }} />
        {this.props.onClose && (
          <FlexRow align="ai-center" justify="jc-end">
            {this.props.onSubmit && (
              <EiqButtonWrapper
                className="close-icon"
                onClick={this.props.onSubmit}
                label={I18n.t('submit')}
                type="button"
                variant="btn--outline"
              />
            )}
            <EiqButtonWrapper
              aria-label="close modal"
              id="modal-close-btn"
              label={I18n.t('close')}
              onClick={this.props.onClose}
              size="btn--sm"
              type="button"
              variant="btn--outline"
              variantColor="extreme"
            />
          </FlexRow>
        )}
      </Fragment>
    );
  };
}

InfoModalContent.propTypes = {
  notification: PropTypes.object,
  onClose: PropTypes.func
};

InfoModalContent.defaultProps = {
  notification: null,
  onClose: null
};
