import { FETCH_SETTINGS, UPDATE_SUPPORTED_FILTERS } from 'src/redux/actionTypes';

const initialState = {
  data: {},
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  },
  supportedFilters: {}
};

export const filterSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS.INVOKE:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_SETTINGS.SUCCESS: {
      return {
        ...state,
        data: action.payload,
        fetchingState: {
          error: null,
          hasFetched: true,
          isLoading: false
        }
      };
    }
    case FETCH_SETTINGS.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    case UPDATE_SUPPORTED_FILTERS: {
      return {
        ...state,
        supportedFilters: {
          ...state.supportedFilters,
          ...action.payload
        }
      };
    }
    default:
      return state;
  }
};
