import cx from 'classnames';
import React, { forwardRef, HTMLAttributes, LegacyRef, ReactNode } from 'react';

interface FlexColumnProps extends HTMLAttributes<HTMLDivElement> {
  addClass?: string;
  align?: string;
  children?: ReactNode;
  justify?: string;
  spacer?: string;
  wrap?: string;
}

const FlexColumn = forwardRef(
  ({ align, justify, wrap, spacer, addClass, children, ...rest }: FlexColumnProps, ref: LegacyRef<HTMLDivElement>) => (
    <div className={cx('l-flex-column', align, justify, wrap, spacer, addClass)} ref={ref} {...rest}>
      {children}
    </div>
  )
);

export default FlexColumn;
