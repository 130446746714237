import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { I18n } from 'src/utils/translations';

import Spinner from 'src/components/common/loading/Spinner';

const LoadingContainer = ({ caption, className, children, loading, renderAboveSpinner, size }) => (
  <div className={loading ? `loading ${className}` : ''}>
    {renderAboveSpinner && renderAboveSpinner()}
    <Spinner loading={loading} caption={caption && I18n.t(caption)} size={size}>
      {children}
    </Spinner>
  </div>
);

LoadingContainer.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  renderAboveSpinner: PropTypes.func,
  size: PropTypes.number
};

LoadingContainer.defaultProps = {
  caption: 'loading_data',
  children: <Fragment />,
  className: '',
  loading: false,
  renderAboveSpinner: null,
  size: 50
};

export default LoadingContainer;
