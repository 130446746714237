import PropTypes from 'prop-types';
import React from 'react';

import FlexRow from 'src/components/common/layout/flex/FlexRow';

const StatusLabel = ({ label, value, id = '' }) => {
  return (
    <FlexRow align="ai-center" id={id}>
      <span className={`indicator bg-${value}`}></span>
      <span className="u-ml-5">{label}</span>
      {/* TODO: CP-2409 Confidence is hidden for now, because of issues with displaying it */}
      {/*{confidence && <div className="content-center white-space-nowrap">{confidence}</div>}*/}
    </FlexRow>
  );
};

StatusLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

StatusLabel.defaultProps = {
  label: '',
  value: 'inactive'
};

export default StatusLabel;
