import i18n from 'i18n-js';

import { store } from 'src/redux/store';

export const I18n = {
  ...i18n,
  t: (key, params = []) => {
    const { locale, translations } = store.getState();

    i18n.locale = locale;
    i18n.translations[locale] = translations.data;

    return i18n.t(key, params);
  }
};
