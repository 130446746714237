import { ScaleLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { getThemeColor } from 'src/utils/tailwindUtils';

const Spinner = ({ caption, children, className, color, height, loading, size }) => {
  return (
    <Fragment>
      {!loading ? (
        children
      ) : (
        <div aria-label="scale-loader" className={className}>
          <ScaleLoader color={color} height={height} loading={loading} size={size} />
          {caption && <p>{caption}</p>}
        </div>
      )}
    </Fragment>
  );
};

Spinner.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  className: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  loading: PropTypes.bool,
  size: PropTypes.number
};

Spinner.defaultProps = {
  caption: null,
  children: null,
  className: 'loading',
  color: getThemeColor('high'),
  height: 35,
  loading: true,
  size: 75
};

export default Spinner;
