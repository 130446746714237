export const colors = {
  aqua: {
    20: '#29FFFB33', // unused color
    50: '#29FFFB80',
    100: '#29FFFB'
  },
  coral: {
    20: '#FF5D2E33', // unused color
    50: '#FF5D2E80', // unused color
    70: '#FF5D2EB3', // unused color
    100: '#FF5D2E'
  },
  darkBlue: '#161938',
  darkMint: {
    20: '#088C8A33',
    50: '#088C8A80',
    70: '#088C8AB3', // unused color
    80: '#0CBF9E',
    100: '#088C8A'
  },
  darkPink: {
    25: '#EDBEE1', // unused color
    50: '#E295CD',
    75: '#D568B8',
    100: '#BF1F99'
  },
  gray: {
    25: '#f3f3f5',
    50: '#92949780',
    80: '#D3D3D3',
    100: '#929497',
    125: '#999999',
    150: '#6C6C6C'
  },
  lightGray: '#EDEDED',
  lemon: {
    20: '#FFE90033', // unused color
    50: '#FFE90080', // unused color
    100: '#FFE900',
    150: '#CCCC00',
    200: '#999900' // unused color
  },
  midGray: '#CCCCCC',
  mint: {
    20: '#0FF2B233',
    50: '#0FF2B27F',
    100: '#0FF2B2'
  },
  navy: {
    20: '#0F123233', // unused color
    50: '#0F123280', // unused color
    70: '#0F1232B3', // unused color
    100: '#0F1232'
  },
  plum: {
    20: '#BF1F9933', // unused color
    50: '#BF1F9980', // unused color
    70: '#BF1F99B3', // unused color
    100: '#BF1F99' // unused color
  },
  purple: {
    100: '#6A0DAD'
  },
  sea: {
    20: '#0087FF33', // unused color
    50: '#0087FF80', // unused color
    55: '#93C3FF',
    70: '#0087FFB3', // unused color
    75: '#64ABFF',
    100: '#0087FF'
  },
  tomato: {
    10: '#FFB19B', // unused color
    25: '#FF8F71',
    50: '#FF9D5F',
    100: '#E32332',
    125: '#A94442'
  },
  waterBlue: {
    50: '#29A9E0',
    75: '#289DC6',
    100: '#004992'
  },
  // Risk Colors
  extreme: '#E32332',
  high: '#FF5d2E',
  low: '#0FF2B2',
  medium: '#FFE900',
  no_data: 'rgba(146, 148, 151, 0.7)',
  other: 'rgba(146, 148, 151, 0.7)',
  'no-data': 'rgba(146, 148, 151, 0.7)',
  // Input Color
  inputColor: '#088C8A'
};
