import React, { useCallback, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { useLocation } from 'react-router-dom';

import { AssessmentsRoute, HomeRoute } from 'src/routes';
import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import { hasAnyPermission, hasPermission, permissionsList } from 'src/components/utils/Auth';
import history from 'src/history';
import { I18n } from 'src/utils/translations';
import { setError } from 'src/redux/actions/error';
import { signOut } from 'src/utils/JWTUtils';
import svg404 from 'assets/images/error-404.svg';

export const NotFound = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const user = useSelector((state: RootStateOrAny) => state.currentUser);

  const [hasDashboardPermission, setHasDashboardPermission] = useState(() =>
    hasAnyPermission([permissionsList.seeNewDashboard, permissionsList.seeOldDashboard])
  );

  useEffect(() => {
    // Note: Below condition is needed to check the permission again, whenever the user switches the portal
    if (user?.data?.portal?.id) {
      setHasDashboardPermission(hasAnyPermission([permissionsList.seeNewDashboard, permissionsList.seeOldDashboard]));
    }
  }, [user?.data?.portal?.id]);

  useEffect(() => {
    if (location.pathname === HomeRoute && !hasDashboardPermission && hasPermission(permissionsList.assessmentsView)) {
      history.replace(AssessmentsRoute);
    }
  }, [hasDashboardPermission, location]);

  useEffect(() => {
    if (!hasDashboardPermission && !hasPermission(permissionsList.assessmentsView)) {
      Sentry.captureException(new Error(`User does not have permission to view the dashboard`));
    }
  }, [hasDashboardPermission]);

  const goToDashboard = useCallback(() => {
    history.push(HomeRoute);
    dispatch(setError({}));
  }, [dispatch]);

  const goBack = useCallback(() => {
    history.goBack();
    dispatch(setError({}));
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    signOut();
  }, []);

  return (
    <section className="l-container l-flex-centered">
      <img className="mb-15 max-w-full h-auto" src={svg404} alt="404: Page Not Found" width="50%" />
      <div className="text-center mb-7.5">
        <h1 className="mb-3.8">{I18n.t('not_found.title')}</h1>
        <h3 className="mb-15">{I18n.t('not_found.description')}</h3>
        <div className="l-flex-column-center">
          <EiqButtonWrapper
            aria-label="return dashboard"
            className="mb-8"
            id="return-dashboard"
            label={I18n.t('not_found.return_dashboard.label')}
            onClick={goToDashboard}
            title={I18n.t('not_found.return_dashboard.title')}
            type="button"
          />
          <EiqButtonWrapper
            aria-label="previous page"
            className="mb-8"
            id="previous-page"
            label={I18n.t('not_found.previous_page')}
            onClick={goBack}
            title={I18n.t('not_found.previous_page')}
            type="button"
            variant="btn--outline-link"
          />
          <EiqButtonWrapper
            aria-label="logout"
            id="logout"
            label={I18n.t('header.log_out')}
            onClick={handleLogout}
            type="button"
            variant="btn--outline-link"
          />
        </div>
      </div>
    </section>
  );
};
