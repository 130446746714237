import PropTypes from 'prop-types';
import React from 'react';

import { allowedValue, defaultValue, generateSelectOptions } from 'src/classes/util/RbaViewUtil';
import { hasAllPermissions, permissionsList } from 'src/components/utils/Auth';
import { I18n } from 'src/utils/translations';

import EiqSelectWrapper from 'src/components/common/EiqSelectWrapper/EiqSelectWrapper';
import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';

export class RbaViewStorageProvider {
  key = 'selectedRbaView';
  possibleOptions = generateSelectOptions(true);

  get = () => SessionStorageProvider.get(this.key);
  set = (val) => SessionStorageProvider.set(this.key, val && val.toString());
}

export default class RbaViewSwitcher extends React.Component {
  storageProvider = new RbaViewStorageProvider();

  constructor(props) {
    super(props);

    // Label and value pairs are only used in this component.
    // Outside, enum-like mechanism is used, example: RBA_VIEW_OPTIONS.RBA
    this.options = generateSelectOptions(this.props.includeRmi, this.props.includeGscdd);
  }

  componentDidMount = () => {
    if (!this.props.initialRbaView) {
      this.broadcastRbaView(defaultValue(this.options, this.storageProvider));
    }
  };

  broadcastRbaView = (value) => {
    const { isStoreFilterInUrl } = this.props;

    this.storageProvider.set(value);

    if (isStoreFilterInUrl) {
      const {
        location: { search },
        history
      } = window;
      const searchParam = new URLSearchParams(search);

      searchParam.set('rbaView', value);
      history.replaceState(null, '', `?${searchParam.toString()}`);
    }

    this.props.callback && this.props.callback(value);
  };

  getInitialValue = () => {
    const initialValue = this.props.initialRbaView;
    return this.options.find((i) => i.value === initialValue);
  };

  render = () => {
    if (hasAllPermissions([permissionsList.rbaSwitcher, permissionsList.treatAsRba])) {
      return (
        <div>
          <EiqSelectWrapper
            actionLabel={`${I18n.t('suppliers.view_switcher.title')}`}
            hasChips={this.props.hasChips}
            isClearable={false}
            name="view_switcher"
            onChange={(_name, value) => this.broadcastRbaView(allowedValue(this.options, value.value))}
            options={this.options}
            placeholder={I18n.t('suppliers.view_switcher.title')}
            value={this.getInitialValue() || defaultValue(this.options, this.storageProvider)}
          />
        </div>
      );
    }

    return null;
  };
}

RbaViewSwitcher.propTypes = {
  callback: PropTypes.func,
  hasChips: PropTypes.bool,
  includeGscdd: PropTypes.bool,
  includeRmi: PropTypes.bool,
  initialRbaView: PropTypes.string,
  isStoreFilterInUrl: PropTypes.bool
};

RbaViewSwitcher.defaultProps = {
  callback: null,
  hasChips: false,
  includeGscdd: false,
  includeRmi: false,
  initialRbaView: '',
  isStoreFilterInUrl: false
};
