import { ButtonProps } from 'src/components/common/forms/Button/SaveButton';
import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import { I18n } from 'src/utils/translations';

const CancelButton = ({ className = '', disabled = false, label = I18n.t('cancel'), onClick }: ButtonProps) => (
  <EiqButtonWrapper
    aria-label={label}
    className={`hover:text-white ${className}`}
    disabled={disabled}
    label={label}
    onClick={onClick}
    type="button"
    variant="btn--outline"
    variantColor="extreme"
  />
);

export default CancelButton;
