import PropTypes from 'prop-types';
import React from 'react';

import LoadingContainer from 'src/components/common/loading/LoadingContainer';

const WidgetLayout = ({
  loading,
  loadingClass,
  renderHeader,
  renderBody,
  containerClassName,
  widgetClassName,
  headerClassName,
  bodyClassName
}) => (
  <div className={containerClassName}>
    <LoadingContainer loading={loading} className={loadingClass}>
      <div className={`l-widget ${widgetClassName}`}>
        {renderHeader && <div className={`l-widget__header ${headerClassName}`}>{renderHeader()}</div>}
        <div className={`l-widget__body ${bodyClassName}`}>{renderBody()}</div>
      </div>
    </LoadingContainer>
  </div>
);

WidgetLayout.propTypes = {
  renderBody: PropTypes.func.isRequired,
  bodyClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  loading: PropTypes.bool,
  loadingClass: PropTypes.string,
  renderHeader: PropTypes.func,
  widgetClassName: PropTypes.string
};

WidgetLayout.defaultProps = {
  bodyClassName: '',
  containerClassName: '',
  headerClassName: '',
  loading: false,
  loadingClass: '',
  renderHeader: null,
  widgetClassName: ''
};

export default WidgetLayout;
