import PropTypes from 'prop-types';
import React from 'react';

import { hasAllPermissions, hasAnyPermission } from 'src/hooksUtils';
import { NotFound } from 'src/components/errors/NotFound';
import { permissionsList } from 'src/components/utils/Auth';

export const Types = {
  All: 'ALL',
  Any: 'ANY'
};

const checkPermissions = (type, permissions) => {
  if (type === Types.All) {
    return hasAllPermissions(permissions);
  }

  return hasAnyPermission(permissions);
};

export const WithPermissions = ({ children, requiredPermissions, type }) => {
  const hasPermissions = checkPermissions(type, requiredPermissions);
  // TODO - since it's all a frontend app and we expose routes in the source rendering NotFound instead 403 gives no security, consider rendering 403
  return hasPermissions ? children : <NotFound />;
};

WithPermissions.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.oneOf(Object.values(permissionsList))).isRequired,
  children: PropTypes.node,
  type: PropTypes.oneOf(Object.values(Types))
};

WithPermissions.defaultProps = {
  children: null,
  type: Types.All
};
