import axios from 'axios';
import { buildPathForApi } from 'src/utils/buildPath';
import { PortalsDataURL } from 'src/apiUrls';
import { FETCH_PORTALS } from 'src/redux/actionTypes';

export const fetchPortals = (requestedPortal) => (dispatch) => {
  dispatch({ type: FETCH_PORTALS.INVOKE });

  return axios
    .get(buildPathForApi(PortalsDataURL, { requestedPortal }))
    .then((response) =>
      dispatch({
        type: FETCH_PORTALS.SUCCESS,
        payload: response.data
      })
    )
    .catch((error) =>
      dispatch({
        type: FETCH_PORTALS.ERROR,
        error: error.response || 'error'
      })
    );
};
