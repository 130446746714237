import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import { routerMiddleware } from 'connected-react-router';
import promise from 'redux-promise-middleware';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import { appReducer } from 'src/redux/reducers/rootReducer';
import history from 'src/history';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['translations']
};

const persistedReducer = persistReducer(persistConfig, appReducer(history));

const isDev = process.env.NODE_ENV === 'development';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [routerMiddleware(history), thunkMiddleware, promise];

export const configureStore = (preloadedState) =>
  createStore(
    persistedReducer,
    preloadedState,
    isDev ? composeEnhancers(applyMiddleware(...middlewares)) : applyMiddleware(...middlewares)
  );

const initialState = {};

export const store = configureStore(initialState);
export const persistor = persistStore(store);
