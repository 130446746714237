import _ from 'lodash';

import { FETCH_CURRENT_USER, SET_LOCALE } from 'src/redux/actionTypes';
import { LOCALES } from 'src/utils/locales';

const availableLocales = Object.keys(LOCALES);
const defaultLocale = 'en';

let languagesMap = {
  cn: ['zh', 'zh-HK', 'zh-CN', 'zh-TW'],
  en: ['en', 'en-US', 'en-AU', 'en-CA', 'en-IN', 'en-NZ', 'en-ZA', 'en-GB']
};

let browserLang = navigator.language || '';
let initialLocale = defaultLocale;
_.forEach(languagesMap, (langCodes, key) => {
  let browserLangInMap = _.find(langCodes, (langCode) => langCode.substring(0, browserLang.length) === browserLang);
  let inAvailableLangs = _.find(availableLocales, (availableLocale) => availableLocale === key);

  if (browserLangInMap && inAvailableLangs) {
    initialLocale = key;
  }
});

const initialState = initialLocale;

export const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCALE:
      return action.payload;
    case FETCH_CURRENT_USER.SUCCESS:
      // for safety reason no to throw error when request with outdated JWT gets empty user
      // (the JWT itself is handled in AxiosConfig with 401 handling)
      return _.get(action, 'payload.profile.locale', state);
    default:
      return state;
  }
};
