import _ from 'lodash';
import { EiqButton, EiqButtonProps } from 'eiq-storybook';
import React, { useCallback } from 'react';

import FiltersUtils from 'src/classes/common/FiltersUtils';
import { GA_CATEGORY_FILTER } from 'src/classes/common/Constants';

interface EiqButtonWrapperProps extends EiqButtonProps {
  actionLabel?: string;
  gaCategory?: string;
  gaValue?: string;
}

const EiqButtonWrapper: React.FC<EiqButtonWrapperProps> = ({
  actionLabel,
  gaCategory = GA_CATEGORY_FILTER,
  gaValue,
  onClick,
  ...rest
}) => {
  const handleClick = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!_.isEmpty(actionLabel) && !_.isEmpty(gaValue)) {
        FiltersUtils.googleAnalyticsEvents(gaCategory, actionLabel, `${gaValue}`);
      }
      if (onClick) {
        await onClick(event);
      }
    },
    [actionLabel, gaCategory, gaValue, onClick]
  );

  return <EiqButton onClick={handleClick} {...rest} />;
};

export default EiqButtonWrapper;
