import { pick } from 'lodash';

import {
  ACCOUNT_ASSESSMENTS_TEMPLATE,
  ARTICLES_VIEW,
  ASSESSMENTS_TEMPLATE,
  AUDIT_COUNTS_COLUMNS,
  AUDIT_METRICS_COLUMNS,
  JWT,
  PREDEFINE_COLOR,
  PRODUCT_RISK_LANDSCAPE_MAP_VISIBILITY,
  RECENT_EIQ_SEARCHES,
  RECENT_RBA_SEARCHES,
  RECENT_SENTINEL_SEARCH,
  SAQ_TEMPLATE,
  SHOW_MAP_LEGEND,
  SITE_PERFORMANCE_COLUMNS,
  SITE_VENDORS_COLUMN,
  SOURCING_REGION_TEMPLATE,
  USER_DESTINATIONS,
  WORKER_DEMOGRAPHICS_COLUMNS_SETTINGS
} from 'src/classes/common/Constants';
import { AuthorizationActions } from 'src/classes/util/RequestUtil';
import { eraseCurrentUser, setLogged } from 'src/redux/actions/currentUser';
import { fetchRootRoute, HomeRoute } from 'src/routes';
import history from 'src/history';
import { store } from 'src/redux/store';

export const currentJWT = () => localStorage.getItem(JWT);

export const signIn = (portal, params, redirectPath = fetchRootRoute()) => {
  return AuthorizationActions.signIn(portal, pick(params, ['user.email', 'user.password'])).then((response) => {
    history.push(redirectPath);
    if (response.data.tac_accepted_at) {
      setJWT(response.headers.authorization);
      setUserDestinations();
    }
    return {
      jwt: response.headers.authorization,
      checkTAC: response.data.tac_accepted_at == null
    };
  });
};

export const signOut = (redirect = true, refresh = false) => {
  return AuthorizationActions.signOut().then(() => {
    removeJWT();

    if (redirect) {
      history.push(`/${store.getState().portals.data.current.slug}`);
    }

    if (refresh) {
      history.go(0);
    }
  });
};

export const singleSignOnAuth = (authCode, redirectPath) => {
  return AuthorizationActions.oAuth2Authorization(authCode).then((response) => {
    setJWT(`Bearer ${response.data.access_token}`);
    window.location.replace(redirectPath);
  });
};

export const revokeToken = () => {
  removeJWT();
  const portalSlug = store.getState().portals.data.current.slug;
  history.push(portalSlug ? `/${portalSlug}` : HomeRoute);
};

export const setUserDestinations = () => {
  return AuthorizationActions.oAuth2AuthorizationCheckDestinations({ throwError: true }).then((response) => {
    if (response?.data?.services) {
      localStorage.setItem(USER_DESTINATIONS, JSON.stringify(response.data.services));
    } else {
      localStorage.setItem(USER_DESTINATIONS, JSON.stringify([]));
    }
  });
};

export const setJWT = (token) => {
  localStorage.setItem(JWT, token);
  store.dispatch(setLogged(true));
};

const removeJWT = () => {
  const localStorageItems = [
    ACCOUNT_ASSESSMENTS_TEMPLATE,
    ARTICLES_VIEW,
    ASSESSMENTS_TEMPLATE,
    AUDIT_COUNTS_COLUMNS,
    AUDIT_METRICS_COLUMNS,
    JWT,
    PREDEFINE_COLOR,
    PRODUCT_RISK_LANDSCAPE_MAP_VISIBILITY,
    RECENT_EIQ_SEARCHES,
    RECENT_RBA_SEARCHES,
    RECENT_SENTINEL_SEARCH,
    SAQ_TEMPLATE,
    SHOW_MAP_LEGEND,
    SITE_PERFORMANCE_COLUMNS,
    SITE_VENDORS_COLUMN,
    SOURCING_REGION_TEMPLATE,
    USER_DESTINATIONS,
    WORKER_DEMOGRAPHICS_COLUMNS_SETTINGS
  ];

  localStorageItems.forEach((item) => localStorage.removeItem(item));
  store.dispatch(setLogged(false));
  store.dispatch(eraseCurrentUser());
};
