import { NavLink } from 'react-router-dom';
import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { ActionCableUtils } from 'src/classes/util/ActionCableUtils';
import {
  findImportantNotification,
  notificationPortalInfo,
  onNotificationClick,
  renderNotificationMessage
} from 'src/classes/util/NotificationUtil';
import { I18n } from 'src/utils/translations';
import { NotificationActions } from 'src/classes/util/RequestUtil';
import { NotificationsHistoryRoute } from 'src/routes';

import InfoModal from 'src/components/common/InfoModal';

class NotificationsMenu extends React.Component {
  state = {
    notification: null,
    notifications: this.props.notifications,
    unseen: this.props.unseen
  };

  componentDidMount = () => {
    const notification = findImportantNotification(this.props.notifications);
    this.setState({ notification });
  };

  onCloseModal = () => {
    this.setState({ notification: null });
  };

  onReceived = (message) => {
    if (message.action === 'created') {
      this.receivedNew(message.notification);
    } else if (message.action === 'seen') {
      this.markedSeen(message.notification);
    } else if (message.action === 'all_seen') {
      this.allMarkedSeen();
    }
  };

  receivedNew = (notification) => {
    let unseen = this.state.unseen + 1;
    let notifications = this.state.notifications.slice();
    if (notifications.length >= 10) {
      notifications.splice(-1, 1);
    }
    notifications.unshift(notification);

    let modalNotification = notification.important ? notification : null;
    this.setState({ notifications: notifications, unseen: unseen, notification: modalNotification });
    Alert.success(I18n.t('notification.new'));
  };

  markedSeen = (notification) => {
    let unseen = this.state.unseen > 0 ? this.state.unseen - 1 : 0;
    let notificationIdx = this.state.notifications.findIndex((n) => n.id === notification.id);
    let notifications = [
      ...this.state.notifications.slice(0, notificationIdx),
      notification,
      ...this.state.notifications.slice(notificationIdx + 1)
    ];
    this.setState({ notifications, unseen });
  };

  allMarkedSeen = () => {
    let notifications = this.state.notifications.slice().map((notification) => {
      notification.seen = true;
      return notification;
    });
    this.setState({ notifications, unseen: 0 });
  };

  onMarkAllClick = () => {
    if (this.state.unseen > 0) {
      NotificationActions.markAllSeen();
    }
  };

  onModalNotification = (notification) => {
    this.setState({ notification });
  };

  getPortalMenu = () => {
    return (
      <ul className="dropdown dropdown__menu dropdown__menu--notification dropdown_blue bg-white text-darkBlue border border-midGray">
        <li>
          <h3 className="text-darkBlue">{I18n.t('notification.you_have_new', { count: this.state.unseen })}</h3>
          <span
            role="button"
            className={this.state.unseen === 0 ? '!cursor-not-allowed' : ''}
            aria-label="mark all as read"
            onClick={this.onMarkAllClick}>
            {I18n.t('notification.mark_all')}
          </span>
        </li>
        {this.state.notifications.map((notification) => (
          <li
            className={`dropdown__item border border-b-midGray ${notification.seen ? 'seen' : 'font-bold unseen'}`}
            key={notification.id}
            onClick={(e) => onNotificationClick(notification, e, this.props.user, this.onModalNotification)}>
            {notificationPortalInfo(notification, this.props.user)}
            <span dangerouslySetInnerHTML={{ __html: renderNotificationMessage(notification.message) }} />
          </li>
        ))}
        <li>
          <NavLink to={NotificationsHistoryRoute}>{I18n.t('notification.view')}</NavLink>
        </li>
      </ul>
    );
  };

  render = () => {
    return (
      <Fragment>
        {ActionCableUtils.subscribeNotificationsChannel(this.onReceived)}
        {this.getPortalMenu()}
        <InfoModal notification={this.state.notification} callback={this.onCloseModal} />
      </Fragment>
    );
  };
}

NotificationsMenu.propTypes = {
  notifications: PropTypes.array.isRequired,
  unseen: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};

export default NotificationsMenu;
