import PropTypes from 'prop-types';
import React from 'react';

import { I18n } from 'src/utils/translations';

import ContactUsForm from 'src/components/contact-us/ContactUsForm';
import Modal from 'src/components/modal/Modal';

const ContactUsModal = ({ isOpen, toggleVisibility, user }) => (
  <Modal isOpen={isOpen} onRequestClose={toggleVisibility} title={I18n.t('contact.contact_us')}>
    <ContactUsForm onSubmitSuccess={toggleVisibility} onSubmitFail={toggleVisibility} user={user} />
  </Modal>
);

ContactUsModal.propTypes = {
  toggleVisibility: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string
  })
};

ContactUsModal.defaultProps = {
  isOpen: false,
  user: {}
};

export default ContactUsModal;
