import React, { Fragment } from 'react';
import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import FlexRow from 'src/components/common/layout/flex/FlexRow';
import { I18n } from 'src/utils/translations';
import IconInformation from 'assets/images/icons/icon-info.svg';
import WidgetLayout from 'src/components/common/layout/WidgetLayout';

interface Item {
  id: string;
  html_message: string;
  message: {
    headline: string;
  };
}

interface NotificationModalProps {
  containerClassName?: string;
  items: Item[];
  onClose: () => void;
  onSubmit?: () => void;
  title: string;
}

const NotificationModal = ({
  containerClassName = '',
  items,
  onClose,
  onSubmit = null,
  title
}: NotificationModalProps) => {
  const renderBody = () => {
    return items.map((item) => {
      return (
        <Fragment key={item.id}>
          <p className="u-pt-15">{item.message.headline}</p>
          <FlexRow>
            <p
              className="description"
              dangerouslySetInnerHTML={{
                __html: item.html_message
              }}
            />
          </FlexRow>
        </Fragment>
      );
    });
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <FlexRow align="ai-center" addClass="white-svg-icon">
          <img alt="information" src={IconInformation} width={30} />
          <p className="u-m-0 u-pl-5">{title}</p>
        </FlexRow>
        <EiqButtonWrapper
          aria-label="delete icon"
          className="close-icon u-pr-5"
          onClick={onClose}
          size="btn--sm"
          type="button"
          variant="btn--close"
          variantColor="transparent"
        />
      </Fragment>
    );
  };

  return (
    items.length > 0 && (
      <Fragment>
        <WidgetLayout
          widgetClassName="notify-widget !bg-navy-100 !pb-5"
          containerClassName={containerClassName}
          bodyClassName="u-pl-35"
          headerClassName="l-flex-row jc-between ai-center"
          renderHeader={renderHeader}
          renderBody={renderBody}
        />
        {onSubmit && (
          <EiqButtonWrapper
            className="close-icon u-pr-5"
            onClick={onSubmit}
            label={I18n.t('submit')}
            type="button"
            variant="btn--outline-link"
          />
        )}
      </Fragment>
    )
  );
};

export default NotificationModal;
