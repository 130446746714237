import { CLEAR_ROLE, FETCH_ROLE, UPDATE_ROLE } from 'src/redux/actionTypes';

const initialState = {
  data: null,
  fetchingState: {
    error: null,
    hasFetched: false,
    isLoading: false
  }
};

export const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ROLE.SUCCESS:
      return initialState;
    case CLEAR_ROLE.ERROR:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          error: action.error
        }
      };
    case FETCH_ROLE.INVOKE:
      return {
        ...initialState,
        fetchingState: {
          ...state.fetchingState,
          isLoading: true
        }
      };
    case FETCH_ROLE.SUCCESS:
      return {
        ...state,
        data: action.payload,
        fetchingState: {
          error: null,
          hasFetched: true,
          isLoading: false
        }
      };
    case FETCH_ROLE.ERROR:
      return {
        ...state,
        fetchingState: {
          error: action.error,
          hasFetched: false,
          isLoading: false
        }
      };
    case UPDATE_ROLE.SUCCESS:
      return {
        ...state,
        data: action.payload
      };
    case UPDATE_ROLE.ERROR:
      return {
        ...state,
        fetchingState: {
          ...state.fetchingState,
          error: action.error
        }
      };
    default:
      return state;
  }
};
