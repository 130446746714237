import { ActionCableProvider } from 'react-actioncable-provider';
import React, { Fragment } from 'react';

import { currentJWT } from 'src/utils/JWTUtils';

export const ActionCableWrapper = ({ children, isSignedIn }) =>
  isSignedIn ? (
    <ActionCableProvider
      url={`${process.env.BACKEND_URL}:${process.env.BACKEND_PORT}/cable?HTTP_AUTHORIZATION=${currentJWT()}`}>
      {children}
    </ActionCableProvider>
  ) : (
    <Fragment>{children}</Fragment>
  );
