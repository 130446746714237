import { NavLink } from 'react-router-dom';
import { useSelector, RootStateOrAny } from 'react-redux';

import cx from 'classnames';
import React, { useEffect, useState } from 'react';

import { NotificationCircle } from 'src/components/layout/top-bar/NotificationCircle';
import { Routes } from 'src/routesConfig';
import { USER_DESTINATIONS } from 'src/classes/common/Constants';

import AssessLogo from 'assets/images/icons/top-bar/LRQA_EiQ_Assess.svg';
import IconAccounts from 'assets/images/sidebar/icon_accounts.svg';
import IconFaq from 'assets/images/icons/top-bar/icon-faq.svg';
import IconNotification from 'assets/images/icons/top-bar/icon-notification.svg';
import LearnLogo from 'assets/images/icons/top-bar/LRQA_EiQ_Learn.svg';
import NotificationsMenu from 'src/components/layout/top-bar/NotificationMenu';
import UserMenu from 'src/components/layout/top-bar/UserMenu';

const ssoHost = process.env.SSO_HOST;
const eiqAssessHost = process.env.EIQ_ASSESS_HOST;
const eiqLearnHost = process.env.EIQ_LEARN_HOST;

export const TopBarSection = (): JSX.Element => {
  const [notificationMenuIsOpen, setNotificationMenuIsOpen] = useState(false);
  const [userMenuIsOpen, setUserMenuIsOpen] = useState(false);

  const { currentUser, navbar } = useSelector((state: RootStateOrAny) => state);

  const { notifications, unseen } = navbar;

  const getDestinations = () => {
    let savedDestinations;
    if (localStorage.getItem(USER_DESTINATIONS) !== 'undefined') {
      savedDestinations = JSON.parse(localStorage.getItem(USER_DESTINATIONS));
    } else {
      savedDestinations = [];
    }
    return Array.isArray(savedDestinations) && savedDestinations.length > 0 ? savedDestinations : [];
  };

  const canSeeAssessLink = getDestinations().some((i) => i === 'eiqassess');
  const canSeeLearnLink = getDestinations().some((i) => i === 'eiqlearning');

  const userIsGhosted = currentUser?.data.ghost.is_ghost ? 'bg-mint-100 text-navy-100' : 'bg-white';

  const toggleNotificationMenu = (e) => {
    e.stopPropagation();

    setUserMenuIsOpen(false);
    setNotificationMenuIsOpen((s) => !s);
  };
  const toggleUserMenu = (e) => {
    e.stopPropagation();

    setNotificationMenuIsOpen(false);
    setUserMenuIsOpen((s) => !s);
  };

  const getUserInitials = () => {
    if (currentUser?.data?.username) {
      const nameArray = currentUser.data.username.split(' ');
      const firstLetter = nameArray[0].slice(0, 1);
      const lastLetter = nameArray.pop().slice(0, 1);
      return firstLetter + lastLetter;
    } else {
      return <img src={IconAccounts} alt="account-icon" className="user_icon svg-gray" />;
    }
  };

  const onClickOutside = (e) => {
    e.stopPropagation();

    setUserMenuIsOpen(false);
    setNotificationMenuIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener('click', onClickOutside);

    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, []);

  return (
    <div className="topbar-wrapper">
      {/* empty div so the flex align items evenly*/}
      <div></div>
      <div className="platform-redirection_wrapper">
        {canSeeAssessLink && (
          <div className="platform-redirection">
            <a href={`${ssoHost}/go?target_url=${eiqAssessHost}&requester=eiqanalyze&destination=eiqassess`}>
              <img src={AssessLogo} alt="eiq-logo" />
            </a>
          </div>
        )}

        {canSeeLearnLink && (
          <div className="platform-redirection u-ml-10">
            <a href={`${ssoHost}/go?target_url=${eiqLearnHost}&requester=eiqanalyze&destination=eiqlearning`}>
              <img src={LearnLogo} alt="eiq-logo" />
            </a>
          </div>
        )}
      </div>

      <div className="user-section_wrapper">
        <div className="user-section">
          <NavLink id="Faq-link" exact to={Routes.Faq.path}>
            <span className="bg-white user-section_button">
              <img src={IconFaq} alt="Faq-link" className="svg-gray" />
            </span>
          </NavLink>
        </div>

        <div className="user-section u-position-relative">
          <span className="bg-white user-section_button" onClick={toggleNotificationMenu}>
            <img src={IconNotification} className="svg-gray" alt="Notification-link" />
            {unseen > 0 && <NotificationCircle numberOfNotifications={unseen} />}
          </span>
          {notificationMenuIsOpen && (
            <NotificationsMenu notifications={notifications} unseen={unseen} user={currentUser.data} />
          )}
        </div>

        <div className="user-section user_settings u-position-relative">
          <div onClick={toggleUserMenu} className={cx('user-section_button', userIsGhosted)}>
            <div className="u-p-0">{getUserInitials()}</div>
          </div>
          <UserMenu user={currentUser.data} userMenuIsOpen={userMenuIsOpen} />
        </div>
      </div>
    </div>
  );
};
