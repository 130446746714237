import { capitalize, get, includes, transform } from 'lodash';
import { Link } from 'react-router-dom';

import { buildPath } from 'src/utils/buildPath';
import { conditionalLabelColor } from 'src/classes/util/FormattingUtil';
import {
  fixNumberNotation,
  formatNumber,
  formatNumberWithSuffix,
  noDecimalPointNumberNotation
} from 'src/classes/util/NumberUtil';
import { I18n } from 'src/utils/translations';
import StatusLabel from 'src/components/utils/StatusLabel';
import { SupplierRoute } from 'src/routes';
import { usesFactory } from 'src/components/utils/Auth';
import { YES_EXPECTED_INDICES } from 'src/classes/data/suppliers/SupplierData';

export const levelTypes = { vendor: 'vendor', site: 'site' };

// Two types are needed in order to make supplier dropdown behave the same exact way as before third party upgrade
// For Supplier-support portals (RBA at this point)
export const sourceTypes = { elevate: 'elevate', third_party: 'third_party', uploaded: 'uploaded' };
export const supplierSourceTypes = { online: 'online', uploaded: 'uploaded' };
export const onlineTypes = [sourceTypes.elevate, sourceTypes.third_party, supplierSourceTypes.online];

export const isOnline = (distributor) => get(distributor, 'sources', []).some((s) => onlineTypes.includes(s));

export const isShared = (distributor) => distributor?.sources?.length === 0;

export const checkIndicesValue = (chartData, axis, type = 'value') => {
  const { indices_x_axis_value, indices_y_axis_value, x_axis_value, y_axis_value } = chartData;
  if (axis === 'x') {
    return x_axis_value.value === 'indices' ? indices_x_axis_value?.[type] : x_axis_value[type];
  }
  return y_axis_value.value === 'indices' ? indices_y_axis_value?.[type] : y_axis_value[type];
};

export const handleTooltipText = (text) => {
  if (I18n.lookup(`risk_indices.${text}.desc`)) {
    return I18n.t(`risk_indices.${text}.desc`);
  } else if (text === 'analytics_leverage') {
    const kind = usesFactory() ? 'site' : 'supplier';
    return I18n.t(`analytics.chart_tooltip.${text}`, { kind_translated: I18n.t(`kind.${kind}`) });
  } else if (I18n.lookup(`analytics.chart_tooltip.${text}`)) {
    return I18n.t(`analytics.chart_tooltip.${text}`);
  }
  return null;
};

export const levelOptions = () => [
  { label: I18n.t('suppliers.risk_list.filters.level.site'), value: levelTypes.site },
  { label: I18n.t('suppliers.risk_list.filters.level.vendor'), value: levelTypes.vendor }
];

export const sourceOptions = () => {
  const selectedSourceTypes = accessibleSourceTypes();
  return transform(
    selectedSourceTypes,
    (result, value, key) => result.push({ label: sourceTranslation(key), value: value }),
    []
  );
};

export const accessibleSourceTypes = () => (isFactory() ? sourceTypes : supplierSourceTypes);

export const allSourceTypes = { ...supplierSourceTypes, ...sourceTypes };

export const sourceLabelColorOptions = () => {
  const mapping = {};
  Object.keys(allSourceTypes).map((s) => (mapping[s] = { text: sourceTranslationKey(s), color: sourceColor(s) }));
  return mapping;
};

export const sourceTranslation = (sourceKey) => I18n.t(sourceTranslationKey(sourceKey));

export const sourceTranslationKey = (sourceKey) => `suppliers.risk_list.filters.source.${sourceKey}`;

export const sourceColor = (sourceKey) =>
  ({
    elevate: 'darkMint-100',
    online: 'extreme',
    third_party: 'low',
    uploaded: 'high'
  }[sourceKey]);

export const isExcludedFromRiskList = (riskAssessment) => {
  // TODO probably handle this better way on the backend
  const onlyRiskForFactory = [
    'materiality_score',
    'overall_score',
    'risk_management_score',
    'risk_exposure_score',
    'spend_score',
    'saq_score',
    'assessment_score',
    'cap_score',
    'elearning_score',
    'worker_voice_score'
  ];
  let listOfExcluded = isFactory() ? onlyRiskForFactory : ['internal_risk_score', 'overall_score'];
  return includes(listOfExcluded, riskAssessment);
};

// Returns all combinations that can be used in translations.
export const factoryOrSupplier = () => {
  const seesFactory = isFactory();
  let kind = seesFactory ? 'site' : 'supplier';
  let kind_plural = seesFactory ? 'sites' : 'suppliers';

  return {
    kind: kind,
    kind_translated: I18n.t(`kind.${kind}`),
    kind_capitalized: capitalize(I18n.t(`kind.${kind}`)),
    kind_plural: kind_plural,
    kind_plural_translated: I18n.t(`kind.${kind_plural}`),
    kind_plural_capitalized: capitalize(I18n.t(`kind.${kind_plural}`))
  };
};

export const isFactory = () => usesFactory();

/**
 * Return translation use translation for factories if user has proper permission
 * if there is no specify translation for factories use the supplier translation
 *
 * @param {string} path - eg. 'some.path' no trailing dot, can be single word
 * @param {string} key - eg. 'some.key' no trailing dot, can be single word
 * @param {?string} [defaultValue] - any string used if there is no factory or supplier
 * @param {object} [translationParams] - params passed to I18n.t function
 * @return {string}
 */
export const getDistributorTranslation = (path, key, defaultValue = null, translationParams = {}) => {
  // if default value not passed we want the default no translation message
  if (defaultValue !== null) {
    translationParams.defaultValue = defaultValue;
  }

  return I18n.t(getDistributorTranslationKey(path, key), translationParams);
};

export const getDistributorTranslationKey = (path, key) => {
  if (isFactory() && I18n.lookup(`${path}.factory.${key}`)) {
    return `${path}.factory.${key}`;
  }

  return `${path}.${key}`;
};

export const labelCellRenderer = (value, colorTextMap) => (
  <label className={`indicator--label bg-${colorTextMap[value].color}`}>
    {I18n.t(`suppliers.risk_list.filters.source_short.${value}`)}
  </label>
);

export const tooltipSourceRenderer = (value, colorTextMap) => I18n.t(colorTextMap[value].text);

export const linkCellRenderer = ({ value }) => (
  <Link key={value.id} to={buildPath(SupplierRoute, { id: value.id })}>
    {value.name}
  </Link>
);

// It needs to be a function in order to I18n to work correctly.
// Store as array to ensure order is always the same.
export const riskBands = () => [
  { key: 'extreme', label: I18n.t('analytics.extreme') },
  { key: 'high', label: I18n.t('analytics.high') },
  { key: 'medium', label: I18n.t('analytics.medium') },
  { key: 'low', label: I18n.t('analytics.low') }
];

// Table cells renderers

export const scoreCellRenderer = ({ row, value, column }) => {
  let color, confidence, label;

  if (row.original.binary) {
    label = I18n.t(`confirm.${(value > 0).toString()}`);

    if (value > 0) {
      color = YES_EXPECTED_INDICES.includes(row.name) ? 'low' : 'extreme';
    } else {
      color = 'no-data';
    }
  } else {
    label = formatNumber(value, fixNumberNotation, I18n.t('na'));
    color = conditionalLabelColor(value ? parseFloat(value) : null);

    if (row.original.confidence) {
      confidence = row.original.confidence[column.id];
      confidence = formatNumberWithSuffix(
        confidence ? confidence * 100 : null,
        noDecimalPointNumberNotation,
        I18n.t('na'),
        '%'
      );
      confidence = `(${I18n.t('risk_landscape.confidence')}: ${confidence})`;
    }
  }

  return <StatusLabel value={color} label={label} confidence={confidence} />;
};
