import { colors } from 'tailwind.colors';

export const getThemeColor = (color: string): string => {
  // Extracting the color name and level from the color string
  const parts = color.split('[');
  const colorName = parts[0];
  const level = parts[1]?.replace(']', '');

  const colorLevel = level !== undefined ? parseInt(level, 10) : undefined;

  // Access the color from the theme
  const themeColor = colorLevel ? colors[colorName]?.[colorLevel] : colors[colorName];

  return themeColor;
};
