import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import React from 'react';

import apple_touch_icon from 'assets/images/apple-touch-icon.png';
import favicon from 'assets/images/LRQA_EiQ_favicon.png';
import favicon_16x16 from 'assets/images/favicon-16x16.png';
import favicon_32x32 from 'assets/images/favicon-32x32.png';
import { generateEnvTitle } from 'src/utils/generateEnvTitle';
import safari_pinned_tab from 'assets/images/safari-pinned-tab.svg';
import { getThemeColor } from 'src/utils/tailwindUtils';

const DynamicHeadContent = () => {
  const { portals, locale } = useSelector((state) => state);
  const { favicon_16, favicon_32, favicon_ico } = portals.data.current;

  const customFavicons = favicon_16 || favicon_32 || favicon_ico;

  const defaultTitle = 'EiQ Analyse';
  const pageTitleObj = _.get(portals.data.current, 'page_titles', []).find(
    (title) => _.get(title, 'locale') === locale
  );
  const pageTitle = _.get(pageTitleObj, 'text', defaultTitle);
  const envTitle = generateEnvTitle();
  return (
    <Helmet>
      <title>{`${pageTitle} ${envTitle}`}</title>
      {customFavicons && favicon_16 && <link rel="icon" type="image/png" sizes="16x16" href={favicon_16.data} />}
      {customFavicons && favicon_32 && <link rel="icon" type="image/png" sizes="32x32" href={favicon_32.data} />}
      {customFavicons && favicon_ico && <link rel="shortcut icon" href={favicon_ico.data} />}
      {!customFavicons && [
        <meta key="msapplication-TileColor" name="msapplication-TileColor" content="#ffffff" />,
        <link key="apple" rel="apple-touch-icon" sizes="180x180" href={apple_touch_icon} />,
        <link key="icon16" rel="icon" type="image/png" sizes="16x16" href={favicon_16x16} />,
        <link key="icon32" rel="icon" type="image/png" sizes="32x32" href={favicon_32x32} />,
        <link key="mask" rel="mask-icon" href={safari_pinned_tab} color={getThemeColor('darkMint[100]')} />,
        <link key="shortcut" rel="shortcut icon" href={favicon} color={getThemeColor('darkMint[100]')} />
      ]}
    </Helmet>
  );
};

export default DynamicHeadContent;
