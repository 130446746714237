import React, { useCallback, useEffect, useState } from 'react';

import PortalMenuContext from 'src/components/layout/aside/menus/PortalMenu.context';

const PortalMenuProvider = (props) => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleOpenPortalMenu = (menuName) => {
    setActiveSubmenu(menuName);
  };

  const handleCloseAllPortalMenu = useCallback(() => {
    if (activeSubmenu) {
      setActiveSubmenu(null);
    }
  }, [activeSubmenu]);

  useEffect(() => {
    document.addEventListener('click', handleCloseAllPortalMenu);
    return () => {
      document.removeEventListener('click', handleCloseAllPortalMenu);
    };
  }, [handleCloseAllPortalMenu]);

  return (
    <PortalMenuContext.Provider
      value={{
        activeSubmenu,
        handleOpenPortalMenu,
        handleCloseAllPortalMenu
      }}>
      {props.children}
    </PortalMenuContext.Provider>
  );
};

export default PortalMenuProvider;
