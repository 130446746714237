import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import {
  ACCOUNT_ASSESSMENTS_TEMPLATE,
  ASSESSMENTS_TEMPLATE,
  PREDEFINE_COLOR,
  SAQ_TEMPLATE,
  SITE_PERFORMANCE_COLUMNS
} from 'src/classes/common/Constants';
import { AccountsRoute, fetchRootRoute } from 'src/routes';
import { buildPath } from 'src/utils/buildPath';
import ContactUsModal from 'src/components/contact-us/ContactUsModal';
import DropdownItem from 'src/components/layout/header/DropdownItem.jsx';
import { fetchUserDependentData } from 'src/redux/actions/userDependentData';
import history from 'src/history';
import { I18n } from 'src/utils/translations';
import LocaleMenu from 'src/components/layout/aside/menus/LocaleMenu';
import { PortalActions, UserActions } from 'src/classes/util/RequestUtil';
import { Routes } from 'src/routesConfig';
import { signOut } from 'src/utils/JWTUtils';

class UserMenu extends React.Component {
  contactUsModal = null;

  state = {
    showContactUsModal: false
  };

  toggleContactUsForm = () => this.setState((prevState) => ({ showContactUsModal: !prevState.showContactUsModal }));

  getUserNickname = () => this.props.user.profile.nickname;

  isGhost = () => this.props.user.ghost.is_ghost;

  ghostUserName = () => {
    let user = this.props.user;
    let ghostName = user.account.name;
    if (user.ghost.user_username) {
      ghostName = `${user.ghost.user_username} (${user.account.name}) `;
    }

    return ghostName;
  };

  getDynamicMenuItems = () => {
    let items = [];

    this.props.user.other_portals.forEach((portal) =>
      items.push({
        action: () => this.switchPortal(portal.id),
        title: I18n.t('portals.log_in_to', { name: portal.name }),
        className: 'w-full'
      })
    );

    if (this.isGhost()) {
      items.push({
        action: this.turnOffGhost,
        title: I18n.t('header.log_out_of', { name: this.ghostUserName() }),
        className: 'exit-ghost'
      });
    }

    return items;
  };

  refetchUser = () => {
    return this.props.fetchUserDependentData();
  };

  switchPortal = (portalId) => {
    return PortalActions.switch(portalId)
      .then(this.refetchUser)
      .then(() => history.push(fetchRootRoute()));
  };

  turnOffGhost = () => {
    return UserActions.turnOffGhost()
      .then(this.refetchUser)
      .then(() => {
        const localStorageItems = [
          ACCOUNT_ASSESSMENTS_TEMPLATE,
          ASSESSMENTS_TEMPLATE,
          PREDEFINE_COLOR,
          SAQ_TEMPLATE,
          SITE_PERFORMANCE_COLUMNS
        ];

        localStorageItems.forEach((item) => localStorage.removeItem(item));
        history.push(AccountsRoute);
      });
  };

  getSubLocaleMenu = () => (
    <li className="dropdown__sub--parent">
      <span role="button">{I18n.t('header.language')}</span>
      <LocaleMenu />
      <i className="icon icon-chevron-right cursor-pointer absolute top-3 right-3 text-darkBlue"></i>
    </li>
  );

  getPortalMenu = () => {
    return (
      <ul className="dropdown dropdown__menu dropdown_user !absolute bg-white">
        <DropdownItem
          id="view-profile"
          key="view-profile-key"
          action={buildPath(Routes.UserProfile.path, { id: this.getUserNickname() })}
          isInternalLink
          title={I18n.t('view_profile')}
        />
        {this.getSubLocaleMenu()}
        <DropdownItem
          id="contact-us"
          key="contact-us-key"
          action={this.toggleContactUsForm}
          isInternalLink
          title={I18n.t('header.contact_us')}
        />
        {this.getDynamicMenuItems().map((item, i) => (
          <DropdownItem key={i} {...item} />
        ))}
        <DropdownItem
          id="sign-out"
          key="sign-out-key"
          action={signOut}
          isInternalLink
          title={I18n.t('header.log_out')}
        />
      </ul>
    );
  };

  render = () => {
    return (
      <Fragment>
        {this.props.userMenuIsOpen && this.getPortalMenu()}
        <ContactUsModal
          user={this.props.user}
          isOpen={this.state.showContactUsModal}
          toggleVisibility={this.toggleContactUsForm}
        />
      </Fragment>
    );
  };
}

UserMenu.propTypes = {
  fetchUserDependentData: PropTypes.func.isRequired,
  user: PropTypes.shape({
    account: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired,
    email: PropTypes.string.isRequired,
    ghost: PropTypes.shape({
      account_id: PropTypes.number,
      is_ghost: PropTypes.bool,
      user_id: PropTypes.number,
      user_username: PropTypes.string
    }),
    is_manager: PropTypes.bool,
    other_portals: PropTypes.array,
    profile: PropTypes.shape({
      fullname: PropTypes.string,
      nickname: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  userMenuIsOpen: PropTypes.bool.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  fetchUserDependentData: () => dispatch(fetchUserDependentData())
});

const Wrp = connect(null, mapDispatchToProps)(UserMenu);

export default Wrp;
