import PropTypes from 'prop-types';

import { hasAnyPermission } from 'src/components/utils/Auth';

const WithAnyPermission = (props) => (hasAnyPermission(props.permissions) ? props.children : null);

WithAnyPermission.propTypes = {
  permissions: PropTypes.array.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

WithAnyPermission.defaultProps = {
  children: null
};

export default WithAnyPermission;
