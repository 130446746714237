// Smart component for gathering data for logged in user page layout

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isCurrentPathWithoutParams } from 'src/utils/isCurrentPathWithoutParams';
import { isCurrentPathWithParams } from 'src/utils/isCurrentPathWithParams';
import LoggedInLayout from 'src/components/layout/LoggedInLayout';

export const LoggedInLayoutLogicWrapper = ({ isSignedIn, children, userDependentDataFetched }) => {
  const { pathname } = useLocation();
  const isFullscreenPath = isCurrentPathWithParams(pathname, 'isFullScreen');
  const isPathWithAuthentication = isCurrentPathWithoutParams(pathname, 'isWithoutAuthentication');

  const { currentUser, navbar } = useSelector((state) => state);
  const { current: currentPortal } = useSelector((state) => state.portals.data);

  const { notifications, previewExt, unseen } = navbar;

  return userDependentDataFetched && isSignedIn && (!isFullscreenPath || isPathWithAuthentication) ? (
    <LoggedInLayout
      portal={currentPortal}
      user={currentUser.data}
      notifications={notifications}
      previewExt={previewExt}
      unseen={unseen}
      pathname={pathname}
      content={children}
    />
  ) : null;
};
