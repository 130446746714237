import { hasAnyPermission, hasPermission, permissionsList } from 'src/components/utils/Auth';

export const AcceptInvitationRoute = '/invitation/accept';
export const AcceptPendingTermsRoute = '/invitation/accept-terms/:id';
export const AccountRoute = '/accounts/:slug';
export const AccountUsersRoute = '/accounts/:slug/users';
export const AccountsRoute = '/accounts';
export const AnalyticsRoute = '/segmentation/analytics';
export const ArticlesRoute = '/sentinel-events';
export const AssessmentsRoute = '/my-program/assessments';
export const CountryRiskRoute = '/risk-landscape/geography';
export const DashboardEditRoute = '/dashboards/:id/edit';
export const DashboardRoute = '/dashboards/:id';
export const EditPasswordRoute = '/password/edit';
export const EngagementRoute = '/my-program/worker-engagement';
export const EnvironmentalMetricsRoute = '/environmental-metrics';
export const EsgRatingsRoute = '/esg-ratings';
export const EsgSiteRoute = '/esg-ratings/:slug';
export const FaqRoute = '/faq';
export const GroupEditRoute = '/admin/manage-groups/:id';
export const ManageGroupsRoute = '/admin/manage-groups';
export const NotificationsHistoryRoute = '/notifications';
export const PortalEditRoute = '/system-admin/portals/:id';
export const PortalLoginRoute = '/:portal';
export const PortalsRoute = '/system-admin/portals';
export const PreviewLoginScreenRoute = '/portals/:slug/preview';
export const ProductRiskRoute = '/risk-landscape/products';
export const ProductRiskProfileRoute = '/risk-landscape/product/:slug';
export const ProgramRoute = '/my-program/surveys/:id';
export const ProgramsRoute = '/my-program/surveys';
export const ProgramSettingsRoute = '/program-settings';
export const ReportBuilderRoute = '/my-program/report-builder';
export const RiskLandscapeIndexRoute = '/risk-landscape/global';
export const RiskManagementOverViewRoute = '/risk-management/overview';
export const SendNotificationRoute = '/admin/notification';
export const SingleSignOnHandlerGetAuthRoute = '/oauth2/authorize';
export const SingleSignOnHandlerRoute = '/oauth2/authorize/handler';
export const SupplierRoute = '/sites/:id';
export const SuppliersRoute = '/segmentation/site-performance';
export const SuppliersUploadRoute = '/segmentation/upload-site-data';
export const TermsConditionsRoute = '/terms-conditions';
export const TrendReportsRoute = '/my-program/program-trend-reports';
export const UserProfileEditRoute = '/users/edit/:id';
export const UserProfileRoute = '/users/:id';
export const UsersEditRoleRoute = '/admin/manage-roles/:id';
export const UsersNewRoleRoute = '/admin/manage-roles/new';
export const UsersRolesRoute = '/admin/manage-roles';
export const UsersRoute = '/admin/manage-users';
export const VisualizationViewRoute = '/surveys/visualizations/:id';

// Static routes
export const ContactRoute = 'https://www.elevatelimited.com/about-elevate/contact/';
export const ContactRouteStaging = 'https://www.staging12.elevatelimited.com/about-elevate/contact/';
export const EsgPressReleaseRoute = 'https://www.elevatelimited.com';
export const HomeRoute = '/';
export const RequestAuditRoute = 'https://www.elevatelimited.com/request-audit/';

export const fetchRootRoute = () => {
  if (
    hasAnyPermission([permissionsList.seeNewDashboard, permissionsList.seeOldDashboard]) ||
    !hasPermission(permissionsList.assessmentsView)
  ) {
    return HomeRoute;
  }

  return AssessmentsRoute;
};
