import colors from 'tailwindcss/colors';
import PropTypes from 'prop-types';
import React from 'react';

import CollapsedLogo from 'assets/images/rba-logo-collapsed.png';
import FlexRow from 'src/components/common/layout/flex/FlexRow';
import { ReactComponent as AnalyzeFullLogo } from 'assets/images/sidebar/LRQA_EiQ_Analyze.svg';
import { ReactComponent as Logo } from 'assets/images/sidebar/LRQA_EiQ.svg';

const AsideHeader = ({ callback, isSidebarExpanded, portal }) => {
  const isRBAPortal = portal.name === 'RBA';
  const logoProps = isRBAPortal ? { height: '43px', width: '190px' } : {};

  const LogoComponent = !isSidebarExpanded ? (
    isRBAPortal ? (
      <img src={CollapsedLogo} className="logo mr-2.5" alt="expanded rba logo" />
    ) : (
      <Logo className="collapsed-logo mr-2.5" />
    )
  ) : portal?.corner_logo?.data ? (
    <img
      className={isRBAPortal ? null : 'logo'}
      src={portal?.corner_logo?.data}
      alt={`${portal.name} logo`}
      {...logoProps}
    />
  ) : (
    <AnalyzeFullLogo className={isRBAPortal ? null : 'logo'} {...logoProps} />
  );

  return (
    <div className="l-nav__header" style={isRBAPortal ? {} : { backgroundColor: colors.white }}>
      <FlexRow align="ai-center" justify="jc-between">
        {LogoComponent}
        <div
          aria-label="expand-collapse menu"
          style={isRBAPortal ? { color: colors.white } : {}}
          className={`btn--nav-blue ${isSidebarExpanded ? 'left' : 'right'}`}
          onClick={callback}
        />
      </FlexRow>
    </div>
  );
};

AsideHeader.propTypes = {
  callback: PropTypes.func.isRequired,
  isSidebarExpanded: PropTypes.bool,
  portal: PropTypes.object
};

AsideHeader.defaultProps = {
  isSidebarExpanded: true,
  portal: {}
};

export default AsideHeader;
