/**
 * Check if baseArray contains any of compareArray items
 * @param {string[]} baseArray
 * @param {string[]} compareArray
 *
 * @return {boolean}
 */
export function includesOneOf(baseArray, compareArray) {
  return baseArray.some((item) => compareArray.includes(item));
}
