import { connect } from 'react-redux';
import _ from 'lodash';
import React from 'react';

import { LOCALES } from 'src/utils/locales';
import { setLocale } from 'src/redux/actions/locale';
import { fetchTranslations } from 'src/redux/actions/translations';
import { UserActions } from 'src/classes/util/RequestUtil';

import DropdownItem from 'src/components/layout/header/DropdownItem.jsx';
import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';

class LocaleMenu extends React.Component {
  componentDidMount = () => {
    SessionStorageProvider.remove('countryNames');
  };

  changeLocale = (locale) =>
    UserActions.changeLocale({ locale }).then(() => {
      SessionStorageProvider.remove('countryNames');
      this.props.setLocale(locale);
      this.props.fetchTranslations(locale);
    });

  itemParams = (name, locale) => ({
    action: () => this.changeLocale(locale),
    key: locale,
    title: name
  });

  render = () => {
    const filteredLocales = _.omit(LOCALES, this.props.locale);
    return (
      <ul className="dropdown__menu dropdown__portal dropdown__third--level">
        {_.map(filteredLocales, (name, locale) => (
          <DropdownItem className="bg-white" {...this.itemParams(name, locale)} />
        ))}
      </ul>
    );
  };
}

const mapStateToProps = (state) => ({
  locale: state.locale
});

const mapDispatchToProps = (dispatch) => ({
  setLocale: (locale) => dispatch(setLocale(locale)),
  fetchTranslations: (locale) => dispatch(fetchTranslations(locale))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocaleMenu);
