// based on https://tagmanager.google.com/ Admin Install Google Tag Manager
import { Helmet } from 'react-helmet';
import React, { Fragment } from 'react';

const GoogleTagManager = () => {
  const headScript = `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${process.env.GTM_ID}');`;

  return (
    <Fragment>
      <Helmet>
        <script>{headScript}</script>
      </Helmet>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GTM_ID}`}
          height="0"
          width="0"
          title="Google tag manager"
          style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
    </Fragment>
  );
};

export default GoogleTagManager;
