import { SET_REQUEST_INTERMINABLE } from 'src/redux/actionTypes';

const initialState = {
  interminable: false
};

export const requestReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REQUEST_INTERMINABLE:
      return {
        interminable: action.payload
      };
    default:
      return state;
  }
};
