import Alert from 'react-s-alert';
import PropTypes from 'prop-types';
import React from 'react';

import { usesFactory } from 'src/components/utils/Auth';
import { I18n } from 'src/utils/translations';
import { NotificationActions } from 'src/classes/util/RequestUtil';

import ImageEVT from 'assets/images/modal_top_evt.png';
import ImageRBA from 'assets/images/modal_top_rba.png';
import InfoModalContent from 'src/components/common/InfoModalContent';
import Modal from 'src/components/modal/Modal';
import NotificationModal from 'src/components/modal/NotificationModal';
import Spinner from 'src/components/common/loading/Spinner';

export default class InfoModal extends React.Component {
  state = {
    notification: null,
    showModal: false
  };

  componentDidMount = () => {
    this.showModal();
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      this.showModal();
    }
  };

  showModal = () => {
    if (this.props.notification) {
      setTimeout(() => {
        this.setState({ notification: this.props.notification, showModal: true });
      }, this.props.delay);
    }
  };

  closeModal = () => {
    const { markReadOnClose, notification } = this.props;

    if (!markReadOnClose || !notification?.id) {
      return this.hideModal();
    }

    NotificationActions.markSeen(notification.id)
      .then(this.hideModal)
      .catch(() => Alert.error(I18n.t('errors.ui_crash.title')));
  };

  hideModal = () => {
    this.setState({ notification: null, showModal: false }, this.props.callback);
  };

  modalImage = () => (usesFactory() ? ImageEVT : ImageRBA);

  render = () => {
    const { notification } = this.props;
    const notificationList = this.props.notification
      ? [
          {
            id: 'notification',
            html_message: this.props.notification.html_message,
            message: {
              headline: this.props.notification.headline
            }
          }
        ]
      : [];

    return (
      this.props.notification && (
        <Modal
          imageTitle={this.modalImage()}
          showOnlyContent={this.props.notification?.dashboard}
          isOpen={this.state.showModal}
          onRequestClose={this.closeModal}>
          <Spinner loading={!this.state.notification} className="u-text-center">
            {this.props.notification?.dashboard ? (
              <NotificationModal
                items={notificationList}
                onSubmit={notification.submitEvent}
                title={I18n.t('news_update')}
                onClose={this.closeModal}
              />
            ) : (
              <InfoModalContent
                notification={this.state.notification}
                onSubmit={notification.submitEvent}
                onClose={this.closeModal}
              />
            )}
          </Spinner>
        </Modal>
      )
    );
  };
}

InfoModal.propTypes = {
  callback: PropTypes.func.isRequired,
  delay: PropTypes.number,
  markReadOnClose: PropTypes.bool,
  notification: PropTypes.shape({
    html_message: PropTypes.string,
    id: PropTypes.number
  })
};

InfoModal.defaultProps = {
  delay: 1500,
  markReadOnClose: true,
  notification: {}
};
