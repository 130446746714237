import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import React, { Fragment } from 'react';

import CancelButton from 'src/components/common/forms/Button/CancelButton';
import EiqButtonWrapper from 'src/components/common/EiqButtonWrapper/EiqButtonWrapper';
import FlexRow from 'src/components/common/layout/flex/FlexRow';
import { I18n } from 'src/utils/translations';
import SaveButton from 'src/components/common/forms/Button/SaveButton';

export default class Modal extends React.Component {
  componentDidMount = () => {
    ReactModal.setAppElement('body');
  };

  className = () => {
    const classes = [this.props.className];
    if (this.props.skipOverflow) classes.push('skip-overflow');

    return classes.join(' ');
  };

  renderHeader = () => {
    const { headerAddition, imageTitle, showCloseButton, subtitle, title } = this.props;
    return (
      <div>
        <div
          className={`ReactModal__Header ${subtitle ? 'u-pb-0' : ''} ${
            !(title || headerAddition || imageTitle) && showCloseButton ? 'jc-end' : ''
          }`}>
          {title && <h2>{title}</h2>}
          {headerAddition?.()}
          {imageTitle && <img src={imageTitle} className="u-width-15" alt="Modal" />}
          {showCloseButton && this.renderCloseButton()}
        </div>
        {subtitle && <div className="ReactModal__Header u-pt-0 u-pb-0">{subtitle}</div>}
      </div>
    );
  };

  renderCloseButton = () => (
    <EiqButtonWrapper
      aria-label="close modal"
      id="close-modal-btn"
      onClick={this.props.onRequestClose}
      size="btn--sm"
      type="button"
      variant="btn--close"
      variantColor="transparent"
    />
  );

  renderBody = () => (
    <div
      className="ReactModal__Body"
      style={{
        maxHeight: this.props.maxHeight ? this.props.maxHeight : null
      }}>
      {this.props.children}
    </div>
  );

  renderFooter = () => {
    const {
      cancelText,
      confirmCallback,
      confirmText,
      deleteText,
      hasDelete,
      hasFooter,
      hasSave,
      isSaveButtonDisabled,
      onDelete,
      onRequestClose
    } = this.props;

    if (hasFooter) {
      return (
        <FlexRow
          addClass="ReactModal__Footer"
          align="ai-center"
          justify={hasSave ? 'jc-end' : 'jc-center'}
          spacer="u-p-15">
          {hasDelete && (
            <EiqButtonWrapper
              aria-label="delete"
              className="u-mr-auto hover:text-black"
              label={deleteText || I18n.t('delete')}
              leftIcon="trash"
              onClick={onDelete}
              size="btn--sm"
              type="button"
              variant="btn--solid"
              variantColor="extreme"
              variantTextColor="text-white"
            />
          )}
          <CancelButton onClick={onRequestClose} />
          {hasSave && (
            <SaveButton className={`!ml-4 ${isSaveButtonDisabled ? 'disabled' : ''}`} onClick={confirmCallback} />
          )}
        </FlexRow>
      );
    }

    if (!confirmCallback) {
      return null;
    }

    return (
      <FlexRow align="ai-center" justify="jc-end" spacer="ReactModal__Footer">
        <EiqButtonWrapper
          aria-label="confirm false"
          className="!mr-3.8 hover:text-white"
          id="confirm-false-btn"
          label={cancelText || I18n.t('confirm.false')}
          onClick={() => confirmCallback(false)}
          size="btn--sm"
          type="button"
          variant="btn--outline"
          variantColor="extreme"
        />
        <EiqButtonWrapper
          aria-label="confirm true"
          id="confirm-true-btn"
          label={confirmText || I18n.t('confirm.true')}
          onClick={() => confirmCallback(true)}
          size="btn--sm"
          type="button"
          variant="btn--solid"
          variantColor="mint-100"
        />
      </FlexRow>
    );
  };

  renderContentOnly = () => this.props.children;

  render = () => (
    <ReactModal
      id="Modal" // Note: Added id for automated testing
      closeTimeoutMS={150}
      {...this.props}
      ariaHideApp={false}
      className={this.className()}
      shouldFocusAfterRender={false}>
      {this.props.showOnlyContent ? (
        this.renderContentOnly()
      ) : (
        <Fragment>
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </Fragment>
      )}
    </ReactModal>
  );
}

Modal.propTypes = {
  ...ReactModal.propTypes,
  cancelText: PropTypes.string,
  confirmCallback: PropTypes.func,
  confirmText: PropTypes.string,
  deleteText: PropTypes.string,
  hasDelete: PropTypes.bool,
  hasFooter: PropTypes.bool,
  hasSave: PropTypes.bool,
  headerAddition: PropTypes.func,
  isSaveButtonDisabled: PropTypes.bool,
  onDelete: PropTypes.func,
  showCloseButton: PropTypes.bool,
  showOnlyContent: PropTypes.bool,
  skipOverflow: PropTypes.bool,
  title: PropTypes.string
};

Modal.defaultProps = {
  cancelText: null,
  className: 'modal--content',
  confirmCallback: null,
  confirmText: null,
  deleteText: '',
  hasDelete: false,
  hasFooter: false,
  hasSave: false,
  headerAddition: null,
  isSaveButtonDisabled: false,
  onDelete: null,
  overlayClassName: 'modal--overlay',
  showCloseButton: true,
  showOnlyContent: false,
  skipOverflow: false,
  title: null
};
