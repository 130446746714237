export const AccountDataURL = '/accounts/:slug';
export const AccountListURL = '/accounts/list';
export const AccountUpdateGroupAccessURL = '/accounts/:slug/update_group_access';
export const AccountUpdateStatusURL = '/accounts/:slug/update_status';
export const AccountUsersDataURL = '/accounts/:slug/users_data';
export const AccountUsersURL = '/accounts/:slug/account_users';
export const AccountsDataURL = '/accounts/data';
export const ActivityLogsAccountDataActivityLogsURL = '/activity_logs/account_data';
export const ActivityLogsAccountUsersDataActivityLogsURL = '/activity_logs/account_users_data';
export const ActivityLogsUserDataActivityLogsURL = '/activity_logs/user_data';
export const AnalyticsButterflyDropdownURL = '/analytics/butterfly_dropdown_options';
export const AnalyticsChartsDataAnalyticsURL = '/analytics/charts_data';
export const AnalyticsExportAnalyticsURL = '/analytics/export';
export const AnalyticsFilterOptionsAnalyticsURL = '/analytics/filter_options';
export const ArticlesArticleRelevancyChangeURL = '/sentinel/:article_id/relevancy_change';
export const ArticlesDynamicFiltersSuggestionsArticlesURL = '/sentinel/dynamic_filters_suggestions';
export const ArticlesExportTableDataURL = '/sentinel/export';
export const ArticlesSentinelIncidentsURL = '/sentinel/sentinel_incidents';
export const ArticlesSentinelMapURL = '/sentinel/country_incidents';
export const ArticlesSentinelRiskExposureURL = '/sentinel/risk_exposure';
export const ArticlesStaticFiltersSuggestionsArticlesURL = '/sentinel/static_filters_suggestions';
export const ArticlesGeographyRiskLandscapeURL = '/risk_indices/sentinel';
export const ArticlesListSentinelURL = '/sentinel/list';
export const ArticlesSentinelTreeMapURL = '/sentinel/tree_map';
export const AssessmentsAssessmentReportDownloadLinkURL = '/assessments/:assessmentId/report_download_link';
export const AssessmentsClientCategories = '/assessments/:assessmentId/client_categories';
export const AssessmentsCancelUpload = '/assessments/cancel_upload';
export const AssessmentsExportTableDataURL = '/assessments/export';
export const AssessmentsFilterOptionsAssessmentsURL = '/assessments/filter_options';
export const AssessmentsListAssessmentsURL = '/assessments/list';
export const AssessmentsReportFindingsSeverityURL = '/assessments/:report_number/findings_severity';
export const AssessmentsReportListAssessmentsURL = '/assessments/report_list';
export const AssessmentsTrendServiceFilterOptionsURL = '/assessments/trend_service_filter_options';
export const AssessmentsUploadURL = '/assessments/upload_assessments';
export const AssessmentsUploadStatus = '/assessments/upload_status';
export const AuditRatingsDropdowns = '/settings/account_ratings/dropdown_options';
export const ClientCategoriesURL = '/client_categories';
export const ClientReasonsClientReasonsListURL = '/client_reasons/list';
export const ContactUsURL = '/contact_us';
export const CountryCountriesForTrendReportURL = '/countries/for_trend_report';
export const CountryCountriesListURL = '/countries/list';
export const CurrentUserDataURL = '/users/current_user_info';
export const CustomizableWeightsCustomizableWeightURL = '/weight_templates/:templateId';
export const CustomizableWeightsCustomizableWeightsURL = '/weight_templates';
export const CustomRiskIndexURL = '/custom_risk_indices/:id';
export const CustomRiskIndicesSourceListURL = '/custom_risk_indices/source_list';
export const CustomRiskIndicesURL = '/custom_risk_indices';
export const DashboardsURL = '/dashboards';
export const DashboardURL = '/dashboards/:id';
export const DashboardBenchmarkingURL = '/main_dashboard/benchmarking';
export const DashboardButterflyURL = '/main_dashboard/butterfly';
export const DashboardProgramImplementationURL = '/main_dashboard/program_implementation';
export const DashboardNotificationListURL = '/main_dashboard/notifications';
export const DashboardNotificationShownURL = '/main_dashboard/mark_all_seen';
export const DashboardSentinelURL = '/main_dashboard/sentinel';
export const DashboardActivitiesURL = '/main_dashboard/activities';
export const DashboardSupplyChainURL = '/main_dashboard/supply_chain';
export const EnvironmentalMetricsAttributedEmissions = '/environmental_metrics/attributed_emissions';
export const EnvironmentalMetricsCarbonMitigation = '/environmental_metrics/carbon_mitigation';
export const EnvironmentalMetricsEmisionsAnalysis = '/environmental_metrics/emissions_analysis';
export const EnvironmentalMetricsEmissionPerformance = '/environmental_metrics/emissions_performance';
export const EnvironmentalMetricsEnergyConsumption = '/environmental_metrics/energy_consumption';
export const EnvironmentalMetricsRefrigerantUsage = '/environmental_metrics/refrigerants_usage';
export const EnvironmentalMetricsReportingConfidence = '/environmental_metrics/reporting_confidence';
export const EnvironmentalMetricsWasteWater = '/environmental_metrics/waste_water';
export const EnvironmentalMetricsWaterUsage = '/environmental_metrics/water_usage';
export const EsgRatingsURL = '/esg_ratings';
export const EsgRatingsCompanyURL = '/esg_ratings/:slug';
export const EsgRatingsFilterOptionsURL = '/esg_ratings/filter_option';
export const EsgRatingsForwardRequestURL = '/esg_ratings/forward_esg_request';
export const GeographyRiskLandscapeProductTableURL = '/risk_landscape/geography_landscape_product_table';
export const GroupItemURL = '/admin/groups/:id/item';
export const InvitationUserInvitationAcceptDataURL = '/invitation/accept';
export const InvitationUserInvitationAcceptPendingTermsURL = '/invitation/accept-terms/:id';
export const InvitationUserInvitationRemoveURL = '/invitation/remove';
export const InvitationUserInvitationResendURL = '/invitation/resend';
export const InvitationUserInvitationURL = '/invitation';
export const ListGroupsURL = '/admin/groups/list';
export const ManageGroupAddMemberURL = '/admin/groups/:group_id/add_member';
export const ManageGroupItemURL = '/admin/groups/:group_id';
export const ManageGroupRemoveMemberURL = '/admin/groups/:group_id/remove_member';
export const ManageGroupsURL = '/admin/groups';
export const NavbarInfoURL = '/api/navbar_info';
export const NotificationListNotificationsURL = '/notifications/list';
export const NotificationMarkAllSeenNotificationsURL = '/notifications/mark_all_seen';
export const NotificationNotificationMarkSeenURL = '/notifications/:notificationId/mark_seen';
export const NotificationNotificationsURL = '/notifications';
export const OrganizationForUpdateOrganizationsURL = '/organizations/for_update';
export const OrganizationGetAssessmentSourceURL = '/organizations/assessments_source';
export const OrganizationListEvtOrganizationsURL = '/organizations/list_evt';
export const OverviewOverviewDataURL = '/engagement/overview/data';
export const PermissionListPermissionsURL = '/permissions/list';
export const PortalDataWithPermissionsURL = '/portals/:slug/data_with_permissions';
export const PortalListPortalsURL = '/system_admin/portals/list';
export const PortalPortalURL = '/system_admin/portals/:portalId';
export const PortalPortalItemURL = '/system_admin/portals/:portalId/item';
export const PortalPortalSwitchPortalURL = '/system_admin/portals/:portalId/switch_portal';
export const PortalPortalsURL = '/system_admin/portals';
export const PortalsDataURL = '/users/portals_info';
export const ProductCommodityData = '/products/for_trend_report';
export const ProductCountryRiskURL = '/products/:slug/country_risk';
export const ProductFilterOptionsURL = '/products/filter_options';
export const ProductHierarchyURL = '/products/:slug/hierarchy_list';
export const ProductListURL = '/products/list';
export const ProductKeyIssuesURL = '/products/:slug/key_issues';
export const ProductRelatedURL = '/products/:slug/related_products';
export const ProductRiskProfileURL = '/products/:slug/risk_profile';
export const ProgramDataURL = '/programs/:id';
export const ProgramsDataURL = '/programs';
export const ProgramSettingsAuditRatingsURL = '/settings/account_ratings';
export const ProgramSettingsAuditRatingsUpdateURL = '/settings/account_ratings/save_audit_ratings';
export const ProgramSettingsURL = '/settings/filters';
export const ProgramSettingsUpdateURL = '/settings/filters/save_filters';
export const ProgramSettingsFindingMappingsURL = '/settings/finding_mappings';
export const ProgramSettingsFindingMappingsUpdateURL = '/settings/finding_mappings/save_finding_mappings';
export const ProgramSettingsNotificationsURL = '/settings/notifications/index';
export const ProgramSettingsNotificationsUpdateURL = '/settings/notifications/change_email_notifications';
export const ProvinceProvincesForTrendReportURL = '/provinces/for_trend_report';
export const ProvinceProvincesOfCountryURL = '/provinces/of_country';
export const RiskIndicesCountryCivilSocietyTrendURL = '/risk_indices/country_civil_society_trend';
export const RiskIndicesCountryRiskMapURL = '/risk_indices/country_risk_map';
export const RiskIndicesCountrySupplyChainTrendURL = '/risk_indices/country_supply_chain_trend';
export const RiskIndicesDownloadMethodologyDocumentURL = '/risk_indices/download_methodology_document';
export const RiskIndicesInsightsFileURL = '/risk_indices/risk_file_link';
export const RiskIndicesProfileURL = '/risk_indices/risk_profile';
export const RiskLandscapeListPillarWithCategoriesURL = '/risk_landscape/list_pillar_with_categories';
export const RiskLandscapeMainDashboardDataURL = '/main_dashboard/risk_landscape';
export const RiskLandscapeRiskLandscapeIndexDataURL = '/risk_landscape/index_data';
export const RiskLandscapeRiskLandscapeRiskSitesURL = '/risk_landscape/risk_sites';
export const RiskLandscapeRiskLandscapeSiteTickerURL = '/risk_landscape/site_ticker';
export const RiskLandscapeRiskLandscapeYearsURL = '/risk_landscape/years';
export const RoleListRolesURL = '/admin/roles/list';
export const RoleRoleURL = '/admin/roles/:id';
export const RoleRolesURL = '/admin/roles';
export const SectorSectorsForTrendReportURL = '/sectors/for_trend_report';
export const SignInURL = '/sign_in';
export const SignOutURL = '/sign_out';
export const SignInGetOauthURL = '/oauth2/generate_auth_code';
export const SignInViaOauthURL = '/oauth2/authorize';
export const SignInViaOauthCheckAccessURL = '/oauth2/destinations';
export const SupplierDataURL = '/suppliers/:id/data';
export const SupplierRiskExposureProductURL = '/suppliers/risk_exposure/product/:id';
export const SupplierSupplierURL = '/suppliers/:id';
export const SupplierSuppliersCancelUploadURL = '/suppliers/cancel_upload';
export const SupplierSuppliersExportURL = '/suppliers/export';
export const SupplierSuppliersDownloadURL = '/suppliers/download';
export const SupplierSuppliersFilterOptionsURL = '/suppliers/filter_options';
export const SupplierSuppliersKeyRisksURL = '/suppliers/:id/key_risks';
export const SupplierSuppliersListPillarsURL = '/suppliers/list_pillars';
export const SupplierSuppliersListSmeltersURL = '/suppliers/list_smelters';
export const SupplierSuppliersListSuppliersURL = '/suppliers/list_suppliers';
export const SupplierSuppliersChangeVisibilityURL = '/suppliers/change_visibility';
export const SupplierSuppliersListSuppliersDropdownInfoURL = '/suppliers/list_suppliers_dropdown_info';
export const SupplierSuppliersMergeDistributorsURL = '/suppliers/merge_distributors';
export const SupplierSuppliersPurgeURL = '/suppliers/purge';
export const SupplierSuppliersRiskManagementURL = '/suppliers/:id/risk_management';
export const SupplierSuppliersSupplyChainURL = '/suppliers/supply_chain';
export const SupplierSuppliersUploadStatusURL = '/suppliers/upload_status';
export const SupplierSuppliersUploadSuppliersURL = '/suppliers/upload_suppliers';
export const SuppliersUploadDataURL = '/suppliers/upload_data';
export const TranslationsDataURL = '/translations/:locale';
export const TrendReportTrendReportsBusinessEthicsURL = '/trend_reports/business_ethics';
export const TrendReportTrendReportsEnvironmentURL = '/trend_reports/environment';
export const TrendReportTrendReportsExportURL = '/trend_reports/export';
export const TrendReportTrendReportsHealthAndSafetyURL = '/trend_reports/health_and_safety';
export const TrendReportTrendReportsLaborURL = '/trend_reports/labor';
export const TrendReportTrendReportsManagementSystemsURL = '/trend_reports/management_systems';
export const TrendReportTrendReportsTransparencyURL = '/trend_reports/transparency';
export const TrendReportTrendReportsWagesURL = '/trend_reports/wages';
export const TrendReportTrendReportsWorkerDemographicsURL = '/trend_reports/worker_demographics';
export const TrendReportTrendReportsWorkerSentimentURL = '/trend_reports/worker_sentiment';
export const TrendReportTrendReportsWorkingHoursURL = '/trend_reports/working_hours';
export const TrendReportsV3AuditMetricsDataURL = '/trend_reports/v3/audit_metrics';
export const TrendReportsV3AuditCountsDataURL = '/trend_reports/v3/audit_counts';
export const TrendReportsV3AuditRatingsDataURL = '/trend_reports/v3/audit_ratings';
export const TrendReportsV3CommonFields = '/trend_reports/v3/common_findings';
export const TrendReportsV3FilterOptions = '/trend_reports/v3/filter_options';
export const TrendReportsV3Findings = '/trend_reports/v3/findings';
export const TrendReportsV3WorkerDemographicsDataURL = '/trend_reports/v3/worker_demographics';
export const UserChangeLocaleUsersURL = '/users/change_locale';
export const UserCurrentUserStaffUsersURL = '/users/current_user_staff';
export const UserDataURL = '/users/:nickname/user_data';
export const UserDropdownListUsersURL = '/users/dropdown_list';
export const UserListUsersURL = '/users/list';
export const UserPasswordEditURL = '/password';
export const UserPasswordEditDataURL = '/password/edit';
export const UserPermissionsURL = '/users/permissions';
export const UserResetPasswordURL = '/users/:nickname/reset_password';
export const UserUpdateStatusURL = '/users/:nickname/update_status';
export const UserTurnOffGhostUsersURL = '/users/turn_off_ghost';
export const UserTurnOnGhostUsersURL = '/users/turn_on_ghost';
export const UserUpdatePasswordURL = '/users/update_password';
export const UserUserURL = '/users/:nickname';
export const UserUserUpdateRoleURL = '/users/:nickname/update_role';
export const UserTACAccept = '/users/tac_accept';
export const VisualizationDataURL = '/visualizations/:id';
