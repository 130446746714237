import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const getLink = ({ action, title, icon, isInternalLink }) => {
  const { key, href, ...restOfActionParams } = action;

  const handleClick = (e) => {
    e.preventDefault();
    return action(e);
  };

  const content = (
    <Fragment>
      {icon && <FontAwesome name={icon} />}
      {title}
    </Fragment>
  );

  if (_.isString(action) && isInternalLink) {
    return <NavLink to={action}>{content}</NavLink>;
  }

  if (_.isFunction(action)) {
    return (
      <span role="button" {...restOfActionParams} onClick={handleClick}>
        {content}
      </span>
    );
  }

  return (
    <a {...restOfActionParams} href={action.href}>
      {content}
    </a>
  );
};

const DropdownItem = (props) => <li className={`dropdown__item ${props.className}`}>{getLink(props)}</li>;

DropdownItem.propTypes = {
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  icon: PropTypes.string,
  isInternalLink: PropTypes.bool
};

DropdownItem.defaultProps = {
  className: '',
  icon: '',
  isInternalLink: false
};

export default DropdownItem;
