import _ from 'lodash';
import Alert from 'react-s-alert';
import contentDisposition from 'content-disposition';
import fileDownload from 'js-file-download';
import FontAwesome from 'react-fontawesome';
import React from 'react';

import { hasHttpHtttps, openInNewTab } from 'src/classes/util/UrlUtil';
import { I18n } from 'src/utils/translations';
import { DownloadActions, NotificationActions, PortalActions } from 'src/classes/util/RequestUtil';
import history from 'src/history';

export const ASSIGN_TO = {
  portal: 'portal',
  account: 'account',
  user: 'user'
};

export const getAssignToOptions = () => {
  return _.map(ASSIGN_TO, (option) => ({ value: option, label: I18n.t(`assign_to.${option}`) }));
};

export const findImportantNotification = (data) =>
  _.find(data, (n) => n.seen === false && n.important === true) || null;

export const notificationPortalInfo = (notification, user) => {
  if (user.other_portals.length === 0) {
    return null;
  }

  return (
    <div>
      {notification.portal.name}
      {user.portal.slug !== notification.portal.slug && (
        <FontAwesome
          name="exclamation-triangle"
          className="text--orange ml5"
          title={I18n.t('notification.portal_warn', { portal: notification.portal.name })}
        />
      )}
    </div>
  );
};

export const onNotificationClick = (notification, event, user, callback) => {
  if (!notification.seen) {
    NotificationActions.markSeen(notification.id);
  }

  if (notification.important && callback) {
    callback(notification);
  }

  if (!notification.url) {
    return;
  }

  if (event.target.tagName !== 'A') {
    if (notification.portal.slug === user.portal.slug) {
      changeLocation(notification);
    } else {
      PortalActions.switch(notification.portal.id).then(() => changeLocation(notification));
    }
  }
};

export const renderNotificationMessage = (message) => {
  let params = message.params || {};

  if (message.nested_params) {
    _.each(message.nested_params, (nested_param) => {
      params[nested_param.name] = renderNotificationMessage(nested_param);
    });
  }

  return I18n.t(message.key, params);
};

const changeLocation = (notification) => {
  if (notification.message.file_download) {
    DownloadActions.download(notification.url)
      .then((response) => {
        const disposition = contentDisposition.parse(response.headers['content-disposition']);
        fileDownload(response.data, disposition.parameters.filename);
      })
      .catch((data) => {
        if (data.response.status === 410) {
          Alert.error(I18n.t('errors.report_removed'));
        } else {
          Alert.error(I18n.t('errors.report_error'));
        }
      });
  } else {
    hasHttpHtttps(notification.url) ? openInNewTab(notification.url) : history.push(notification.url);
  }
};
