import React, { useState } from 'react';

import { I18n } from 'src/utils/translations';
import { store } from 'src/redux/store';
import ContactUsModal from 'src/components/contact-us/ContactUsModal';

const LongRequestMessage = () => {
  const [modalOpened, setModalOpened] = useState(false);

  const toggleContactUsForm = () => setModalOpened(!modalOpened);
  const requestInterminable = store.getState().request.interminable;

  return requestInterminable ? (
    <div className="request-error-box">
      <p>{I18n.t('request_error.longer_then_usual')}</p>
      <div>{I18n.t('request_error.please_wait')}</div>
      <div>
        {I18n.t('request_error.please')}
        <span role="button" onClick={toggleContactUsForm}>
          {' '}
          {I18n.t('request_error.contact_support')}{' '}
        </span>
        {I18n.t('request_error.if_request_too_long')}
      </div>
      <ContactUsModal
        isOpen={modalOpened}
        toggleVisibility={toggleContactUsForm}
        user={store.getState().currentUser && store.getState().currentUser.data}
      />
    </div>
  ) : null;
};

export default LongRequestMessage;
