import { BrowserTracing } from '@sentry/tracing';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { get, isEmpty } from 'lodash';
import { hotjar } from 'react-hotjar';
import React, { Fragment, useEffect } from 'react';
import ReactGA from 'react-ga';
import * as Sentry from '@sentry/browser';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
} from 'chart.js';
import ChartjsPluginStacked100 from 'chartjs-plugin-stacked100';

// move to AppWraper when start using loggedIn from the store
import { ActionCableUtils } from 'src/classes/util/ActionCableUtils';
import { ActionCableWrapper } from 'src/components/spa/ActionCableWrapper';
import { currentJWT, setUserDestinations } from 'src/utils/JWTUtils';
import { fetchPermissions } from 'src/redux/actions/permissions';
import { fetchTranslations } from 'src/redux/actions/translations';
import { fetchCurrentUser, setLogged } from 'src/redux/actions/currentUser';
import { fetchFilterSettings } from 'src/redux/actions/filterSettings';
import { fetchPortals } from 'src/redux/actions/portals';
import { fetchNavbar } from 'src/redux/actions/navbar';
import { fetchUserDependentData } from 'src/redux/actions/userDependentData';
import history from 'src/history';
import { HomeRoute } from 'src/routes';
import LongRequestMessage from 'src/components/spa/LongRequestMessage';
import { RequestErrorHandler } from 'src/components/spa/RequestErrorHandler';
import { SwitchComponent } from 'src/components/spa/Switch';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartjsPluginStacked100
);
ChartJS.unregister(ChartDataLabels);

const sentryTracesSampleRate = process.env.SENTRY_TRACES_SAMPLE_RATE;

const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { hasFetched: hasFetchedPermissions } = useSelector((state) => state.permissions.fetchingState);
  const { hasFetched: hasFetchedFilterSettings } = useSelector((state) => state.filterSettings.fetchingState);
  const { hasFetched: hasFetchedTranslations } = useSelector((state) => state.translations.fetchingState);
  const { hasFetched: navbarHasFetched } = useSelector((state) => state.navbar.fetchingState);
  const { hasFetched: portalHasFetched } = useSelector((state) => state.portals.fetchingState);
  const { hasFetched: userHasFetched } = useSelector((state) => state.currentUser.fetchingState);

  const { logged: loggedIn } = useSelector((state) => state.currentUser);
  const { data: currentUserData } = useSelector((state) => state.currentUser);

  const { locale } = useSelector((state) => state);

  const userDependentDataFetched = userHasFetched && navbarHasFetched && portalHasFetched && hasFetchedFilterSettings;

  const onReceived = () => {
    dispatch(fetchNavbar());
  };

  const onGhostReceived = () => {
    dispatch(fetchUserDependentData());

    if (location.pathname !== HomeRoute) {
      history.push(HomeRoute);
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (currentJWT()) {
      dispatch(setLogged(true));
    } else {
      dispatch(setLogged(false));
      dispatch(fetchPermissions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchFilterSettings());
    }
    /** Sentry set up */
    if (process.env.LOCAL_DEV_INSTANCE !== 'true') {
      Sentry.init({
        environment: process.env.NODE_ENV,
        dsn: process.env.SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: sentryTracesSampleRate ? Number(sentryTracesSampleRate) : 0
      });
      Sentry.setUser({ email: currentUserData?.email });
    }
    if (process.env.HOTJAR_SITE_ID) {
      hotjar.initialize(process.env.HOTJAR_SITE_ID, '6');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  useEffect(() => {
    const isProduction = process.env.NODE_ENV === 'production';
    if (!isEmpty(currentUserData) && isProduction) {
      /** Google Analytics set up */
      const domainName = currentUserData?.email ? currentUserData.email.split('@')[1] : null;
      ReactGA.initialize(process.env.GA_ID);
      const unlisten = history.listen((location) => {
        ReactGA.set({ page: location.pathname, dimension1: domainName }); // Update the user's current page and user domain
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });

      return unlisten;
    }
  }, [location.pathname, currentUserData]);

  useEffect(() => {
    if (loggedIn !== null) {
      !portalHasFetched && dispatch(fetchPortals());
      if (loggedIn) {
        dispatch(fetchPermissions());
        dispatch(fetchNavbar());
        dispatch(fetchCurrentUser()).then((result) => {
          const currentUserEmail = get(result, 'payload.email');
          setUserDestinations(currentUserEmail);
          const newLocale = get(result, 'payload.profile.locale');
          dispatch(fetchTranslations(newLocale));
        });
      } else {
        dispatch(fetchTranslations(locale));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return (
    <Fragment>
      <RequestErrorHandler>
        {hasFetchedPermissions && hasFetchedTranslations && (
          <ActionCableWrapper isSignedIn={loggedIn}>
            {loggedIn && ActionCableUtils.subscribeNotificationsChannel(onReceived)}
            {loggedIn && ActionCableUtils.subscribeTurnOnOffGhostsChannel(onGhostReceived)}
            <SwitchComponent {...props} isSignedIn={loggedIn} userDependentDataFetched={userDependentDataFetched} />
            <LongRequestMessage />
          </ActionCableWrapper>
        )}
      </RequestErrorHandler>
    </Fragment>
  );
};

export default App;
