import { matchPath } from 'react-router-dom';

import { Routes } from 'src/routesConfig';

/* params = string || array || undefined */
export const isCurrentPathWithParams = (currentPath, params) =>
  !!Object.values(Routes).filter((route) => {
    const isCurrentPath = !!matchPath(currentPath, {
      path: route.path,
      exact: true
    });

    if (!params) {
      return isCurrentPath;
    }

    let pathMeetsConditions;
    if (typeof params === 'string') {
      pathMeetsConditions = !!route[params];
    } else {
      pathMeetsConditions = params.every((param) => !!route[param]);
    }

    return isCurrentPath && pathMeetsConditions;
  }).length;
