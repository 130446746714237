import _ from 'lodash';
import ReactGA from 'react-ga';

import { DATA_TYPE_DATE_RANGE, GA_ACTION_FILTER_COMPONENT, GA_CATEGORY_FILTER } from 'src/classes/common/Constants';
import { defaultRbaView } from 'src/classes/util/RbaViewUtil';
import { factoryOrSupplier, getDistributorTranslationKey } from 'src/classes/suppliers/SuppliersUtil';
import { getStaticFiltersOptions } from 'src/classes/suppliers/SuppliersFiltersUtils';
import { I18n } from 'src/utils/translations';

import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';

const isProduction = process.env.NODE_ENV === 'production';
const sessionStorage = 'suppliersRiskFilters';

const FiltersUtils = {
  getPlaceholderText: (key) =>
    I18n.t(getDistributorTranslationKey('analytics.risk_list.filters', key), factoryOrSupplier()),

  onFilterChange: (filters, sendFormCallback) => {
    SessionStorageProvider.set(sessionStorage, JSON.stringify(filters));
    sendFormCallback(filters);
  },

  noUploadedSource: (sources) => !_.find(sources, (s) => s.value === 'uploaded'),

  filters: () => {
    const stickyFilters = JSON.parse(SessionStorageProvider.get(sessionStorage));
    return {
      ...stickyFilters,
      ...getLevel(stickyFilters),
      dateRange: getDateRange(stickyFilters),
      rbaView: defaultRbaView(false, false)
    };
  },

  googleAnalyticsEvents: (category = GA_CATEGORY_FILTER, actionLabel = GA_ACTION_FILTER_COMPONENT, value = '') => {
    if (isProduction) {
      ReactGA.event({
        category,
        action: actionLabel,
        label: value
      });
    }
  },

  specificKeyObjFilter: (data, keys_to_keep) => {
    return Object.fromEntries(Object.entries(data).filter((a) => keys_to_keep.includes(a[0])));
  },

  staticFilters: () => ({
    dateRange: getDateRange(),
    rbaView: defaultRbaView(false, false)
  }),

  defaultValues: () => ({
    dateRange: getDateRange(),
    rbaView: defaultRbaView(false, false)
  }),

  additionCategories: (categories, filters) => {
    const option = {};

    Object.entries(categories).forEach(([key]) => {
      const filter = filters[key];
      if (filter) {
        if (categories[key]?.data_type === DATA_TYPE_DATE_RANGE) {
          option[key] = filter.from && filter.to ? { from: filter.from, to: filter.to } : {};
        } else {
          option[key] = _.isString(filter) ? filter : getIdsFromSelect(filter);
        }
      }
    });

    return option;
  },

  adjustedFilters: (filters, withName = false) => {
    const basicFilters = {
      dateRange: {
        from: filters.dateRange?.from,
        to: filters.dateRange?.to
      },
      countries: getIdsFromSelect(filters.countries),
      levels: getIdsFromSelect(filters.levels),
      leverage: getIdsFromSelect(filters.leverages),
      materiality: getIdsFromSelect(filters.materiality),
      productCategories: getIdsFromSelect(filters.products),
      provinces: getIdsFromSelect(filters.provinces),
      sectors: getIdsFromSelect(filters.sectors),
      sentinel_articles: filters?.sentinel_articles,
      sources: getIdsFromSelect(filters.sources),
      spends: getIdsFromSelect(filters.spends),
      vendors: getIdsFromSelect(filters.vendors),
      rbaView: filters.rbaView,
      weightTemplate: filters.weightTemplate
    };

    if (withName) {
      basicFilters['name'] = filters.name;
    }

    return basicFilters;
  },

  parseFilters: (filters) => {
    const backendParamsMapping = {
      countries: 'country_ids',
      leverage: 'leverages',
      materiality: 'materialities',
      product_categories: 'product_ids',
      provinces: 'province_ids',
      vendors: 'vendor_ids',
      weight_template: 'weight_template_id'
    };

    return Object.entries(filters).reduce((acc, [key, value]) => {
      if (backendParamsMapping[key]) {
        acc[backendParamsMapping[key]] = value;
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  }
};

const getDateRange = (stickyFilters = null) => {
  if (stickyFilters && stickyFilters['dateRange']) {
    return {
      from: stickyFilters['dateRange']['from'] || undefined,
      to: stickyFilters['dateRange']['to'] || undefined
    };
  }
  return {
    from: undefined,
    to: undefined
  };
};

const getIdsFromSelect = (items) => (items ? _.map(items, (item) => item.value) : null);

const getLevel = (stickyFilters = null) => ({
  levels: (stickyFilters && stickyFilters['levels']) || getStaticFiltersOptions().levels
});

export default FiltersUtils;
