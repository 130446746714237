import _ from 'lodash';

/**
 * Returns average value of an array of numbers, skipping null/undefined values.
 * @param values array of numbers to compute average from.
 */
export const avgSkippingNils = (values) => _.mean(values.filter((val) => !_.isNil(val)));

/**
 * Returned value is next 10th above rounded real maximum value for numbers larger than 10. Example:
 * If maxValue is 36, chart will show up 50 as max.
 * If maxValue is 34, chart will show up 40 as max.
 * For maxValue equal to 10 or smaller, returned number is increased by one. Example:
 * If maxValue is 10, chart will show up 11 as max.
 * If maxValue is 6, chart will show up 7 as max.
 * @param values array of numbers
 * @return {number} counted maximum Y
 */
export const countChartMaxY = (values) => {
  let maxValue = _.max(values);
  return maxValue > 10 ? _.round(maxValue / 10 + 1) * 10 : maxValue + 1;
};

/**
 * Returns the given number rounded to two decimal places.
 * @param number the number to format
 * @param precision rounding precision
 * @return {number} formatted number
 */
export const roundNumber = (number, precision = 2) => _.round(number, precision);
export const roundNumberAndPrettify = (number) => largeNumberFormatter(_.round(number, 2));
export const roundToUnity = (number) => (_.isNil(number) ? null : roundNumber(number, 0));
export const convertNumberMultiply = (number) => Number(number) * 100;

export const fixNumberNotation = (number) => (_.isNil(number) ? null : roundNumber(number).toFixed(2));
export const noDecimalPointNumberNotation = (number) => (_.isNil(number) ? null : roundNumber(number, 0));

export const toLocaleString = (number) => (_.isNil(number) ? null : roundNumber(number).toLocaleString());

export const formatNumber = (number, formatter, emptyValue = '-') => {
  return _.isNil(number) ? emptyValue : formatter(number);
};

export const formatNumberWithSuffix = (number, formatter, emptyValue = '-', suffix = '') => {
  return _.isNil(number) ? emptyValue : `${formatNumber(number, formatter, emptyValue)}${suffix}`;
};

/**
 * Returns the given number rounded to two decimal places,
 * or provided empty value if the number is null/undefined.
 *
 * WARNING: we should prefer extending formatNumber method and make utilities around it,
 * rather than using displayNumber, because displayNumber hard-codes rounding numbers, which makes it unable to
 * ex. display fixed number of fraction digits. It is kept for backward-compatibility.
 *
 * @param number the number to format
 * @param emptyValue {string} empty value placeholder
 * @param suffix {string} suffix added to the non empty value
 * @return {string} formatted number, or placeholder for empty value
 */
export const displayNumber = (number, emptyValue = '-', suffix = '') => {
  return _.isNil(number) ? emptyValue : `${roundNumber(number)}${suffix ? suffix : ''}`;
};

/**
 * Returns an object containing given object's properties parsed as numbers.
 * If a property is not defined in source object, the resultant object's value for that key is null.
 *
 * @param obj the object to traverse.
 * @param keys the keys to be included.
 * @return object with given keys, with values based on the source object.
 */
export const extractNumbers = (obj, keys) => {
  return keys
    .map((key) => ({ [key]: !_.isNil(obj[key]) ? Number.parseFloat(obj[key]) : null }))
    .reduce((result, val) => ({ ...result, ...val }));
};

/**
 * Dynamically returns formatter which displays .00 only for small numbers.
 * Big numbers won't have .00 in the end.
 * @param value is value to be displayed
 * @param threshold checks which formatter to use
 */
export const smallPercentFormatter = (value, threshold = 5) => {
  return value < threshold && value !== 0 ? fixNumberNotation : roundToUnity;
};

/**
 * Dynamically returns formatter which displays % only for fixed numbers.
 * There will be no decimal on the end.
 * @param value is value to be displayed
 */
export const fixedPercentFormatter = (value) => {
  return (value * 100).toFixed(0) + '%';
};

/**
 * Adds commas to large numbers.
 * Example: 50000.25 => 50,000.25
 * @param value is value to be displayed
 */
export const largeNumberFormatter = (value) => {
  let parts = value.toString().split('.');
  parts[0] = parseInt(parts[0]).toLocaleString();
  return parts.join('.');
};

/**
 * Returns true if @value is a number or string number, false otherwise.
 *
 * @param value is a value to check.
 * @return true or false
 */
export const isNumeric = (value) => !isNaN(parseFloat(value)) && !isNaN(value - 0);
