import axios from 'axios';
import { generatePath } from 'react-router-dom';

import { buildPath, buildPathForApi } from 'src/utils/buildPath';
import {
  AccountListURL,
  AccountUpdateGroupAccessURL,
  AccountUpdateStatusURL,
  ActivityLogsAccountDataActivityLogsURL,
  ActivityLogsAccountUsersDataActivityLogsURL,
  ActivityLogsUserDataActivityLogsURL,
  AnalyticsChartsDataAnalyticsURL,
  AnalyticsExportAnalyticsURL,
  AnalyticsFilterOptionsAnalyticsURL,
  ArticlesArticleRelevancyChangeURL,
  AnalyticsButterflyDropdownURL,
  ArticlesDynamicFiltersSuggestionsArticlesURL,
  ArticlesExportTableDataURL,
  ArticlesGeographyRiskLandscapeURL,
  ArticlesListSentinelURL,
  ArticlesSentinelIncidentsURL,
  ArticlesSentinelMapURL,
  ArticlesSentinelRiskExposureURL,
  ArticlesSentinelTreeMapURL,
  ArticlesStaticFiltersSuggestionsArticlesURL,
  AssessmentsAssessmentReportDownloadLinkURL,
  AssessmentsCancelUpload,
  AssessmentsClientCategories,
  AssessmentsExportTableDataURL,
  AssessmentsFilterOptionsAssessmentsURL,
  AssessmentsListAssessmentsURL,
  AssessmentsReportFindingsSeverityURL,
  AssessmentsReportListAssessmentsURL,
  AssessmentsTrendServiceFilterOptionsURL,
  AssessmentsUploadStatus,
  AssessmentsUploadURL,
  AuditRatingsDropdowns,
  ClientCategoriesURL,
  ClientReasonsClientReasonsListURL,
  CountryCountriesForTrendReportURL,
  CountryCountriesListURL,
  CustomizableWeightsCustomizableWeightURL,
  CustomizableWeightsCustomizableWeightsURL,
  CustomRiskIndexURL,
  CustomRiskIndicesURL,
  CustomRiskIndicesSourceListURL,
  DashboardActivitiesURL,
  DashboardBenchmarkingURL,
  DashboardButterflyURL,
  DashboardProgramImplementationURL,
  DashboardNotificationListURL,
  DashboardNotificationShownURL,
  DashboardSentinelURL,
  DashboardSupplyChainURL,
  DashboardsURL,
  DashboardURL,
  EnvironmentalMetricsAttributedEmissions,
  EnvironmentalMetricsCarbonMitigation,
  EnvironmentalMetricsEmisionsAnalysis,
  EnvironmentalMetricsEmissionPerformance,
  EnvironmentalMetricsEnergyConsumption,
  EnvironmentalMetricsRefrigerantUsage,
  EnvironmentalMetricsReportingConfidence,
  EnvironmentalMetricsWasteWater,
  EnvironmentalMetricsWaterUsage,
  EsgRatingsCompanyURL,
  EsgRatingsFilterOptionsURL,
  EsgRatingsForwardRequestURL,
  EsgRatingsURL,
  GeographyRiskLandscapeProductTableURL,
  GroupItemURL,
  InvitationUserInvitationAcceptDataURL,
  InvitationUserInvitationAcceptPendingTermsURL,
  InvitationUserInvitationURL,
  ListGroupsURL,
  ManageGroupAddMemberURL,
  ManageGroupItemURL,
  ManageGroupRemoveMemberURL,
  ManageGroupsURL,
  NotificationListNotificationsURL,
  NotificationMarkAllSeenNotificationsURL,
  NotificationNotificationMarkSeenURL,
  NotificationNotificationsURL,
  OrganizationForUpdateOrganizationsURL,
  OrganizationGetAssessmentSourceURL,
  OrganizationListEvtOrganizationsURL,
  OverviewOverviewDataURL,
  PermissionListPermissionsURL,
  PortalDataWithPermissionsURL,
  PortalListPortalsURL,
  PortalPortalURL,
  PortalPortalItemURL,
  PortalPortalSwitchPortalURL,
  PortalPortalsURL,
  ProductCommodityData,
  ProductCountryRiskURL,
  ProductFilterOptionsURL,
  ProductHierarchyURL,
  ProductListURL,
  ProductKeyIssuesURL,
  ProductRelatedURL,
  ProductRiskProfileURL,
  ProgramSettingsURL,
  ProgramSettingsUpdateURL,
  ProgramSettingsAuditRatingsURL,
  ProgramSettingsAuditRatingsUpdateURL,
  ProgramSettingsFindingMappingsURL,
  ProgramSettingsFindingMappingsUpdateURL,
  ProgramSettingsNotificationsURL,
  ProgramSettingsNotificationsUpdateURL,
  ProvinceProvincesForTrendReportURL,
  ProvinceProvincesOfCountryURL,
  RiskIndicesCountryCivilSocietyTrendURL,
  RiskIndicesCountryRiskMapURL,
  RiskIndicesCountrySupplyChainTrendURL,
  RiskIndicesProfileURL,
  RiskIndicesInsightsFileURL,
  RiskLandscapeListPillarWithCategoriesURL,
  RiskLandscapeMainDashboardDataURL,
  RiskLandscapeRiskLandscapeIndexDataURL,
  RiskLandscapeRiskLandscapeRiskSitesURL,
  RiskLandscapeRiskLandscapeSiteTickerURL,
  RiskLandscapeRiskLandscapeYearsURL,
  RoleListRolesURL,
  RoleRoleURL,
  RoleRolesURL,
  SignInURL,
  SignInGetOauthURL,
  SignInViaOauthURL,
  SignInViaOauthCheckAccessURL,
  SignOutURL,
  SectorSectorsForTrendReportURL,
  SupplierDataURL,
  SupplierRiskExposureProductURL,
  SupplierSupplierURL,
  SupplierSuppliersChangeVisibilityURL,
  SupplierSuppliersCancelUploadURL,
  SupplierSuppliersExportURL,
  SupplierSuppliersFilterOptionsURL,
  SupplierSuppliersKeyRisksURL,
  SupplierSuppliersListPillarsURL,
  SupplierSuppliersListSmeltersURL,
  SupplierSuppliersListSuppliersURL,
  SupplierSuppliersListSuppliersDropdownInfoURL,
  SupplierSuppliersMergeDistributorsURL,
  SupplierSuppliersPurgeURL,
  SupplierSuppliersRiskManagementURL,
  SupplierSuppliersSupplyChainURL,
  SupplierSuppliersUploadStatusURL,
  SupplierSuppliersUploadSuppliersURL,
  SuppliersUploadDataURL,
  TrendReportTrendReportsBusinessEthicsURL,
  TrendReportTrendReportsEnvironmentURL,
  TrendReportTrendReportsExportURL,
  TrendReportTrendReportsHealthAndSafetyURL,
  TrendReportTrendReportsLaborURL,
  TrendReportTrendReportsManagementSystemsURL,
  TrendReportTrendReportsTransparencyURL,
  TrendReportTrendReportsWagesURL,
  TrendReportTrendReportsWorkerDemographicsURL,
  TrendReportTrendReportsWorkerSentimentURL,
  TrendReportTrendReportsWorkingHoursURL,
  TrendReportsV3AuditMetricsDataURL,
  TrendReportsV3AuditCountsDataURL,
  TrendReportsV3AuditRatingsDataURL,
  TrendReportsV3FilterOptions,
  TrendReportsV3CommonFields,
  TrendReportsV3Findings,
  TrendReportsV3WorkerDemographicsDataURL,
  UserChangeLocaleUsersURL,
  UserCurrentUserStaffUsersURL,
  UserDataURL,
  UserDropdownListUsersURL,
  UserListUsersURL,
  UserPasswordEditURL,
  UserPasswordEditDataURL,
  UserTurnOffGhostUsersURL,
  UserTurnOnGhostUsersURL,
  UserUserURL,
  UserUserUpdateRoleURL,
  UserTACAccept
} from 'src/apiUrls';
import SessionStorageProvider from 'src/classes/storage/SessionStorageProvider';

export const postFormData = (url, data, additionalConfig) => {
  return axios
    .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } }, additionalConfig)
    .catch((err) => {
      throw new Error(err);
    });
};

export const AccountActions = {
  list: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AccountListURL, params), additionalConfig);
  },

  updateGroupAccess: (account, params, additionalConfig) => {
    return axios.put(buildPathForApi(AccountUpdateGroupAccessURL, { slug: account.slug }), params, additionalConfig);
  },

  updateStatusAccess: (account, params, additionalConfig) => {
    return axios.put(buildPathForApi(AccountUpdateStatusURL, { slug: account.slug }), params, additionalConfig);
  }
};

export const ActivityLogsActions = {
  account_data: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ActivityLogsAccountDataActivityLogsURL, params), additionalConfig);
  },

  account_users_data: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ActivityLogsAccountUsersDataActivityLogsURL, params), additionalConfig);
  },

  user_data: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ActivityLogsUserDataActivityLogsURL, params), additionalConfig);
  }
};

export const AnalyticsActions = {
  chartsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AnalyticsChartsDataAnalyticsURL, params), additionalConfig);
  },

  export: (params, additionalConfig) => {
    return axios.post(AnalyticsExportAnalyticsURL, params, additionalConfig);
  },

  filterOptions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AnalyticsFilterOptionsAnalyticsURL, params), additionalConfig);
  },

  fetchButterflyDropdown: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AnalyticsButterflyDropdownURL, params), additionalConfig);
  }
};

export const ArticlesActions = {
  dynamicFiltersSuggestions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesDynamicFiltersSuggestionsArticlesURL, params), additionalConfig);
  },

  exportTableData: (params, additionalConfig) => {
    return axios.post(buildPathForApi(ArticlesExportTableDataURL, params), additionalConfig);
  },

  geographyRiskArticles: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesGeographyRiskLandscapeURL, params), additionalConfig);
  },

  latestIncidents: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesSentinelIncidentsURL, params), additionalConfig);
  },

  listArticles: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesListSentinelURL, params), additionalConfig);
  },

  map: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesSentinelMapURL, params), additionalConfig);
  },

  riskExposure: (distributorId) => {
    return axios.get(buildPathForApi(ArticlesSentinelRiskExposureURL, { distributor_id: distributorId }));
  },

  setRelevancy: (article_id, is_relevant, additionalConfig) => {
    return axios.post(
      buildPathForApi(ArticlesArticleRelevancyChangeURL, { article_id, is_relevant }),
      additionalConfig
    );
  },

  staticFiltersSuggestions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesStaticFiltersSuggestionsArticlesURL, params), additionalConfig);
  },

  treeMap: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ArticlesSentinelTreeMapURL, params), additionalConfig);
  }
};

export const AssessmentsActions = {
  cancelUpload: (id, additionalConfig) => {
    return axios.get(AssessmentsCancelUpload, {
      params: { id },
      additionalConfig
    });
  },

  getClientCategories: (assessmentId) => {
    return axios.get(generatePath(AssessmentsClientCategories, { assessmentId }));
  },

  getReportFindingsSeverity: (reportNumber) => {
    return axios.get(generatePath(AssessmentsReportFindingsSeverityURL, { report_number: reportNumber.split(' ')[0] }));
  },

  exportTableData: (params, additionalConfig) => {
    return axios.post(buildPathForApi(AssessmentsExportTableDataURL, params, additionalConfig));
  },

  filterOptions: (additionalConfig) => {
    return axios.get(AssessmentsFilterOptionsAssessmentsURL, additionalConfig);
  },

  list: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AssessmentsListAssessmentsURL, params), additionalConfig);
  },

  reportDownloadLink: (assessmentId, additionalConfig) => {
    return axios.get(buildPathForApi(AssessmentsAssessmentReportDownloadLinkURL, { assessmentId }), additionalConfig);
  },

  trendAssessmentsList: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AssessmentsReportListAssessmentsURL, params), additionalConfig);
  },

  saveClientCategories: (assessmentId, data) => {
    return axios.post(generatePath(AssessmentsClientCategories, { assessmentId }), data);
  },

  serviceFilterOptions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(AssessmentsTrendServiceFilterOptionsURL, params), additionalConfig);
  },

  uploadAssessments: ({ file, cacheToken, skipErrors }, additionalConfig) => {
    const formData = new FormData();

    if (file) formData.append('file', file);
    if (cacheToken) formData.append('cache_token', cacheToken);
    if (skipErrors) formData.append('skip_errors', skipErrors.toString());

    return postFormData(AssessmentsUploadURL, formData, additionalConfig);
  },

  uploadStatus: (id, additionalConfig) => {
    return axios.get(AssessmentsUploadStatus, { params: { id: id } }, additionalConfig);
  }
};

export const AuthorizationActions = {
  signIn: (portal, params, additionalConfig) => {
    return axios.post(SignInURL, { ...params, requestedPortal: portal.slug }, additionalConfig);
  },

  signOut: (additionalConfig) => {
    return axios.delete(SignOutURL, additionalConfig);
  },

  oAuth2Authorization: (authCode) => {
    return axios.post(SignInViaOauthURL, { auth_code: authCode });
  },

  oAuth2AuthorizationGetAuth: (accessToken, additionalConfig) => {
    return axios.post(SignInGetOauthURL, { token: accessToken }, additionalConfig);
  },

  oAuth2AuthorizationCheckDestinations: (additionalConfig) => {
    return axios.post(SignInViaOauthCheckAccessURL, null, additionalConfig);
  }
};

export const ClientCategoriesActions = {
  clientCategories: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ClientCategoriesURL, params), additionalConfig);
  },

  saveClientCategories: (params, additionalConfig) => {
    return axios.post(ClientCategoriesURL, params, additionalConfig);
  }
};

export const ClientReasonsActions = {
  list: (additionalConfig) => {
    return axios.get(ClientReasonsClientReasonsListURL, additionalConfig);
  }
};

export const CountryActions = {
  list: (additionalConfig) => {
    return axios.get(CountryCountriesListURL, additionalConfig);
  },

  forReport: (params, additionalConfig) => {
    return axios.get(buildPathForApi(CountryCountriesForTrendReportURL, params), additionalConfig);
  }
};

export const CustomizableWeightsActions = {
  create: (params, additionalConfig) => {
    return axios.post(CustomizableWeightsCustomizableWeightsURL, params, additionalConfig);
  },

  list: (additionalConfig) => {
    return axios.get(CustomizableWeightsCustomizableWeightsURL, additionalConfig);
  },

  remove: (templateId) => {
    return axios.delete(buildPathForApi(CustomizableWeightsCustomizableWeightURL, { templateId }));
  },

  update: (templateId, params, additionalConfig) => {
    return axios.put(
      buildPathForApi(CustomizableWeightsCustomizableWeightURL, { templateId }),
      params,
      additionalConfig
    );
  }
};

export const CustomRiskIndicesAction = {
  delete: (id, additionalConfig) => {
    return axios.delete(buildPath(CustomRiskIndexURL, { id }), additionalConfig);
  },

  get: (id, additionalConfig) => {
    return axios.get(buildPath(CustomRiskIndexURL, { id: id }), additionalConfig);
  },

  list: (additionalConfig) => {
    return axios.get(CustomRiskIndicesURL, additionalConfig);
  },

  save: (params, additionalConfig) => {
    return axios.post(CustomRiskIndicesURL, params, additionalConfig);
  },

  sourceList: (additionalConfig) => {
    return axios.get(CustomRiskIndicesSourceListURL, additionalConfig);
  },

  update: (id, params, additionalConfig) => {
    return axios.put(buildPath(CustomRiskIndexURL, { id }), params, additionalConfig);
  }
};

export const DashboardActions = {
  create: (name) => {
    return axios.post(DashboardsURL, { name });
  },

  get: (id) => {
    return axios.get(buildPathForApi(DashboardURL, { id: id }));
  },

  list: (params) => {
    return axios.get(buildPathForApi(DashboardsURL, params));
  },

  update: (id, params) => {
    return axios.put(buildPathForApi(DashboardURL, { id }), { dashboard: params });
  },

  dashboardActivities: (params) => {
    return axios.get(buildPathForApi(DashboardActivitiesURL, params));
  },

  dashboardBenchmarking: (params) => {
    return axios.get(buildPathForApi(DashboardBenchmarkingURL, params));
  },

  dashboardProgramImplementation: (params) => {
    return axios.get(buildPathForApi(DashboardProgramImplementationURL, params));
  },

  dashboardButterfly: (params) => {
    return axios.get(buildPathForApi(DashboardButterflyURL, params));
  },

  dashboardNotificationList: (params) => {
    return axios.get(buildPathForApi(DashboardNotificationListURL, params));
  },

  dashboardNotificationShown: (params) => {
    return axios.post(buildPathForApi(DashboardNotificationShownURL, params));
  },

  dashboardSentinel: (params) => {
    return axios.get(buildPathForApi(DashboardSentinelURL, params));
  },

  dashboardSupplyChain: (params) => {
    return axios.get(buildPathForApi(DashboardSupplyChainURL, params));
  }
};

export const DownloadActions = {
  download: (url, additionalConfig) => {
    return axios.get(url, { responseType: 'blob' }, additionalConfig);
  }
};

export const EngagementActions = {
  overviewData: (additionalConfig) => {
    return axios.get(OverviewOverviewDataURL, additionalConfig);
  }
};

export const EsgActions = {
  esgRatingsFilterOptions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EsgRatingsFilterOptionsURL, params), additionalConfig);
  },

  esgRatings: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EsgRatingsURL, params), additionalConfig);
  },

  siteData: (slug, additionalConfig) => {
    return axios.get(buildPathForApi(EsgRatingsCompanyURL, { slug }), additionalConfig);
  },

  sendEmail: (additionalConfig) => {
    return axios.post(buildPathForApi(EsgRatingsForwardRequestURL), additionalConfig);
  }
};

export const GroupActions = {
  addMember: (groupId, params, additionalConfig) => {
    return axios.post(buildPathForApi(ManageGroupAddMemberURL, { group_id: groupId }), params, additionalConfig);
  },

  create: (params, additionalConfig) => {
    return axios.post(ManageGroupsURL, params, additionalConfig);
  },

  get: (groupId, additionalConfig) => {
    return axios.get(buildPathForApi(GroupItemURL, { id: groupId }), additionalConfig);
  },

  list: (additionalConfig) => {
    return axios.get(ListGroupsURL, additionalConfig);
  },

  remove: (groupId, additionalConfig) => {
    return axios.delete(buildPathForApi(ManageGroupItemURL, { group_id: groupId }), additionalConfig);
  },

  removeMember: (groupId, params, additionalConfig) => {
    return axios.post(buildPathForApi(ManageGroupRemoveMemberURL, { group_id: groupId }), params, additionalConfig);
  },

  update: (groupId, params, additionalConfig) => {
    return axios.put(buildPathForApi(ManageGroupItemURL, { group_id: groupId }), params, additionalConfig);
  }
};

export const InvitationActions = {
  accept: (params, additionalConfig) => {
    return axios.put(InvitationUserInvitationURL, params, additionalConfig);
  },

  acceptPendingTerms: (id, params, additionalConfig) => {
    return axios.post(buildPathForApi(InvitationUserInvitationAcceptPendingTermsURL, { id }), params, additionalConfig);
  },

  invitationData: (token, additionalConfig) => {
    return axios.get(
      buildPathForApi(InvitationUserInvitationAcceptDataURL, { invitation_token: token }),
      additionalConfig
    );
  },

  invite: (users, additionalConfig) => {
    return axios.post(InvitationUserInvitationURL, { users: users }, additionalConfig);
  },

  pendingTermsData: (id, additionalConfig) => {
    return axios.get(buildPathForApi(InvitationUserInvitationAcceptPendingTermsURL, { id }), additionalConfig);
  }
};

export const NotificationActions = {
  create: (params, additionalConfig) => {
    return axios.post(NotificationNotificationsURL, { ...params }, additionalConfig);
  },

  fetchAll: (additionalConfig) => {
    return axios.get(NotificationListNotificationsURL, additionalConfig);
  },

  markAllSeen: (additionalConfig) => {
    return axios.post(NotificationMarkAllSeenNotificationsURL, additionalConfig);
  },

  markSeen: (notificationId, additionalConfig) => {
    return axios.post(buildPathForApi(NotificationNotificationMarkSeenURL, { notificationId }), additionalConfig);
  }
};

export const OrganizationActions = {
  evtOrganizations: (reportName, with_data_first = false, additionalConfig) => {
    return axios.get(
      buildPathForApi(OrganizationListEvtOrganizationsURL, { report: reportName, with_data_first: with_data_first }),
      additionalConfig
    );
  },

  fetchForUpdate: (accountId, searchQuery, externalSystem, additionalConfig) => {
    return axios.get(
      buildPathForApi(OrganizationForUpdateOrganizationsURL, {
        account_id: accountId,
        search_query: searchQuery,
        external_system: externalSystem
      }),
      additionalConfig
    );
  },

  getAssessmentSource: (organizationIDs, additionalConfig) => {
    return axios.get(
      buildPathForApi(OrganizationGetAssessmentSourceURL, {
        organization_ids: organizationIDs
      }),
      additionalConfig
    );
  }
};

export const PasswordActions = {
  resetPassword: (params, additionalConfig) => {
    return axios.put(UserPasswordEditURL, params, additionalConfig);
  },

  resetPasswordData: (token, additionalConfig) => {
    return axios.get(buildPathForApi(UserPasswordEditDataURL, { reset_password_token: token }), additionalConfig);
  },

  sendResetPassword: (email, additionalConfig) => {
    return axios.post(UserPasswordEditURL, { user: { email: email } }, additionalConfig);
  }
};

export const PermissionActions = {
  list: (kind, all, additionalConfig) => {
    return axios.get(buildPathForApi(PermissionListPermissionsURL, { kind, all }), additionalConfig);
  }
};

export const PortalActions = {
  create: (params, additionalConfig) => {
    return axios.post(PortalPortalsURL, params, additionalConfig);
  },

  get: (portalId, additionalConfig) => {
    return axios.get(buildPathForApi(PortalPortalItemURL, { portalId }), additionalConfig);
  },

  getDataWithPermissions: (slug, additionalConfig) => {
    return axios.get(buildPathForApi(PortalDataWithPermissionsURL, { slug }), additionalConfig);
  },

  update: (portalId, params, additionalConfig) => {
    return axios.put(buildPathForApi(PortalPortalURL, { portalId }), params, additionalConfig);
  },

  remove: (portalId, additionalConfig) => {
    return axios.delete(buildPathForApi(PortalPortalURL, { portalId }), additionalConfig);
  },

  fetchAll: (params, additionalConfig) => {
    return axios.get(buildPathForApi(PortalListPortalsURL, params), additionalConfig);
  },

  switch: (portalId, additionalConfig) => {
    return axios
      .post(buildPathForApi(PortalPortalSwitchPortalURL, { portalId }), additionalConfig)
      .then(SessionStorageProvider.clear);
  }
};

export const ProgramSettingsActions = {
  getFilters: (withOptions, withSupported = false) => {
    return axios.get(buildPathForApi(ProgramSettingsURL, { with_options: withOptions, with_supported: withSupported }));
  },

  updateFilters: (params, additionalConfig) => {
    return axios.post(buildPathForApi(ProgramSettingsUpdateURL, params), additionalConfig);
  },

  getFindingMappings: (params) => {
    return axios.get(buildPathForApi(ProgramSettingsFindingMappingsURL, params));
  },

  updateFindingMappings: (params, additionalConfig) => {
    return axios.post(ProgramSettingsFindingMappingsUpdateURL, params, additionalConfig);
  },

  getNotifications: () => {
    return axios.get(buildPathForApi(ProgramSettingsNotificationsURL));
  },

  updateNotifications: (params, additionalConfig) => {
    return axios.post(ProgramSettingsNotificationsUpdateURL, params, additionalConfig);
  },

  auditRatingsDropdown: () => {
    return axios.get(buildPathForApi(AuditRatingsDropdowns));
  },

  getAuditRatings: () => {
    return axios.get(buildPathForApi(ProgramSettingsAuditRatingsURL));
  },

  updateAuditRatings: (params, additionalConfig) => {
    return axios.post(ProgramSettingsAuditRatingsUpdateURL, params, additionalConfig);
  }
};

export const ProgramTrendReportsActions = {
  filterOptions: (selectedOption, selectedSegment1Values, selectedSegment1, additionalConfig) => {
    return axios.get(
      buildPathForApi(TrendReportsV3FilterOptions, {
        option: selectedOption,
        values: selectedSegment1Values,
        segment_1_name: selectedSegment1
      }),
      additionalConfig
    );
  },

  getAuditMetricsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3AuditMetricsDataURL, params), additionalConfig);
  },

  getAuditCountsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3AuditCountsDataURL, params), additionalConfig);
  },

  getAuditRatingsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3AuditRatingsDataURL, params), additionalConfig);
  },

  getWorkerDemographicsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3WorkerDemographicsDataURL, params), additionalConfig);
  },

  getFindingsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3Findings, params), additionalConfig);
  },

  commonFieldsTrendReports: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportsV3CommonFields, params), additionalConfig);
  }
};

export const EnvironmentalMetricsActions = {
  getAttributedEmissionsData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsAttributedEmissions, params), additionalConfig);
  },

  getCarbonMitigationData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsCarbonMitigation, params), additionalConfig);
  },

  getEmisionsAnalysisData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsEmisionsAnalysis, params), additionalConfig);
  },

  getEmissionsPerformanceData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsEmissionPerformance, params), additionalConfig);
  },

  getEnergyConsumptionData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsEnergyConsumption, params), additionalConfig);
  },

  getRefrigerantUsageData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsRefrigerantUsage, params), additionalConfig);
  },

  getReportingConfidenceData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsReportingConfidence, params), additionalConfig);
  },

  getWasteWaterData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsWasteWater, params), additionalConfig);
  },

  getWaterUsageData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(EnvironmentalMetricsWaterUsage, params), additionalConfig);
  }
};

export const ProvinceActions = {
  forReport: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ProvinceProvincesForTrendReportURL, params), additionalConfig);
  },

  ofCountry: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ProvinceProvincesOfCountryURL, params), additionalConfig);
  }
};

export const ProductActions = {
  countryRisk: (slug) => {
    return axios.get(buildPathForApi(ProductCountryRiskURL, { slug }));
  },

  filterOptions: (additionalConfig) => {
    return axios.get(ProductFilterOptionsURL, additionalConfig);
  },

  forReport: (params, additionalConfig) => {
    return axios.get(buildPathForApi(ProductCommodityData, params), additionalConfig);
  },

  hierarchy: (slug) => {
    return axios.get(buildPathForApi(ProductHierarchyURL, { slug }));
  },

  list: (additionalConfig) => {
    return axios.get(ProductListURL, additionalConfig);
  },

  keyIssues: (slug) => {
    return axios.get(buildPathForApi(ProductKeyIssuesURL, { slug }));
  },

  related: (slug) => {
    return axios.get(buildPathForApi(ProductRelatedURL, { slug }));
  },

  riskProfile: (slug) => {
    return axios.get(buildPathForApi(ProductRiskProfileURL, { slug }));
  }
};

export const RiskIndicesActions = {
  countryCivilSocietyTrend: (year, country_id, province_id, additionalConfig) => {
    return axios.get(
      buildPathForApi(RiskIndicesCountryCivilSocietyTrendURL, { year, country_id, province_id }),
      additionalConfig
    );
  },

  countryRiskMap: (year, country_id, additionalConfig) => {
    return axios.get(buildPathForApi(RiskIndicesCountryRiskMapURL, { year, country_id }), additionalConfig);
  },

  countrySupplyChainTrend: (year, country_id, province_id, additionalConfig) => {
    return axios.get(
      buildPathForApi(RiskIndicesCountrySupplyChainTrendURL, { year, country_id, province_id }),
      additionalConfig
    );
  },

  downloadRiskReport: (year = '', country_id = '', province_id = '', additionalConfig) => {
    return axios.get(buildPathForApi(RiskIndicesInsightsFileURL, { year, country_id, province_id }), additionalConfig);
  },

  riskProfile: (year, country_id, province_id, additionalConfig) => {
    return axios.get(buildPathForApi(RiskIndicesProfileURL, { year, country_id, province_id }), additionalConfig);
  }
};

export const RiskLandscapeActions = {
  geographyRiskLandscapeProductTable: (params, additionalConfig) => {
    return axios.get(buildPathForApi(GeographyRiskLandscapeProductTableURL, params), additionalConfig);
  },

  indexData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(RiskLandscapeRiskLandscapeIndexDataURL, params), additionalConfig);
  },

  mainDashboardData: (params, additionalConfig) => {
    return axios.get(buildPathForApi(RiskLandscapeMainDashboardDataURL, params), additionalConfig);
  },

  riskSites: (params, additionalConfig) => {
    return axios.get(buildPathForApi(RiskLandscapeRiskLandscapeRiskSitesURL, params), additionalConfig);
  },

  siteTicker: (country_id, province_id, additionalConfig) => {
    return axios.get(
      buildPathForApi(RiskLandscapeRiskLandscapeSiteTickerURL, { country_id, province_id }),
      additionalConfig
    );
  },

  years: (additionalConfig) => {
    return axios.get(RiskLandscapeRiskLandscapeYearsURL, additionalConfig);
  }
};

export const RoleActions = {
  create: (params, additionalConfig) => {
    return axios.post(RoleRolesURL, { role: params }, additionalConfig);
  },

  list: (type, additionalConfig) => {
    return axios.get(buildPathForApi(RoleListRolesURL, type ? { type: type } : {}), additionalConfig);
  },

  update: (id, params, additionalConfig) => {
    return axios.put(buildPathForApi(RoleRoleURL, { id }), { role: params }, additionalConfig);
  }
};

export const SectorActions = {
  forReport: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SectorSectorsForTrendReportURL, params), additionalConfig);
  }
};

export const SupplierActions = {
  cancelUpload: (id, additionalConfig) => {
    return axios.get(SupplierSuppliersCancelUploadURL, {
      params: { id },
      additionalConfig
    });
  },

  changeVisibility: (params, additionalConfig) => {
    return axios.post(SupplierSuppliersChangeVisibilityURL, params, additionalConfig);
  },

  exportDistributors: (type, listParams, includesHidden, additionalConfig) => {
    return axios.post(
      buildPathForApi(SupplierSuppliersExportURL, {
        type,
        list_params: listParams,
        includes_hidden: includesHidden
      }),
      additionalConfig
    );
  },

  filterOptions: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersFilterOptionsURL, params), additionalConfig);
  },

  keyRisks: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersKeyRisksURL, params), additionalConfig);
  },

  listPillarWithCategories: (params, additionalConfig) => {
    return axios.get(buildPathForApi(RiskLandscapeListPillarWithCategoriesURL, params), additionalConfig);
  },

  listPillars: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersListPillarsURL, params), additionalConfig);
  },

  listSmelters: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersListSmeltersURL, params), additionalConfig);
  },

  listSuppliers: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersListSuppliersURL, params), additionalConfig);
  },

  listSuppliersDropdownInfo: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersListSuppliersDropdownInfoURL, params), additionalConfig);
  },

  mergeDistributors: (params, additionalConfig) => {
    return axios.post(SupplierSuppliersMergeDistributorsURL, params, additionalConfig);
  },

  purgeSuppliers: (params, additionalConfig) => {
    return axios.post(buildPathForApi(SupplierSuppliersPurgeURL, params), additionalConfig);
  },

  supplierSupplyChain: (params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSuppliersSupplyChainURL, params), additionalConfig);
  },

  supplierAssessments: (params) => {
    return axios.get(buildPathForApi(SupplierSuppliersRiskManagementURL, params));
  },

  supplierData: (id, params, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierDataURL, { id, ...params }), additionalConfig);
  },

  supplierInfo: (id, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierSupplierURL, { id }), additionalConfig);
  },

  update: (id, supplier, additionalConfig) => {
    return axios.put(buildPathForApi(SupplierSupplierURL, { id }), { distributor: supplier }, additionalConfig);
  },

  uploadData: (id, additionalConfig) => {
    return axios.get(buildPathForApi(SuppliersUploadDataURL, { id }), additionalConfig);
  },

  uploadStatus: (id, additionalConfig) => {
    return axios.get(SupplierSuppliersUploadStatusURL, { params: { id: id } }, additionalConfig);
  },

  uploadSuppliers: (type, { file, cacheToken, skipErrors }, mode, isAdd, additionalConfig) => {
    const formData = new FormData();
    formData.append('type', type);
    formData.append('mode', mode);
    formData.append('is_add', isAdd);

    if (file) formData.append('file', file);
    if (cacheToken) formData.append('cache_token', cacheToken);
    if (skipErrors) formData.append('skip_errors', skipErrors.toString());

    return postFormData(SupplierSuppliersUploadSuppliersURL, formData, additionalConfig);
  },

  riskExposureProduct: (id, additionalConfig) => {
    return axios.get(buildPathForApi(SupplierRiskExposureProductURL, { id }), additionalConfig);
  }
};

export const TrendActions = {
  businessEthics: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsBusinessEthicsURL, params), additionalConfig);
  },

  environment: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsEnvironmentURL, params), additionalConfig);
  },

  export: (params, additionalConfig) => {
    return axios.post(TrendReportTrendReportsExportURL, params, additionalConfig);
  },

  healthAndSafety: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsHealthAndSafetyURL, params), additionalConfig);
  },

  labor: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsLaborURL, params), additionalConfig);
  },

  managementSystems: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsManagementSystemsURL, params), additionalConfig);
  },

  transparency: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsTransparencyURL, params), additionalConfig);
  },

  wages: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsWagesURL, params), additionalConfig);
  },

  workerDemographics: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsWorkerDemographicsURL, params), additionalConfig);
  },

  workerSentiment: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsWorkerSentimentURL, params), additionalConfig);
  },

  workingHours: (params, additionalConfig) => {
    return axios.get(buildPathForApi(TrendReportTrendReportsWorkingHoursURL, params), additionalConfig);
  }
};

export const UserActions = {
  acceptTAC: (params, additionalConfig) => {
    return axios.patch(UserTACAccept, params, additionalConfig);
  },

  changeLocale: (params, additionalConfig) => {
    return axios.patch(UserChangeLocaleUsersURL, params, additionalConfig);
  },

  current_user_staff: (additionalConfig) => {
    return axios.get(UserCurrentUserStaffUsersURL, additionalConfig);
  },

  dropdownList: (params, additionalConfig) => {
    return axios.get(buildPathForApi(UserDropdownListUsersURL, params), additionalConfig);
  },

  getUserByNickname: (nickname, additionalConfig) => {
    return axios.get(buildPathForApi(UserDataURL, { nickname }), additionalConfig);
  },

  list: (params, additionalConfig) => {
    return axios.get(buildPathForApi(UserListUsersURL, params), additionalConfig);
  },

  remove: (nickname, additionalConfig) => {
    return axios.delete(buildPathForApi(UserUserURL, { nickname }), additionalConfig);
  },

  turnOffGhost: (additionalConfig) => {
    return axios.post(UserTurnOffGhostUsersURL, additionalConfig).then(SessionStorageProvider.clear);
  },

  turnOnGhost: (accountSlug, userId, additionalConfig) => {
    return axios
      .post(UserTurnOnGhostUsersURL, { slug: accountSlug, user_id: userId }, additionalConfig)
      .then(SessionStorageProvider.clear);
  },

  updateRoles: (nickname, params, additionalConfig) => {
    return axios.put(buildPathForApi(UserUserUpdateRoleURL, { nickname }), { roles: params }, additionalConfig);
  }
};
